/** @format */

import React from "react";
// import { Audio } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { PropagateLoader } from "react-spinners";

const Loader = () => {
  let show = useSelector((s) => s.is_loader);
  return (
    <div className="container-fluid">
  <div className={`spiner-loader ${show ? "d-block" : "d-none"}`}>
      <div className={`spinercontainer `}>
      <PropagateLoader color="#c01f25" speedMultiplier={2} size={16} />
      </div>
    </div>
    </div>
  
  );
};

export default Loader;

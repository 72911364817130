/** @format */

import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import {
  adData,
  advertiserData,
  idToDelete,
  isLoader,
  isToaster,
} from "../../actions"
import { useSelector } from "react-redux"
import axios from "axios"
import { useNavigate } from "react-router-dom"

const DeleteImage = () => {
  let ID = useSelector((s) => s.id_to_delete)
  let token = localStorage.getItem("userToken")
  const [loading, setLoading] = useState(false)
  let navigate = useNavigate()
  let dispatch = useDispatch()
  const onDelete = async () => {
    setLoading(true)
    await axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/delete-image-by-id/${ID}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setLoading(false)
        dispatch(idToDelete(""))
        dispatch(isToaster({ flag: true, msg: "image deleted Successfully!" }))
        console.log(res)
      })
      .catch((err) => {
        setLoading(false)
        dispatch(idToDelete(""))
        console.log(err)
      })
    dispatch(isLoader(true))
    if (token) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/get-advertiser-by-admin/${localStorage.getItem(
            "viewAdvertiser"
          )}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          dispatch(isLoader(false))
          dispatch(advertiserData(res.data.user))
        })
        .catch((err) => {
          dispatch(isLoader(false))
        })
    } else {
      navigate("/login")
    }
  }
  return (
    <>
      <div
        className={`modal_container text-center m-auto ${
          ID ? "d-block" : "d-none"
        }`}
      >
        <div className="modal-dialog">
          <div className="modal-content price-table p-4">
            <div className="position-relative">
              <h2 className="modal-title fs-5 text-body" id="deletepopupLabel">
                Delete this Entry?
              </h2>
              <span
                className="position-absolute cross_close top-0 end-0"
                onClick={() => dispatch(idToDelete(""))}
              >
                <i
                  className="fa-solid fa-x"
                  data-dismiss="modal"
                  aria-label="Close"
                ></i>
              </span>
            </div>
            <div className="modal-body">
              <h2 className="fs-6 text-body">
                Are you sure to delete this Entry
              </h2>
            </div>

            <div className="p-2">
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn cus-btn "
                  disabled={loading}
                  data-dismiss="modal"
                  onClick={onDelete}
                >
                  Delete&nbsp;
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : null}
                </button>
                <button
                  type="button"
                  className="btn sec-btn ms-3"
                  onClick={() => dispatch(idToDelete(""))}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DeleteImage

/** @format */

import React, { useEffect, useState } from "react";
import "../../asset/css/landingpage.css";
import location from "../../asset/img/location.png";
import FilterPopup from "./FilterPopup";
import { useDispatch, useSelector } from "react-redux";
import { isToaster, searchCity, searchGender } from "../../actions";

const Filter = () => {
  const [gender, setGender] = useState(useSelector((s) => s.search_gender));
  const [city, setCity] = useState(useSelector((s) => s.search_city));
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();
  const handleGender = (e) => {
    setGender(e.target.value);
  };
  const handleCity = (e) => {
    setCity(e.target.value);
  };

  const onSearch = () => {
    if(city){
      setLoading(true)
      dispatch(searchGender(gender));
      dispatch(searchCity(city));
      setTimeout(() => {
        setLoading(false)
      }, 400)
    } else {
      dispatch(isToaster({flag: true, msg: "Please enter City"}))
    }
    
    
  };

  return (
    <>
      <section id="filter-background">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="filter-section">
                <span className="filter-g">
                  <select id="gender" value={gender} onChange={handleGender}>
                    <option value="" selected>
                      Gender
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Non-binary">Non Binary</option>
                    <option value="Other">Other</option>
                  </select>
                </span>
                <span>
                  <div className="input-container">
                    <input
                      type="text"
                      value={city}
                      onChange={handleCity}
                      className="input-field custom-select"
                      placeholder="Search by city"
                    />
                    <img
                      src={location}
                      alt="Location Icon"
                      className="icon location-icon"
                    />
                  </div>
                </span>
                <span className="filter-f">
                  <FilterPopup />
                </span>
                <span>
                  <button className="btn hm-btn" disabled={loading} onClick={onSearch}>
                    Search &nbsp;
                    {loading ? (
                      <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : null}
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Filter;

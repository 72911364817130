/** @format */

import "./App.css";
import Home from "./enduser/home.jsx";
import Login from "./pages/auth-pages/login.js";
import Signup from "./pages/auth-pages/signup.js";
import ForgotPassword from "./pages/auth-pages/forgotpassword.js";
import ResetPassword from "./pages/auth-pages/resetpassword.jsx";
import AdvertiserDashboard from "./advertiser/advertiserDashboard.jsx";
import AdminPanel from "./admin/adminPanel.jsx";
import AdminDashboard from "./admin/pages/AdminDashboard.jsx";
import Comingsoon from "./component/comingsoon.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./advertiser/pages/dashboard.jsx";
import AddProfile from "./advertiser/pages/Profile/AddProfile.jsx";
import ViewProfile from "./advertiser/pages/Profile/ViewProfile.jsx";
import ActiveAds from "./advertiser/pages/Ads/ActiveAds.jsx";
import CreateAds from "./advertiser/pages/Ads/CreateAds.jsx";
import ExpiredAds from "./advertiser/pages/Ads/ExpiredAds.jsx";
import Availability from "./advertiser/pages/Availability.jsx";
import Plan from "./advertiser/pages/Plan.jsx";
import MyMessage from "./advertiser/pages/MyMessage.jsx";
import MyMessageAdmin from "./admin/pages/MyMessage.jsx";
import MyAccount from "./advertiser/pages/MyAccount.jsx";
import CurrentOrders from "./advertiser/pages/Orders/CurrentOrders.jsx";
import PastOrders from "./advertiser/pages/Orders/PastOrders.jsx";
import RejectedOrders from "./advertiser/pages/Orders/RejectedOrders.jsx";
import AllAdvertiser from "./admin/pages/AllAdvertiser.jsx";
import UpdatePassword from "./advertiser/pages/UpdatePassword.jsx";
import PauseAds from "./admin/pages/Ads/PauseAds.jsx";
import TotalAds from "./admin/pages/Ads/TotalAds.jsx";
import TrashAds from "./admin/pages/Ads/TrashAds.jsx";
import LatestAds from "./admin/pages/Ads/LatestAds.jsx";
import AdsSales from "./admin/pages/AdsSales.jsx";
import AdvertiserReport from "./admin/pages/Report/AdvertiserReport.jsx";
import Revenue from "./admin/pages/Report/Revenue.jsx";
import Sales from "./admin/pages/Report/Sales.jsx";
import ProfileRevenue from "./admin/pages/Report/ProfileRevenue.jsx";
import AdsRevenue from "./admin/pages/Report/AdsRevenue.jsx";
import ViewAdvertiser from "./admin/pages/ViewAdvertiser.jsx";
import ViewAds from "./admin/pages/ViewAds.jsx";
import AdminAccount from "./admin/pages/AdminAccount.jsx";
import UpdatePasswordAdmin from "./admin/pages/UpdatePasswordAdmin.jsx";
import User from "./admin/pages/User/User.jsx";
import AddUser from "./admin/pages/User/CreateUser.jsx";
import CreateUser from "./admin/pages/User/CreateUser.jsx";
import EditAds from "./advertiser/pages/Ads/EditAds.jsx";
import Aboutus from "./enduser/aboutus.jsx";
import Vipads from "./enduser/vipads.jsx";
import Termandcondition from "./enduser/termandcondition.jsx";
import Faqs from "./enduser/faqs.jsx";
import Privacypolicy from "./enduser/privacypolicy.jsx";
import ViewAbcProfile from "./enduser/viewabcprofile.jsx";
import Blog from "./enduser/blog.jsx";
import Blog1 from "./enduser/Blog/Blog1.jsx";
import Loader from "./component/loader.js";
import Toaster from "./component/toaster.js";
import EditUser from "./admin/pages/User/EditUser.jsx";
import Promotezone from "./admin/pages/Managment/Promotezone.jsx";
import Coupon from "./admin/pages/Managment/Coupon.jsx";
import CreateCoupon from "./admin/pages/Managment/CreateCoupon.jsx";
import CreatePromotezone from "./admin/pages/Managment/CreatePromotezone.jsx";
import Payment from "./pages/auth-pages/Payment.jsx";
import AvailableNowData from "./enduser/AvailableNowData.jsx";
import AllAdvertiserData from "./enduser/AllAdvertiserData.jsx";
import FreeZone from "./pages/auth-pages/FreeZone.jsx";
import { useEffect } from "react";
import PaymentDetails from "./pages/auth-pages/PaymentDetails.js";

function App() {
  useEffect(() => {
    const checkAge = () => {
        if (localStorage.getItem("age18") !== "true" && window.location.pathname !== "/") {
            window.location.href = "http://localhost:3000/";
        }
    };

    checkAge(); // Call the function once when the component mounts

    // Note: We don't include localStorage.getItem("age18") in the dependency array
    // because we only want to check it once when the component mounts.
}, []);

  return (
    <>
      <Loader />
      <Toaster />
      <Router>
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<Aboutus />} />
          <Route path="/premium" element={<Vipads />} />
          <Route path="/terms-of-use" element={<Termandcondition />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/privacy-policy" element={<Privacypolicy />} />
          <Route path="/view-profile" element={<ViewAbcProfile />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog1" element={<Blog1 />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/free-zone-message" element={<FreeZone />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/comsoon" element={<Comingsoon />} />
          <Route path="/available-now" element={<AvailableNowData />} />
          <Route path="/all-advertisers" element={<AllAdvertiserData />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/payment-details" element={<PaymentDetails />} />
          <Route path="/admin" element={<AdminPanel />}>
            <Route path="" element={<AdminDashboard />} />
            <Route path="createCoupon" element={<CreateCoupon />} />
            <Route path="Coupon" element={<Coupon />} />
            <Route path="promotezone" element={<Promotezone />} />
            <Route path="createpromotezone" element={<CreatePromotezone />} />
            <Route path="alladvertiser" element={<AllAdvertiser />} />
            <Route path="pauseAds" element={<PauseAds />} />
            <Route path="latestAds" element={<LatestAds />} />
            <Route path="totalAds" element={<TotalAds />} />
            <Route path="trashAds" element={<TrashAds />} />
            <Route path="adsSales" element={<AdsSales />} />
            <Route path="advertiserRevenue" element={<AdvertiserReport />} />
            <Route path="revenueReport" element={<Revenue />} />
            <Route path="salesReport" element={<Sales />} />
            <Route path="profileRevenue" element={<ProfileRevenue />} />
            <Route path="adsRevenue" element={<AdsRevenue />} />
            <Route path="viewAdvertiser" element={<ViewAdvertiser />} />
            <Route path="viewAds" element={<ViewAds />} />
            <Route path="myAccount" element={<AdminAccount />} />
            <Route path="myMessage" element={<MyMessageAdmin />} />
            <Route path="updatePassword" element={<UpdatePasswordAdmin />} />
            <Route path="user" element={<User />} />
            <Route path="addUser" element={<AddUser />} />
            <Route path="createUser" element={<CreateUser />} />
            <Route path="editUser" element={<EditUser />} />
          </Route>
          <Route path="/advertiser" element={<AdvertiserDashboard />}>
            <Route path="" element={<Dashboard />} />
            <Route path="addProfile" element={<AddProfile />} />
            <Route path="viewProfile" element={<ViewProfile />} />
            <Route path="activeAds" element={<ActiveAds />} />
            <Route path="editAds" element={<EditAds />} />
            <Route path="createAds" element={<CreateAds />} />
            <Route path="expiredAds" element={<ExpiredAds />} />
            <Route path="availability" element={<Availability />} />
            <Route path="plan" element={<Plan />} />
            <Route path="myMessage" element={<MyMessage />} />
            <Route path="myAccount" element={<MyAccount />} />
            <Route path="updatePassword" element={<UpdatePassword />} />
            <Route path="currentOrders" element={<CurrentOrders />} />
            <Route path="pastOrders" element={<PastOrders />} />
            <Route path="rejectedOrders" element={<RejectedOrders />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;

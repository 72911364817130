/** @format */

import React, { useState } from "react"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import ViewPrice from "../../component/ViewPrice"
import DeleteTableData from "../../component/DeleteTableData"
import { showPricePopup } from "../../../actions"

const PastOrders = () => {
  const [viewPriceData, setViewPriceData] = useState()
  let dispatch = useDispatch()
  const handlePricePopup = (a) => {
    console.log("arr data : ", a)
    dispatch(showPricePopup(true))
    setViewPriceData(a)
  }
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)

  const data = []

  useEffect(() => {
    let numberOfPages = Math.floor(data.length / 5)
    setTotalPage(numberOfPages + 1)
  })
  return (
    <>
      <section className="addash">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex align-items-center">
                <h4>Past Order</h4>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="dashboardtable">
              <div
                className={`table-scrollable ${
                  totalPage > 1 
                    ? "border-radius-n-pagination"
                    : "border-radius-w-pagination"
                }`}
              >
                  <table className="table table-color table-hover table-fixed-first-columns">
                    <thead>
                      <tr>
                        <th>Ads Name</th>
                        <th>Name</th>
                        <th>Phone Number</th>
                        <th>Date</th>
                        <th>Prize</th>
                        <th>Address</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>ABC</td>
                        <td>XXX</td>
                        <td>+17896541230</td>
                        <td>10 Jan</td>
                        <td>
                          <p
                            type="button"
                            className="m-0"
                            // data-toggle="modal"
                            // data-target="#pricepopup"
                            onClick={() => handlePricePopup([])}
                          >
                            <button type="button" className="btn custom-btn">
                              View Price
                            </button>
                          </p>
                        </td>
                        <td>CHDEN, 10 Mile</td>
                        <td className="table-action">
                          {/* <DeleteTableData /> */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* Pagination Code */}

                {totalPage > 1 ? (
                  <div className="d-flex justify-content-center w-100 mx-auto pt-4 pb-3">
                    <div
                      className="pagination-css-pre"
                      onClick={() =>
                        currentPage > 1 ? setCurrentPage(currentPage - 1) : null
                      }
                    >
                      <span aria-hidden="true">
                        <i className="fa fa-chevron-left" aria-hidden="true"></i>
                      </span>
                    </div>
                    <div className="d-flex">
                      <span className="pagination-css ">{currentPage} </span>
                      <span className="pagination-css ">{` ... `}</span>
                      <span className="pagination-css ">{` ${totalPage}`}</span>
                    </div>
                    <div
                      className="pagination-css-next"
                      onClick={() =>
                        currentPage < totalPage
                          ? setCurrentPage(currentPage + 1)
                          : setCurrentPage(1)
                      }
                    >
                      <span aria-hidden="true">
                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                      </span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PastOrders

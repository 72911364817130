/** @format */

import React, { useEffect } from "react";
import $ from "jquery";
import user from "../../../src/asset/img/userpro.png";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { idToDelete, isLoader, isToaster, userData } from "../../actions";
import { Link, useNavigate } from "react-router-dom";
import DeleteEntry from "../component/DeleteTableData";
import axios from "axios";
import {
  GetCountries,
  GetState,
  GetCity,
  // GetLanguages, //async functions
} from "react-country-state-city";

const MyAccount = () => {
  const [countryid, setCountryid] = useState(-1);
  const [stateid, setStateid] = useState(-1);
  const [cityid, setCityid] = useState(-1);

  const [countryindex, setCountryindex] = useState();
  const [stateindex, setStateindex] = useState();
  const [cityindex, setCityindex] = useState();

  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const data = useSelector((state) => state.user_data);
  let token = localStorage.getItem("userToken");
  const [loading, setLoading] = useState(false);
  const [edata, setEdata] = useState(data);

  let navigate = useNavigate();
  let dispatch = useDispatch();
  let name, Value;
  const handleInputs = (e) => {
    name = e.target.name;
    Value = e.target.value;
    setEdata({ ...edata, [name]: Value });
  };

  const onDelete = () => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/api/delete-advertiser`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch(
          isToaster({ flag: true, msg: "Account deleted Successfully!" })
        );
        navigate("/login");
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSave = () => {
    
    const customConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    
    if(
      edata.phone &&
      edata.street_address &&
      cityList.find(x => x.id == cityid) &&
      stateList.find(x => x.id == stateid) &&
      countriesList.find(x => x.id == countryid) &&
      edata.zip
    ){
      let postData = {
        phone: edata.phone,
        street_address: edata.street_address,
        city: cityList.find(x => x.id == cityid).name,
        state: stateList.find(x => x.id == stateid).name,
        country: countriesList.find(x => x.id == countryid).name,
        zip: edata.zip,
      };
      setLoading(true);
      axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/update-my-account`,
        postData,
        customConfig
      )
      .then((res) => {
        $(".editable-form").hide();
        $(".readonly-form").show();
        $("#editButton").show();
        $("#cancelButton").hide();
        setLoading(false);
        dispatch(isLoader(true));
        axios
          .get(`${process.env.REACT_APP_API_URL}/api/get-advertiser`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            dispatch(isLoader(false));
            dispatch(userData(res.data.user));
            // window.location.reload()
            console.log("advertiser data in my account: ", res.data.user);
          })
          .catch((err) => {
            dispatch(isLoader(false));
            console.log(err);
          });
        dispatch(
          isToaster({ flag: true, msg: "Account Updated Successfully!" })
        );
        console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    } else {
      dispatch(isToaster({flag: true, msg: 'Please fill all details!'}))
    }
    
  };

  $(document).ready(function () {
    $("#editButton").click(function () {
      $(".editable-form").show();
      $(".readonly-form").hide();
      $("#editButton").hide();
      $("#cancelButton").show();
    });

    $("#cancelButton").click(function () {
      $(".editable-form").hide();
      $(".readonly-form").show();
      $("#editButton").show();
      $("#cancelButton").hide();
    });
  });

  // $(document).ready(function () {
  //   $("#editButton1").click(function () {
  //     $(".editable-form1").show()
  //     $(".readonly-form1").hide()
  //     $("#editButton1").hide()
  //     $("#cancelButton1").show()
  //   })

  //   $("#cancelButton1").click(function () {
  //     $(".editable-form1").hide()
  //     $(".readonly-form1").show()
  //     $("#editButton1").show()
  //     $("#cancelButton1").hide()
  //   })
  // })

  // console.log("Country list: ", countriesList);

  useEffect(() => {
    GetCountries().then((result) => {
      setCountriesList(result);
    });
  }, []);

  useEffect(() => {
    // Fetch states when countryid changes
    if (countryid !== -1) {
      GetState(countryid).then((result) => {
        // console.log("stateList: ", result)
        setStateList(result);
      });
    }
  }, [countryid]);

  useEffect(() => {
    // Fetch cities when both countryid and stateid change
    if (countryid !== -1 && stateid !== -1) {
      GetCity(countryid, stateid).then((result) => {
        // console.log("CityList: ", result)
        setCityList(result);
      });
    }
  }, [countryid, stateid]);

  return (
    <>
      <DeleteEntry type="Account" />
      <section className="addash">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex align-items-center">
                <h4>My Account</h4>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 ds-card">
              <div className="viewcard-img">
                <div className="">
                  <div className="d-flex justify-content-center">
                    <div className="pic-holder-account">
                      <img
                        src={data.profile_photo ? data.profile_photo : user}
                        alt="UploadPhoto"
                        id="blah1"
                        className="pic mb-2"
                      />
                    </div>
                  </div>
                  <div className="account-name">
                    <h4>{data.firstname}</h4>
                    <p>{data.email}</p>
                  </div>
                </div>
              </div>
            </div> 
            <div className="col-lg-8 ds-card">
              <div className="viewcard ">
                <div className="row  basic-detail">
                  <div className="d-flex justify-content-between align-items-center">
                    <h4 className="infoedit m-0"> Profile Information </h4>
                    <span className="infoedit1">
                      <button
                        id="editButton"
                        className="custom-btn"
                        onClick={() => setEdata(data)}
                      >
                        {" "}
                        Edit{" "}
                      </button>{" "}
                    </span>
                  </div>
                  <span className="basic-detail-border"></span>
                </div>
                <div className="row mt-3">
                  <div className="readonly-form">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Username</p>
                        <h6 className="basicdata">{data.username}</h6>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Email</p>
                        <h6 className="basicdata">{data.email}</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Working Name</p>
                        <h6 className="basicdata">{data.firstname}</h6>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                      <p className="basicinfo">Gender</p>
                      <h6 className="basicdata">{data.gender}</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Phone Number</p>
                        <h6 className="basicdata">{data.phone}</h6>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Age</p>
                        <h6 className="basicdata">{data.age}</h6>
                      </div>
                    </div>
                    <div className="readonly-form1">
                      {/* <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <p className="basicinfo">Street Address</p>
                          <h6 className="basicdata">{data.street_address}</h6>
                        </div>
                      </div> */}
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <p className="basicinfo">City</p>
                          <h6 className="basicdata">{data.city}</h6>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <p className="basicinfo">State</p>
                          <h6 className="basicdata">{data.state}</h6>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <p className="basicinfo">Country</p>
                          <h6 className="basicdata">{data.country}</h6>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <p className="basicinfo">Zip Code</p>
                          <h6 className="basicdata">{data.zip}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="editable-form" style={{ display: "none" }}>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="input-label">
                            <label className="mb-1">
                              {" "}
                              <span>Username</span>{" "}
                            </label>
                            <input
                              type="text"
                              name="username"
                              value={data.username}
                              onChange={handleInputs}
                              // placeholder="Username"
                              disabled
                              className="form-control form-control-ctm mb-2"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="input-label">
                            <label className="mb-1">
                              {" "}
                              <span>Email</span>{" "}
                            </label>
                            <input
                              type="text"
                              name="email"
                              value={data.email}
                              onChange={handleInputs}
                              // placeholder="abc@example.com"
                              disabled
                              className="form-control form-control-ctm mb-2"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="input-label">
                            <label className="mb-1">
                              {" "}
                              <span>First Name</span>{" "}
                            </label>
                            <input
                              type="text"
                              name="firstname"
                              value={data.firstname}
                              onChange={handleInputs}
                              // placeholder="First Name"
                              disabled
                              className="form-control form-control-ctm mb-2"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="input-label">
                            <label className="mb-1">
                              {" "}
                              <span>Last Name</span>{" "}
                            </label>
                            <input
                              type="text"
                              name="lastname"
                              value={data.lastname}
                              onChange={handleInputs}
                              // placeholder="Last Name"
                              disabled
                              className="form-control form-control-ctm mb-2"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="input-label">
                            <label className="mb-1">
                              {" "}
                              <span>Phone Number</span>{" "}
                            </label>
                            <input
                              type="number"
                              name="phone"
                              value={edata.phone}
                              onChange={handleInputs}
                              // placeholder="Phone Number"
                              className="form-control form-control-ctm mb-2"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="input-label">
                            <label className="mb-1">
                              {" "}
                              <span>Gender</span>{" "}
                            </label>
                            <input
                              type="text"
                              name="gender"
                              value={data.gender}
                              onChange={handleInputs}
                              // placeholder="Gender"
                              disabled
                              className="form-control form-control-ctm mb-2"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="input-label">
                            <label className="mb-1">
                              {" "}
                              <span>Age</span>{" "}
                            </label>
                            <input
                              type="text"
                              name="age"
                              value={data.age}
                              onChange={handleInputs}
                              // placeholder="Age"
                              disabled
                              className="form-control form-control-ctm mb-2"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12"></div>
                      </div>
                      {/* <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="input-label">
                            <label>
                              {" "}
                              <span>Street Adress</span>{" "}
                            </label>
                            <input
                              type="text"
                              name="street_address"
                              value={edata.street_address}
                              onChange={handleInputs}
                              // placeholder="ABC"
                              className="form-control form-control-ctm mb-2"
                            />
                          </div>
                        </div>
                      </div> */}
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="input-label">
                            <label className="mb-1">
                              {" "}
                              <span>Country</span>{" "}
                            </label>
                            <select
                              className="form-control form-control-ctm mb-2"
                              aria-label="select"
                              // value={adData.country}
                              name="country"
                              // onChange={handleInputs}
                              onChange={(e) => {
                                const country = countriesList[e.target.value];
                                // console.log("country", country) //here you will get full country object.
                                setCountryid(country.id);
                                // GetState(country.id).then((result) => {
                                //   setStateList(result);
                                // });
                              }}
                              value={countryindex}
                              // placeholder="Country"
                            >
                              <option selected>-Country-</option>
                              {countriesList.map((item, index) => (
                                <option key={index} value={index}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            {/* <input
                              type="text"
                              name="city"
                              value={edata.city}
                              onChange={handleInputs}
                              placeholder="San Diego"
                              className="form-control form-control-ctm"
                            /> */}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="input-label">
                            <label className="mb-1">
                              {" "}
                              <span>State</span>{" "}
                            </label>
                            <select
                              className="form-control form-control-ctm mb-2"
                              aria-label="select"
                              // value={adData.country}
                              name="state"
                              // onChange={handleInputs}
                              onChange={(e) => {
                                const state = stateList[e.target.value];
                                // console.log("state", state) //here you will get full state object.
                                setStateid(state.id);
                                // GetCity(countryid, state.id).then((result) => {
                                //   setCityList(result);
                                // });
                              }}
                              value={stateindex}
                              // placeholder="State"
                            >
                              <option selected>-State-</option>
                              {stateList.map((item, index) => (
                                <option key={index} value={index}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="input-label">
                            <label className="mb-1">
                              <span>City</span>
                            </label>
                            <select
                              className="form-control form-control-ctm mb-2"
                              aria-label="select"
                              // value={adData.country}
                              name="city"
                              // onChange={handleInputs}
                              onChange={(e) => {
                                const city = cityList[e.target.value];
                                // console.log("city", city) //here you will get full city object.
                                setCityid(city.id);
                              }}
                              value={cityindex}
                              // placeholder="City"
                            >
                              <option selected>-City-</option>
                              {cityList.map((item, index) => (
                                <option key={index} value={index}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="input-label">
                            <label className="mb-1">
                              <span>Zip Code</span>
                            </label>
                            <input
                              type="number"
                              name="zip"
                              value={edata.zip}
                              onChange={handleInputs}
                              // placeholder="174001"
                              className="form-control form-control-ctm mb-2"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-12">
                          <button
                            type="submit"
                            id="saveButton"
                            disabled={loading}
                            className="btn cus-btn "
                            onClick={onSave}
                          >
                            Save&nbsp;
                            {loading ? (
                              <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : null}
                          </button>
                          &nbsp;
                          <button
                            type="submit"
                            id="cancelButton"
                            className="btn sec-btn"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="row mt-4 ">
                <div className="col-lg-6 col-sm-6 ds-card">
                  <Link to="/advertiser/updatePassword">
                    <button type="button" className="btn cus-btn">
                      Change Password
                    </button>
                  </Link>
                </div>
                <div className="col-lg-6 col-sm-6 text-right ds-card">
                  <button
                    type="button"
                    className="btn sec-btn"
                    onClick={() => dispatch(idToDelete(data.id))}
                  >
                    Delete Account
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyAccount;

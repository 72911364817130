import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import no_data from "../../../asset/img/no_data.png";
import { Link } from "react-router-dom";
import { idToDelete, isLoader, promotedZone } from "../../../actions";
import DeleteEntry from "../../component/DeleteEntry";
import axios from "axios";

const Promotezone = () => {
  const token = localStorage.getItem("userToken");
  const dispatch = useDispatch();

  const promotedzoneStore = useSelector((s) => s.promoted_zone);

  const fetchPromotedZone = () => {
    dispatch(isLoader(true));
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/get-promoting-zones`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch(isLoader(false));
        dispatch(promotedZone(res.data.promotingZones));
      })
      .catch((err) => {
        dispatch(isLoader(false));
      });
  };

  useEffect(() => {
    fetchPromotedZone();
  }, []);

  return (
    <>
      <DeleteEntry type="Promoted Zone" />
      <section className="addash">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between align-items-center">
                <span className="d-flex justify-content-between align-items-center">
                  <h4 className="m-0">Promoted Zone</h4>
                </span>
                <span>
                  <div>
                    <Link to="/admin/createpromotezone">
                      {" "}
                      <button type="button" className="btn cus-btn">
                        Create New Zone
                      </button>
                    </Link>
                  </div>
                </span>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="dashboardtable">
                <div
                  className={`table-scrollable  
                 
                      ? "border-radius-n-pagination"
                      : "border-radius-w-pagination"
                  }`}
                >
                  <table className="table table-color table-hover table-fixed-first-columns">
                    <thead>
                      <tr>
                        <th>City</th>
                        <th>State</th>
                        <th>Country</th>
                        <th>Zip Code</th>
                        <th>Valid From</th>
                        <th>Valid to</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(promotedzoneStore) &&
                      promotedzoneStore.length != 0 ? (
                        promotedzoneStore.map((x) => {
                          return (
                            <tr>
                              <td>{x.city}</td>
                              <td>{x.state}</td>
                              <td>{x.country}</td>
                              <td>{x.zip}</td>
                              <td>{x.valid_from}</td>
                              <td>{x.valid_to}</td>
                              <td className="table-action">
                                <div className="d-flex justify-content-center align-items-center">
                                  <p
                                    type="button"
                                    className="my-0 mx-2 "
                                    onClick={() => dispatch(idToDelete(x.id))}
                                  >
                                    <i className="far fa-trash-alt"></i>
                                  </p>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <img
                              src={no_data}
                              alt="Description of the image"
                              width={277}
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Promotezone;

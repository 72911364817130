const initialState = {
    firstname: "",
    lastname: "",
    hair_coloe: "",
    gender: "",
    body_type: "",
    age: "",
    ethnicity: "",
    eye_color: "",
    breast_size: "",
    height: "",
    images: [],
    videos: [],
}

const advertiser_data = (state = initialState, action) => {
     switch(action.type) {
        case "ADVERTISER_DATA": return state = action.payload
        default: return state;
     }
}

export default advertiser_data;
import React, { useEffect, useState } from "react";
import "../../asset/css/carousel.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import gallery from "../../asset/img/gallery.png";
import handleLastActive from "../../component/handleLastActive.js";
import {
  advertisementId,
  advertiserId,
  availableList,
  isLoader,
} from "../../actions";

const AvailableNow = () => {
  // const [topList, setTopList] = useState([]);
  const search_gender = useSelector((s) => s.search_gender);
  const search_city = useSelector((s) => s.search_city);
  const search_age = useSelector((s) => s.search_age);
  const search_eye_color = useSelector((s) => s.search_eye_color);
  const search_hair_color = useSelector((s) => s.search_hair_color);
  const search_height = useSelector((s) => s.search_height);
  const search_body_type = useSelector((s) => s.search_body_type);
  const search_breast_size = useSelector((s) => s.search_breast_size);
  const search_ethnicity = useSelector((s) => s.search_ethnicity);
  const search_availability = useSelector((s) => s.search_availability);
  const AvailableList = useSelector((s) => s.available_list);
  const advertiserCity = localStorage.getItem("advertiserCity");
  const [dataArr, setDataArr] = useState([]);
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const fetchPremiumAds = () => {
    setDataArr([]);
    dispatch(isLoader(true));
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/get-available-advertisements?defaultCity=${advertiserCity}&searchedCity=${search_city}&eye_color=${search_eye_color}&breast_size=${search_breast_size}&hair_color=${search_hair_color}&height=${search_height}&ethnicity=${search_ethnicity}&age=${search_age}&body_type=${search_body_type}&gender=${search_gender}`
      )
      .then((res) => {
        dispatch(isLoader(false));
        setDataArr((x) => [...x, res.data.availableAdvertisements.data]);
        if (res.data.availableAdvertisements.data.length < 8) {
          return null;
        } else {
          axios
            .get(
              `${process.env.REACT_APP_API_URL}/api/get-available-advertisements?page=2&defaultCity=${advertiserCity}&searchedCity=${search_city}&eye_color=${search_eye_color}&breast_size=${search_breast_size}&hair_color=${search_hair_color}&height=${search_height}&ethnicity=${search_ethnicity}&age=${search_age}&body_type=${search_body_type}&gender=${search_gender}`
            )
            .then((res) => {
              dispatch(isLoader(false));
              setDataArr((x) => [...x, res.data.availableAdvertisements.data]);
              if (res.data.availableAdvertisements.data.length < 8) {
                return null;
              } else {
                axios
                  .get(
                    `${process.env.REACT_APP_API_URL}/api/get-available-advertisements?page=3&defaultCity=${advertiserCity}&searchedCity=${search_city}&eye_color=${search_eye_color}&breast_size=${search_breast_size}&hair_color=${search_hair_color}&height=${search_height}&ethnicity=${search_ethnicity}&age=${search_age}&body_type=${search_body_type}&gender=${search_gender}`
                  )
                  .then((res) => {
                    dispatch(isLoader(false));
                    setDataArr((x) => [
                      ...x,
                      res.data.availableAdvertisements.data,
                    ]);
                    console.log("data after fetch: ", dataArr);
                  })
                  .catch((err) => {
                    dispatch(isLoader(false));
                    console.log(err);
                  });
              }
            })
            .catch((err) => {
              dispatch(isLoader(false));
              console.log(err);
            });
        }
      })
      .catch((err) => {
        dispatch(isLoader(false));
        console.log(err);
      });
  };

  useEffect(() => {
    if (AvailableList.length == 0) {
      fetchPremiumAds();
    } else {
      fetchPremiumAds();
    }
  }, [search_gender, search_city, advertiserCity]);
  return (
    <>
      <section className="spacing-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="premium-text">
                <h3>
                  Lorium Ipsum
                  <span className="premium-text-color">Dummy Text</span>
                </h3>
                <p>
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups.
                </p>
              </div>
            </div>
          </div>
          <div className="row section-detail">
            <div className="d-flex justify-content-between p-0">
              {/* <h4 className="section-infoedit m-0"> Available Now </h4> */}
              <h4 className="section-infoedit m-0">
                Available <span>Now</span>{" "}
              </h4>
              <span>
                <div className="carousel-btn">
                  <a
                    className="carousel-btn-pre"
                    href="#premiumslider"
                    role="button"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="carousel-btn-next"
                    href="#premiumslider"
                    role="button"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Next</span>
                  </a>
                </div>
              </span>
            </div>
            <span className="section-detail-border"></span>
          </div>
          <div
            id="premiumslider"
            className="carousel slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators premium-carousel-dot">
              <li
                data-bs-target="#premiumslider"
                data-bs-slide-to="0"
                className="active"
              ></li>
              <li
                data-target="#premiumslider"
                className={` ms-1 ${dataArr.length < 2 ? "d-none" : null}`}
                data-bs-slide-to="1"
              ></li>
              <li
                data-target="#premiumslider"
                className={`ms-1 ${dataArr.length < 3 ? "d-none" : null}`}
                data-bs-slide-to="2"
              ></li>
            </ol>
            <div className="carousel-inner p-0">
              {dataArr
                ? dataArr.map((s, index) => (
                    <div
                      key={index}
                      className={`carousel-item ${
                        index == 0 ? "active" : null
                      }`}
                    >
                      <div className="row ads-lists mt-4">
                        {s
                          ? s.map((x, idx) => {
                              return (
                                <div
                                  key={idx}
                                  className={`col-lg-3 col-md-3 col-6 mb-4 `}
                                >
                                  <div
                                    className="adscard-link"
                                    onClick={() =>
                                      navigate("/view-profile") &
                                      dispatch(advertiserId(x.advertiser_id)) &
                                      dispatch(advertisementId(x.ad_id))
                                    }
                                  >
                                    <div className="adscard">
                                      <div className="ads-card-body">
                                        <img
                                          src={x.profile_photo}
                                          alt="adsimage"
                                        />
                                        <div className="ads-photo">
                                          <span className="ads-gallery">
                                            <i
                                              class="fa-regular fa-images"
                                              aria-hidden="true"
                                            ></i>
                                           {x.advertiser_photos_count}
                                          </span>
                                          <span className="ads-gallery">
                                            {x.duration_price[0].price} $
                                          </span>
                                        </div>
                                        <div className="ads-card-body-footer">
                                          <h5>
                                            <span>{x.username}</span>
                                            {x.age}
                                          </h5>
                                          <p className="adss-footer">
                                            <i className="fa  fa-location-dot"></i>
                                            <span>{x.location}</span>
                                          </p>
                                          <p className="adss-footer">
                                            {handleLastActive(
                                              x.last_active
                                                ? x.last_active
                                                : "2024-04-22 erge"
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-12 text-center">
              <Link to="/available-now">
                {" "}
                <button type="button" className="btn cus-btn">
                  View All
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AvailableNow;

/** @format */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  advertisementId,
  advertiserCity,
  advertiserId,
  isLoader,
  showAvailability,
} from "../../actions";
import { useNavigate } from "react-router-dom";
import Calendar from "../../advertiser/component/Calendar";
import handleLastActive from "../../component/handleLastActive.js";
import { topListAction } from "../../actions";
import { error } from "jquery";
import BookingButton from "../SingleProfile/BookingButton.jsx";

const HeroCrausel = () => {
  // const [topList, setTopList] = useState([]);
  const available_days = useSelector((s) => s.available_days);
  const City = localStorage.getItem("advertiserCity");
  const topList = useSelector((s) => s.top_list);
  let dispatch = useDispatch();
  const [showCalendar, setShowCalendar] = useState([]);
  let navigate = useNavigate();

  const findMyLocation = () => {
    const success = (position) => {
      console.log(position);
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      console.log(latitude + " " + longitude);

      const geoApiUrl = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`;
      dispatch(isLoader(true));
      fetch(geoApiUrl)
        .then((res) => res.json())
        .then((data) => {
          dispatch(advertiserCity(data.city));
          localStorage.setItem("advertiserCity", data.city);
          console.log(data.city);
          axios
            .get(
              `${process.env.REACT_APP_API_URL}/api/get-top-primary-advertisements?city=${data.city}`
            )
            .then((res) => {
              dispatch(isLoader(false));
              // console.log(res.data.topPrimaryAdvertisements.data)
              dispatch(topListAction(res.data.topPrimaryAdvertisements.data));
            })
            .catch((err) => {
              dispatch(isLoader(false));
              console.log(err);
            });
        });
    };

    const error = () => {
      console.log("geo location blocked, data is fetched from IP Address");
    };

    navigator.geolocation.getCurrentPosition(success, error);
  };

  useEffect(() => {
    if (!City) {
      dispatch(isLoader(true));
      axios
        .get("https://ipinfo.io")
        .then((res) => {
          const city = res.data.city.replace("ā", "a");
          dispatch(advertiserCity(city));
          localStorage.setItem("advertiserCity", city);
          dispatch(isLoader(false));
          axios
            .get(
              `${process.env.REACT_APP_API_URL}/api/get-top-primary-advertisements?city=${city}`
            )
            .then((res) => {
              dispatch(isLoader(false));
              dispatch(topListAction(res.data.topPrimaryAdvertisements.data));
            })
            .catch((err) => {
              dispatch(isLoader(false));
              console.log(err);
            });
        })
        .catch((err) => {
          dispatch(isLoader(false));
          console.log(err);
        });
    } else {
      dispatch(isLoader(true));
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/get-top-primary-advertisements?city=${City}`
        )
        .then((res) => {
          dispatch(isLoader(false));
          dispatch(topListAction(res.data.topPrimaryAdvertisements.data));
        })
        .catch((err) => {
          dispatch(isLoader(false));
          console.log(err);
        });
    }
  }, []);

  window.onload = function () {
    if (!City) {
      dispatch(isLoader(true));
      axios
        .get("https://ipinfo.io")
        .then((res) => {
          const city = res.data.city.replace("ā", "a");
          dispatch(advertiserCity(city));
          localStorage.setItem("advertiserCity", city);
          dispatch(isLoader(false));
          axios
            .get(
              `${process.env.REACT_APP_API_URL}/api/get-top-primary-advertisements?city=${city}`
            )
            .then((res) => {
              dispatch(isLoader(false));
              dispatch(topListAction(res.data.topPrimaryAdvertisements.data));
            })
            .catch((err) => {
              dispatch(isLoader(false));
              console.log(err);
            });
        })
        .catch((err) => {
          dispatch(isLoader(false));
          console.log(err);
        });
    } else {
      dispatch(isLoader(true));
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/get-top-primary-advertisements?city=${City}`
        )
        .then((res) => {
          dispatch(isLoader(false));
          dispatch(topListAction(res.data.topPrimaryAdvertisements.data));
        })
        .catch((err) => {
          dispatch(isLoader(false));
          console.log(err);
        });
    }
    setTimeout(findMyLocation, 3000);
  };

  const onCheckAvailability = (dates) => {
    dispatch(showAvailability(true));
    setShowCalendar(dates);
  };

  return (
    <>
      <section className="mt-4">
        <div id="bannerslider" className="carousel slide" data-ride="carousel">
          <ol className="carousel-indicators premium-carousel-dot">
            {topList.map((x, i) => {
              if (i <= 4) {
                return (
                  <li
                    data-bs-target="#bannerslider"
                    data-bs-slide-to={i}
                    className={`${i == 0 ? "active" : null}`}
                  ></li>
                );
              } else if (i > 4) {
                return (
                  <li
                    data-bs-target="#bannerslider"
                    data-bs-slide-to={i}
                    className={`${i == 0 ? "active" : null}`}
                  ></li>
                );
              }
            })}
          </ol>

          <div className="carousel-inner">
            {topList
              ? topList.map((x, i) => {
                  return (
                    <div className={`carousel-item ${i == 0 ? "active" : ""}`}>
                      <div className="container ">
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="header_hero">
                              <img
                                className="img-fluid"
                                src={x.profile_photo}
                                alt="First 1 slide"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="live-ad-card">
                              <h2> {x.fullname}</h2>
                              <div className="detalist-ad mt-3">
                                <p>
                                  <span className="details1">Gender: </span>
                                  {x.gender}
                                </p>
                                <p>
                                  <span className="details2">Age: </span>
                                  {x.age}
                                </p>
                              </div>
                              <div className="detalist-ad flex-column flex-lg-row">
                                <p>
                                  <span className="details1">City: </span>
                                  {x.location}
                                </p>
                                <p className="">
                                  {handleLastActive(
                                    x.last_active
                                      ? x.last_active
                                      : "2024-04-22 erge"
                                  )}
                                </p>
                              </div>

                              <div className="mt-3">
                                <div className="detalist-ad">
                                  <span className="details1">Ad Name:</span>
                                  {x.ad_name}
                                </div>
                                <div className="detalist-ad">
                                  <span className="details1">Height:</span>
                                  {x.height}
                                </div>
                                <div
                                  className={`detalist-ad ${
                                    x.gender === "Male" ? "d-none" : "d-flex"
                                  }`}
                                >
                                  <span className="details1">Breast Size:</span>
                                  {x.breast_size}
                                </div>
                                <div className="detalist-ad">
                                  <span className="details1">Ethnicity:</span>
                                  {x.ethnicity}
                                </div>
                                <div className="detalist-ad">
                                  <span className="details1">Body Type:</span>
                                  {x.body_type}
                                </div>
                              </div>
                              <div className="text-center mt-2">
                                <span className="detalist-ad-ava">
                                  <button
                                    type="button"
                                    className="btn pri1-btn detalist-ad-ava-btn"
                                    onClick={() =>
                                      onCheckAvailability() &
                                      dispatch(advertiserId(x.advertiser_id)) &
                                      dispatch(advertisementId(x.ad_id))
                                    }
                                  >
                                    Check Availability
                                  </button>
                                </span>
                              </div>
                              <div className="mt-4 text-center d-flex flex-col justify-content-center">
                                <div className="d-flex justify-content-center hero-btn-crausal">
                                  <div>
                                    <BookingButton
                                      type="hero"
                                      advertiser_id={x.advertiser_id}
                                    />
                               </div>
                                  <div>
                                    <button
                                      type="button"
                                      className="btn cus-btn "
                                      onClick={() =>
                                        navigate("/view-profile") &
                                        dispatch(
                                          advertiserId(x.advertiser_id)
                                        ) &
                                        dispatch(advertisementId(x.ad_id))
                                      }
                                    >
                                      More Detail
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>

          <a
            className="carousel-control-prev"
            href="#bannerslider"
            role="button"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#bannerslider"
            role="button"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </section>
      <Calendar
        availableDates={
          available_days
            ? available_days
            : ["2024-03-05", "2024-03-18", "2024-03-19", "2024-03-20"]
        }
      />
    </>
  );
};

export default HeroCrausel;

/** @format */

import React from "react"
import { Outlet } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useEffect } from "react"
import axios from "axios"
import { isLoader, userData } from "../actions"
import { useSelector } from "react-redux"
import AdminSidebar from "../admin/component/AdminSidebar"

const AdminPanel = () => {
  // const data = useSelector((state) => state.user_data)
  const role = localStorage.getItem("userRole")
  const token = localStorage.getItem("userToken")
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(isLoader(true));
    if (token) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/get-user`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          dispatch(isLoader(false));
          dispatch(userData(res.data.user))
          console.log("admin data at admin panel: ", res.data.user)
        })
        .catch((err) => {
          dispatch(isLoader(false));
          navigate("/login")
        })
    } else {
      dispatch(isLoader(false));
      navigate("/login")
    }

  }, [])
  return (
    <>
      {/* {token? <AdminDashboard /> : () => navigate("/login")} */}
      <AdminSidebar />
    </>
  )
}

export default AdminPanel

/** @format */

import React from "react"
import Availablity_Table from "../component/Availablity_Table"

const Availability = () => {
  return (
    <>
    <section className="addash">
    <div className="container-fluid">
        <Availablity_Table />
      </div>
    </section>    
    </>
  )
}

export default Availability
 
import React, { useEffect, useState } from 'react';
import '../../asset/css/advertiser.css'; // Adjust path to your CSS file as needed
import { useDispatch, useSelector } from 'react-redux';
import {availableDays as availability} from '../../actions';

const UpdateAvailability = (props) => {
  const dispatch = useDispatch();
  // Local state for available days
  const [availableDays, setAvailableDays] = useState(props.available);

  // Function to toggle availability for a day
  const toggleAvailability = (day) => {
    let updatedDays;
    if (availableDays.includes(day)) {
      // Day is already available, so remove it from availableDays
      updatedDays = availableDays.filter((d) => d !== day);
    } else {
      // Day is not available, so add it to availableDays
      updatedDays = [...availableDays, day];
    }
    setAvailableDays(updatedDays);
    dispatch(availability(updatedDays))
    console.log(updatedDays)
  };

  // Days of the week
  const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const renderComponent = () => {
    return(
      <div className="container">
      {/* <h2>Update Weekdays Availability</h2> */}
      <div className="weekdays-container">
        {weekdays.map((day) => (
          <div
            key={day}
            className={`day ${availableDays.includes(day) ? 'available' : ''}`}
            onClick={() => toggleAvailability(day)}
          >
            {day.substring(0, 1).toUpperCase()}
          </div>
        ))}
      </div>
    </div>
    )
  }

  useEffect(() => {
    setAvailableDays(props.available)
  }, [])

  return (
    <>
    {availableDays? renderComponent() : null}
    </>
  );
};

export default UpdateAvailability;

const initialState = []

const available_list_more = (state = initialState, action) => {
     switch(action.type) {
        case "AVAILABLE_LIST_MORE": return state.concat(action.payload)
        case 'RESET_STATE': return state = initialState;
        default: return state;
     }
}

export default available_list_more;
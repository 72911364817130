import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  advertisementId,
  advertiserId,
  allList1,
  allList2,
  allList3,
  isLoader,
  isToaster,
  resetState,
} from "../../actions";
import handleLastActive from "../../component/handleLastActive.js";
import gallery from "../../asset/img/gallery.png";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const AllAdvertiserCardData = () => {
  const advertiserCity = localStorage.getItem("advertiserCity");

  const [pageNumber, setPageNumber] = useState(1);

  const [loading, setLoading] = useState(false);

  const [isLoadMore, setIsLoadMore] = useState(true);

  const search_gender = useSelector((s) => s.search_gender);
  const search_city = useSelector((s) => s.search_city);
  const search_age = useSelector((s) => s.search_age);
  const search_eye_color = useSelector((s) => s.search_eye_color);
  const search_hair_color = useSelector((s) => s.search_hair_color);
  const search_height = useSelector((s) => s.search_height);
  const search_body_type = useSelector((s) => s.search_body_type);
  const search_breast_size = useSelector((s) => s.search_breast_size);
  const search_ethnicity = useSelector((s) => s.search_ethnicity);
  const search_availability = useSelector((s) => s.search_availability);

  const topList = useSelector((s) => s.all_list1);
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const fetchAllAds = (x) => {
    setLoading(true);
    dispatch(isLoader(true));
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/get-all-advertisements-home?page=${x}&defaultCity=${advertiserCity}&searchedCity=${search_city}&eye_color=${search_eye_color}&hair_color=${search_hair_color}&breast_size=${search_breast_size}&height=${search_height}&ethnicity=${search_ethnicity}&age=${search_age}&body_type=${search_body_type}&gender=${search_gender}`
      )
      .then((res) => {
        setLoading(false);
        dispatch(isLoader(false));
        // console.log(res.data.topPrimaryAdvertisements.data)
        // setTopList(res.data.topPrimaryAdvertisements.data);
        dispatch(allList1(res.data.allAdvertisements.data));
        setIsLoadMore(true);
        localStorage.setItem("Page", 0);
        if (res.data.allAdvertisements.data.length < 8) {
          setIsLoadMore(false);
          dispatch(
            isToaster({
              flag: true,
              msg: "You reached the maximum Advertisements",
            })
          );
        }
        if (res.data.allAdvertisements.data.length == 0) {
          setIsLoadMore(false);
          if (x > 1) {
            dispatch(
              isToaster({
                flag: true,
                msg: "You reached the maximum Advertisements",
              })
            );
          } else {
            dispatch(isToaster({ flag: true, msg: "No matching data found!" }));
          }

          localStorage.setItem("Page", x);
        }
      })
      .catch((err) => {
        setLoading(false);
        dispatch(isLoader(false));
        console.log(err);
      });
  };

  const onLoadMore = () => {
    const page = localStorage.getItem("Page");
    if (page > 1) {
      dispatch(
        isToaster({ flag: true, msg: "You reached the maximum Advertisements" })
      );
    } else {
      setPageNumber(pageNumber + 1);
      fetchAllAds(pageNumber + 1);
    }
    localStorage.setItem("Page", null);
  };

  // useEffect(() => {
  //   if (pageNumber === 1) {
  //     dispatch(resetState());
  //     fetchAllAds(pageNumber);
  //   } else {
  //     fetchAllAds(pageNumber);
  //   }
  // }, [pageNumber]);

  useEffect(() => {
    dispatch(resetState());
    setPageNumber(1);
    fetchAllAds(1);
  }, [
    search_gender,
    search_city,
    advertiserCity,
    search_age,
    search_body_type,
    search_breast_size,
    search_height,
    search_ethnicity,
    search_availability,
    search_eye_color,
    search_hair_color,
  ]);

  return (
    <>
      <section className="spacing-page">
        <div className="container">
          <div className="row ads-lists mt-4">
            {topList
              ? topList.map((x) => {
                  return (
                    <div className="col-lg-3 col-md-3 col-6 mb-4 ">
                      <div
                        className="adscard-link"
                        onClick={() =>
                          navigate("/view-profile") &
                          dispatch(advertiserId(x.advertiser_id)) &
                          dispatch(advertisementId(x.ad_id))
                        }
                      >
                        <div className="adscard">
                          <div className="ads-card-body">
                            <img src={x.profile_photo} alt="adsimage" />
                            <div className="ads-photo">
                              <span className="ads-gallery">
                                <i
                                  class="fa-regular fa-images"
                                  aria-hidden="true"
                                ></i>
                                4
                              </span>
                              <span className="ads-gallery">
                                {x.duration_price[0].price} $
                              </span>
                            </div>
                            <div className="ads-card-body-footer">
                              <h5>
                                <span>{x.username}</span>
                                {x.age}
                              </h5>
                              <p className="adss-footer">
                                <i className="fa  fa-location-dot"></i>
                                <span>{x.location}</span>
                              </p>
                              <p className="adss-footer">
                                  {handleLastActive(
                                    x.last_active
                                      ? x.last_active
                                      : "2024-04-22 erge"
                                  )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
          <div className="row mt-3">
            <div
              className={`col-lg-12 text-center ${
                topList.length == 0 || !isLoadMore ? "d-none" : null
              }`}
            >
              <button
                type="button"
                className="btn cus-btn"
                disabled={loading}
                onClick={onLoadMore}
              >
                Load More &nbsp;
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : null}
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AllAdvertiserCardData;

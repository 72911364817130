/** @format */

import React from "react"
import LatestAdsTable from "../../component/LatestAdsTable"

const LatestAds = () => {
  return (
    <>
      <section className="addash">
        <div className="container-fluid">
          <LatestAdsTable />
        </div>
      </section>
    </>
  )
}

export default LatestAds

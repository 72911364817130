import React from 'react'

const BlogBanner = () => {
  return (
    <>
       <section className="aboutpage">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h1 className="m-0">Blogs</h1>
              <div className="separator separator-danger">✻</div>
            </div>
          </div>
        </div>
      </section>
    
    </>
  )
}

export default BlogBanner
import React from 'react'

export const AboutMember = () => {
  return (
  <>
   <section className="membership-back">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h4>Premium Membership</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. </p>
                <span>
                    <button type="button" className="btn hm-btn">Join Now</button>
                </span>
            </div>
          </div>
        </div>
      </section>
  </>
  )
}

/** @format */

import React from "react"
import { Link } from "react-router-dom"

const AdsSales = () => {
  return (
    <>
      <section className="addash">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 d-flex align-items-center">
              <span>
                <h4 className="m-0">Ads Sales</h4>
              </span>
            </div>
            <div className="col-lg-4">
              <span className="ads-Sales-Dropdown">
                Select Month
                <select
                  className="dropdown-fm form-select-ctm"
                  aria-label=" select example"
                >
                  <option selected>All</option>
                  <option value="1">Jan</option>
                  <option value="2">Feb</option>
                  <option value="3">March</option>
                </select>
              </span>
            </div>
            <div className="col-lg-4">
              <span className="position-relative">
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control form-control-ctm "
                />
                <i className="fa fa-magnifying-glass position-absolute mx-3 mt-2 top-0 end-0 fglass"></i>
              </span>
            </div>
          </div>
          <div className="row  ">
            <div className="col-lg-12 mt-3">
              <div className="dashboardtable">
                <div className="table-scrollable">
                  <table className="table table-color table-hover table-fixed-first-columns">
                    <thead>
                      <tr>
                        <th>Ads Name</th>
                        <th>Profile Name</th>
                        <th>Publish Date</th>
                        <th>Targeted City</th>
                        <th>Total Ads Sold</th>
                        <th>Total Revenue</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>ABC</td>
                        <td>XYZ</td>
                        <td>15/10/2024</td>
                        <td>San Diego</td>
                        <td>100</td>
                        <td>$100</td>
                        <td className="table-action">
                          <div className="d-flex justify-content-center align-items-center">
                            <span>
                              {" "}
                              <i className="fa-regular fa-pen-to-square"></i>
                            </span>
                            &nbsp; &nbsp;
                            <span>
                              {" "}
                              <Link to="#">
                                {" "}
                                <i className="fa-regular fa-eye"></i>
                              </Link>
                            </span>
                            &nbsp; &nbsp; &nbsp;
                            <span>
                              <Link to="#">
                                <i className="far fa-trash-alt pointer"></i>
                              </Link>
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>ABC</td>
                        <td>XYZ</td>
                        <td>15/10/2024</td>
                        <td>San Diego</td>
                        <td>100</td>
                        <td>$100</td>
                        <td className="table-action">
                          <div className="d-flex justify-content-center align-items-center">
                            <span>
                              {" "}
                              <i className="fa-regular fa-pen-to-square"></i>
                            </span>
                            &nbsp; &nbsp;
                            <span>
                              {" "}
                              <Link to="#">
                                {" "}
                                <i className="fa-regular fa-eye"></i>
                              </Link>
                            </span>
                            &nbsp; &nbsp; &nbsp;
                            <span>
                              <Link to="#">
                                <i className="far fa-trash-alt pointer"></i>
                              </Link>
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-center w-100 mx-auto pt-4 pb-3">
                  <div className="pagination-css-pre">
                    <span aria-hidden="true">
                      <i className="fa fa-chevron-left" aria-hidden="true"></i>
                    </span>
                  </div>
                  <div className="d-flex">
                    <span className="pagination-css ">1</span>
                    <span className="pagination-css ">{` - `}</span>
                    <span className="pagination-css ">3</span>
                  </div>
                  <div className="pagination-css-next">
                    <span aria-hidden="true">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AdsSales

/** @format */

import React from "react"
import "../../../src/asset/css/popup.css"
import { useSelector } from "react-redux"
import { showPricePopup } from "../../actions"
import { useDispatch } from "react-redux"

const ViewPrice = (props) => {
  let dispatch = useDispatch()
  const show = useSelector((s) => s.show_price_popup)
  return (
    <>
      <div
        className={`modal_container text-center m-auto ${
          show ? "d-block" : "d-none"
        }`}
      >
        <div className="modal-dialog">
          <div className="modal-content price-table p-4">
            <div className="position-relative">
              <h2 className="modal-title fs-5 text-body" id="deletepopupLabel">
                View Price
              </h2>
              <span
                className="position-absolute cross_close top-0 end-0"
                onClick={() => dispatch(showPricePopup(false))}
              >
                <i
                  className="fa-solid fa-x"
                  data-dismiss="modal"
                  aria-label="Close"
                ></i>
              </span>
            </div>
            <div className="modal-body mt-2">
              <div>
                
                <table className="view-price w-100 dashboardtable">
                  <thead>
                    <tr>
                      <th>Duration</th>
                      <th>Rate</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props
                      ? props.data.map((x) => {
                          return (
                            <tr>
                              <td className="py-2">
                              {(x.hour) + ' : ' + Number(x.min)} Hours
                              </td>
                              <td>{x.price} $</td>
                            </tr>
                          )
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="p-2">
              <div className="d-flex justify-content-center mt-2">
                <button
                  type="button"
                  className="btn cus-btn"
                  onClick={() => dispatch(showPricePopup(false))}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewPrice

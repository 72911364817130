/** @format */

import React, { useState } from "react"
import { Link } from "react-router-dom"
import barfiler from "../../../asset/img/bars-filter.svg"

const Sales = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false)

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen)
  }
  return (
    <>
      <section className="addash">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 d-flex align-items-center">
              <span>
                <h4 className="m-0">Sales Report</h4>
              </span>
            </div>
            <div className="col-lg-4">
              <span className="position-relative">
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control form-control-ctm"
                />
                <i className="fa fa-magnifying-glass position-absolute mx-3 mt-2 top-0 end-0 fglass"></i>
              </span>
            </div>
            <div className="col-lg-3 d-flex justify-content-between">
              <span>
                <button
                  className="btn pri-btn"
                  onClick={toggleFilter}
                  aria-controls="filterCollapse"
                  aria-expanded={isFilterOpen}
                >
                  <img
                    src={barfiler}
                    alt="bars"
                    className="img-fluid bar-filter"
                  />{" "}
                  Filter
                </button>
              </span>
              <span>
                <button
                  className="btn pri-btn dropdown-toggle position-relative"
                  type="button"
                  data-toggle="dropdown"
                  data-auto-close="outside"
                  aria-expanded="false"
                >
                  Export
                </button>
                <ul className="dropdown-menu profile_menu export_menu">
                  <li>
                    <Link className="dropdown-item">Export PDF</Link>
                  </li>
                  <li>
                    <Link className="dropdown-item">Export CSV</Link>
                  </li>
                </ul>
              </span>
            </div>
          </div>
          <div
            className={`row mt-4 filter-drop ${isFilterOpen ? "" : "collapse"}`}
            id="filterCollapse"
          >
            <div className="col-lg-2 col-md-6">
              <span>
                Select Advertiser
                <select
                  className="form-select-ctm"
                  aria-label=" select example"
                >
                  <option selected>All</option>
                  <option value="1">ABC</option>
                  <option value="2">ABC</option>
                  <option value="3">ABC</option>
                </select>
              </span>
            </div>
            <div className="col-lg-2 col-md-6">
              <span>
                Select Ads
                <select
                  className="form-select-ctm"
                  aria-label=" select example"
                >
                  <option selected>All</option>
                  <option value="1">ABC</option>
                  <option value="2">ABC</option>
                  <option value="3">ABC</option>
                </select>
              </span>
            </div>
            <div className="col-lg-3 col-md-6">
              <span>
                From
                <input type="date" className="form-control form-control-ctm" />
              </span>
            </div>
            <div className="col-lg-3 col-md-6">
              <span>
                To
                <input type="date" className="form-control form-control-ctm" />
              </span>
            </div>
            <div className="col-lg-2 d-flex justify-content-center">
              <button className="btn pri-btn">Apply</button>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-12">
              <h6>Total Ads Revenue</h6>
            </div>
          </div>
          <div className="row filter-drop">
            <div className="col-lg-6 col-md-6">
              <p className="basicinfo">Total Ads Revenue</p>
              <h6 className="basicdata">1200 $</h6>
            </div>
            <div className="col-lg-6 col-md-6">
              <p className="basicinfo">Last Month Revenue</p>
              <h6 className="basicdata">200 $</h6>
            </div>
          </div>
          <div className="row  ">
            <div className="col-lg-12 mt-3">
              <div className="dashboardtable">
                <div className="table-scrollable">
                  <table className="table table-color table-hover table-fixed-first-columns">
                    <thead>
                      <tr>
                        <th>Ads Name</th>
                        <th>Profile Name</th>
                        <th>Publish Date</th>
                        <th>Targeted City</th>
                        <th>Total Ads Sold</th>
                        <th>Total Revenue</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>ABC</td>
                        <td>XYZ</td>
                        <td>15/10/2024</td>
                        <td>San Diego</td>
                        <td>100</td>
                        <td>$100</td>
                        <td className="table-action">
                          <div className="d-flex justify-content-center align-items-center">
                            <span>
                              {" "}
                              <i className="fa-regular fa-pen-to-square"></i>
                            </span>
                            &nbsp; &nbsp;
                            <span>
                              {" "}
                              <Link to="#">
                                {" "}
                                <i className="fa-regular fa-eye"></i>
                              </Link>
                            </span>
                            &nbsp; &nbsp; &nbsp;
                            <span>
                              <Link to="#">
                                <i className="far fa-trash-alt pointer"></i>
                              </Link>
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>ABC</td>
                        <td>XYZ</td>
                        <td>15/10/2024</td>
                        <td>San Diego</td>
                        <td>100</td>
                        <td>$100</td>
                        <td className="table-action">
                          <div className="d-flex justify-content-center align-items-center">
                            <span>
                              {" "}
                              <i className="fa-regular fa-pen-to-square"></i>
                            </span>
                            &nbsp; &nbsp;
                            <span>
                              {" "}
                              <Link to="#">
                                {" "}
                                <i className="fa-regular fa-eye"></i>
                              </Link>
                            </span>
                            &nbsp; &nbsp; &nbsp;
                            <span>
                              <Link to="#">
                                <i className="far fa-trash-alt pointer"></i>
                              </Link>
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="d-flex justify-content-center w-100 mx-auto pt-4 pb-3">
                  <div className="pagination-css-pre">
                    <span aria-hidden="true">
                      <i className="fa fa-chevron-left" aria-hidden="true"></i>
                    </span>
                  </div>
                  <div className="d-flex">
                    <span className="pagination-css ">1</span>
                    <span className="pagination-css ">{` - `}</span>
                    <span className="pagination-css ">3</span>
                  </div>
                  <div className="pagination-css-next">
                    <span aria-hidden="true">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Sales

import React, { useEffect } from 'react'
import Header from '../component/header';
import PrivacyBanner from './PrivacyPolicy/PrivacyBanner';
import PrivacyText from './PrivacyPolicy/PrivacyText';
import { Membership } from './HomeComponent/Membership';
import { TopFooter } from './HomeComponent/TopFooter';
import Footer from '../component/footer';
import BacktoTop from './HomeComponent/BacktoTop';

const Privacypolicy = () => {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);


  return (
   <>
   <BacktoTop />
   <Header />
   <PrivacyBanner />
   <PrivacyText />
   <Membership />
<TopFooter />
   <Footer /> 
   </>
  )
}

export default Privacypolicy;
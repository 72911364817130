import React, { useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import barfiler from "../../asset/img/bars-filter.svg";
import location from "../../asset/img/location.png";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import {
  allList2,
  availableList,
  isLoader,
  premiumList,
  searchAge,
  searchAvailability,
  searchBodyType,
  searchBreastSize,
  searchCity,
  searchEthnicity,
  searchEyeColor,
  searchGender,
  searchHairColor,
  searchHeight,
} from "../../actions";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { click } from "@testing-library/user-event/dist/click";

const FilterPopup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [gender, setGender] = useState("");
  const [city, setCity] = useState("");
  const [age, setAge] = useState("");
  const [eyeColor, setEyeColor] = useState("");
  const [hairColor, setHairColor] = useState("");
  const [height, setHeight] = useState("");
  const [bodyType, setBodyType] = useState("");
  const [breastSize, setBreastSize] = useState("");
  const [ethnicity, setEthnicity] = useState("");
  const [availability, setAvailability] = useState("");
  const advertiserCity = localStorage.getItem("advertiserCity");
  const [loading, setLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);

  const handleGender = (e) => {
    setGender(e.target.value);
  };

  const handleCity = (e) => {
    setCity(e.target.value);
    // dispatch(searchCity(e.target.value));
    console.log("Selected City: ", e.target.value);
  };

  const handleAge = (e) => {
    setAge(e.target.value);
    // dispatch(searchAge(e.target.value));
    console.log("Selected Age: ", e.target.value);
  };

  const handleEyeColor = (e) => {
    setEyeColor(e.target.value);
    // dispatch(searchEyeColor(e.target.value));
    console.log("Selected Eye Color: ", e.target.value);
  };

  const handleHairColor = (e) => {
    setHairColor(e.target.value);
    // dispatch(searchHairColor(e.target.value));
    console.log("Selected Hair Color: ", e.target.value);
  };

  const handleHeight = (e) => {
    setHeight(e.target.value);
    // dispatch(searchHeight(e.target.value));
    console.log("Selected Height Color: ", e.target.value);
  };

  const handleBodyType = (e) => {
    setBodyType(e.target.value);
    // dispatch(searchBodyType(e.target.value));
    console.log("Selected Body Type: ", e.target.value);
  };

  const handleBreastSize = (e) => {
    setBreastSize(e.target.value);
    // dispatch(searchBreastSize(e.target.value));
    console.log("Selected Breast Size: ", e.target.value);
  };

  const handleEthnicity = (e) => {
    setEthnicity(e.target.value);
    // dispatch(searchEthnicity(e.target.value));
    console.log("Selected Ethnicity: ", e.target.value);
  };

  const handleAvailability = (e) => {
    setAvailability(e.target.value);
    // dispatch(searchAvailability(e.target.value));
    console.log("Selected Availability: ", e.target.value);
  };

  const heightArray = [];
  for (let i = 152; i <= 210; i++) {
    heightArray.push(i);
  }

  const bSizeArray = [];
  for (let i = 34; i <= 38; i += 2) {
    bSizeArray.push(i + " (A)");
    bSizeArray.push(i + " (B)");
    bSizeArray.push(i + " (C)");
  }

  const ageArray = [];
  for (let i = 18; i <= 60; i++) {
    ageArray.push(i);
  }

  $(".selectBox").on("click", function (e) {
    $(this).toggleClass("show");
    var dropdownItem = e.target;
    var container = $(this).find(".selectBox__value");
    container.text(dropdownItem.text);
    $(dropdownItem).addClass("active").siblings().removeClass("active");
  });

  const resetFilters = () => {
    setGender("");
    setCity("");
    setAge("");
    setEyeColor("");
    setHairColor("");
    setHeight("");
    setBodyType("");
    setBreastSize("");
    setEthnicity("");
    setAvailability("");
    dispatch(searchGender(""));
    dispatch(searchCity(""));
    dispatch(searchAge(""));
    dispatch(searchEyeColor(""));
    dispatch(searchHairColor(""));
    dispatch(searchHeight(""));
    dispatch(searchBodyType(""));
    dispatch(searchBreastSize(""));
    dispatch(searchEthnicity(""));
    dispatch(searchAvailability(""));
    setResetLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/get-available-advertisements?defaultCity=${advertiserCity}&searchedCity=${city}&eye_color=${eyeColor}&breast_size=${breastSize}&hair_color=${hairColor}&height=${height}&ethnicity=${ethnicity}&age=${age}&body_type=${bodyType}&gender=${gender}`
      )
      .then((res) => {
        setResetLoading(false);
        // console.log(res.data.topPrimaryAdvertisements.data)
        // setTopList(res.data.topPrimaryAdvertisements.data);
        dispatch(availableList(res.data.availableAdvertisements.data));
      })
      .catch((err) => {
        setResetLoading(false);
        console.log(err);
      });
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/get-all-advertisements-home?defaultCity=${advertiserCity}&searchedCity=${city}&eye_color=${eyeColor}&breast_size=${breastSize}&hair_color=${hairColor}&height=${height}&ethnicity=${ethnicity}&age=${age}&body_type=${bodyType}&gender=${gender}`
      )
      .then((res) => {
        // console.log(res.data.topPrimaryAdvertisements.data)
        // setTopList(res.data.topPrimaryAdvertisements.data);
        dispatch(allList2(res.data.allAdvertisements.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSearch = () => {
    if (gender === "Male") {
      dispatch(searchGender(gender));
      dispatch(searchBreastSize(""));
      setBreastSize("");
    } else {
      dispatch(searchGender(gender));
    }

    dispatch(searchCity(city));
    dispatch(searchAge(age));
    dispatch(searchEyeColor(eyeColor));
    dispatch(searchHairColor(hairColor));
    dispatch(searchHeight(height));
    dispatch(searchBodyType(bodyType));
    dispatch(searchBreastSize(breastSize));
    dispatch(searchEthnicity(ethnicity));
    dispatch(searchAvailability(availability));

    dispatch(isLoader(true));
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/get-available-advertisements?defaultCity=${advertiserCity}&searchedCity=${city}&eye_color=${eyeColor}&breast_size=${breastSize}&hair_color=${hairColor}&height=${height}&ethnicity=${ethnicity}&age=${age}&body_type=${bodyType}&gender=${gender}`
      )
      .then((res) => {
        setLoading(false);
        dispatch(isLoader(false));
        // console.log(res.data.topPrimaryAdvertisements.data)
        // setTopList(res.data.topPrimaryAdvertisements.data);
        dispatch(availableList(res.data.availableAdvertisements.data));
      })
      .catch((err) => {
        setLoading(false);
        dispatch(isLoader(false));
        console.log(err);
      });
    dispatch(isLoader(true));
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/get-all-advertisements-home?defaultCity=${advertiserCity}&searchedCity=${city}&eye_color=${eyeColor}&breast_size=${breastSize}&hair_color=${hairColor}&height=${height}&ethnicity=${ethnicity}&age=${age}&body_type=${bodyType}&gender=${gender}`
      )
      .then((res) => {
        dispatch(isLoader(false));
        // console.log(res.data.topPrimaryAdvertisements.data)
        // setTopList(res.data.topPrimaryAdvertisements.data);
        dispatch(allList2(res.data.allAdvertisements.data));
      })
      .catch((err) => {
        dispatch(isLoader(false));
        console.log(err);
      });

    // axios
    //   .get(
    //     `${process.env.REACT_APP_API_URL}/api/get-primium-advertisements?city=${search_city}&eye_color=${search_eye_color}&breast_size=${search_breast_size}&height=${search_height}&ethnicity=${search_ethnicity}&age=${search_age}&body_type=${search_body_type}&gender=${search_gender}`
    //   )
    //   .then((res) => {
    //     dispatch(isLoader(false));
    //     dispatch(premiumList(res.data.premiumAdvertisements.data));
    //   })
    //   .catch((err) => {
    //     dispatch(isLoader(false));
    //     console.log(err);
    //   });
  };

  return (
    <>
      <Popup
        trigger={
          <div className="filter-homepage">
            <img src={barfiler} alt="bars" className="img-fluid bar-filter" />{" "}
            <p>More Filter</p>
          </div>
        }
        modal
        nested
      >
        {(close) => (
          <div className="modal-w">
            <div className="content">
              {" "}
              <div className="modal-dialog">
                <div className="modal-content filterpoup">
                  <div className="position-relative">
                    <h2
                      className="modal-title fs-5 text-body"
                      id="deletepopupLabel"
                    >
                      Filter Options
                    </h2>
                    <span className="position-absolute top-0 end-0">
                      <i className="fa-solid fa-x pe-auto" onClick={close}></i>
                    </span>
                  </div>
                  <div className="modal-body ">
                    <span className="filterpopupop1 mt-2">
                      <div className="input-container">
                        <input
                          type="text"
                          value={city}
                          onChange={handleCity}
                          className="input-field custom-select filter-input-popup"
                          placeholder="Search by city"
                        />
                        <img
                          src={location}
                          alt="Location Icon"
                          className="icon location-icon"
                        />
                      </div>
                    </span>
                    <div className="filterpopupop mt-3">
                      <span>
                        <span className=" text-body filterpopupopans">
                          <select
                            className="custom-select select-filter"
                            id="gender2"
                            value={gender}
                            onChange={handleGender}
                          >
                            <option value="" selected>
                              Gender
                            </option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Non-Binary">Non-Binary</option>
                            <option value="Other">Other</option>
                          </select>
                        </span>
                      </span>
                      <span>
                        <span className=" text-body filterpopupopans">
                          <span className="text-body filterpopupopans">
                            <select
                              className="custom-select select-filter"
                              id="gender2"
                              value={age}
                              onChange={handleAge}
                            >
                              <option value="" selected>
                                Age
                              </option>
                              {ageArray.map((x) => {
                                return <option value={x}>{x}</option>;
                              })}
                            </select>
                          </span>
                        </span>
                      </span>
                    </div>
                    <div className="filterpopupop mt-3">
                      <span>
                        <span className=" text-body filterpopupopans">
                          <span className="text-body filterpopupopans">
                            <select
                              className="custom-select select-filter"
                              id="gender2"
                              value={eyeColor}
                              onChange={handleEyeColor}
                            >
                              <option value="" selected>
                                Eye Color
                              </option>
                              <option value="Black">Black</option>
                              <option value="Grey">Grey</option>
                              <option value="Golden">Golden</option>
                              <option value="Red">Green</option>
                            </select>
                          </span>
                        </span>
                      </span>
                      <span>
                        <span className="text-body filterpopupopans">
                          <select
                            className="custom-select select-filter"
                            id="gender2"
                            value={hairColor}
                            onChange={handleHairColor}
                          >
                            <option value="" selected>
                              Hair Color
                            </option>
                            <option value="Black">Black</option>
                            <option value="Grey">Grey</option>
                            <option value="Golden">Golden</option>
                            <option value="Red">Red</option>
                            <option value="Blonde">Blonde</option>
                            <option value="Multicolor">Multicolor</option>
                          </select>
                        </span>
                      </span>
                    </div>

                    <div className="filterpopupop mt-3">
                      <span>
                        <span className="text-body filterpopupopans">
                          <select
                            className="custom-select select-filter"
                            id="gender2"
                            value={height}
                            onChange={handleHeight}
                          >
                            <option value="" selected>
                              Select Height
                            </option>
                            {heightArray.map((x) => {
                              return <option value={x}>{x} cm</option>;
                            })}
                          </select>
                        </span>
                      </span>
                      <span>
                        <span className="text-body filterpopupopans">
                          <select
                            className="custom-select select-filter"
                            id="gender2"
                            value={bodyType}
                            onChange={handleBodyType}
                          >
                            <option value="" selected>
                              Body Type
                            </option>
                            {gender === "Male" ? (
                              <>
                                <option value="Rectangle">Rectangle</option>
                                <option value="Triangle">Triangle</option>
                                <option value="Oval">Oval</option>
                                <option value="Trapezoid">Trapezoid</option>
                                <option value="Mesomorph">Mesomorph</option>
                                <option value="Ectomorph">Ectomorph</option>
                                <option value="Endomorph">Endomorph</option>
                              </>
                            ) : (
                              <>
                                <option value="Slim">Slim</option>
                                <option value="Triangle">Triangle</option>
                                <option value="Hour glass">Hour glass</option>
                                <option value="Rectangle">Rectangle</option>
                                <option value="Apple">Apple</option>
                                <option value="Pear">Pear</option>
                              </>
                            )}
                          </select>
                        </span>
                      </span>
                    </div>

                    <div className="filterpopupop mt-3">
                      <span>
                        <span className="text-body filterpopupopans">
                          {gender === "Male" ? null : (
                            <>
                              <select
                                className="custom-select select-filter"
                                id="gender2"
                                value={breastSize}
                                onChange={handleBreastSize}
                              >
                                <>
                                  <option value="" selected>
                                    {" "}
                                    Breast Size
                                  </option>
                                  {bSizeArray.map((x) => {
                                    return <option value={x}>{x}</option>;
                                  })}
                                </>
                              </select>
                            </>
                          )}
                        </span>
                      </span>
                      <span>
                        <span className="text-body filterpopupopans">
                          <select
                            className="custom-select select-filter"
                            id="gender2"
                            value={ethnicity}
                            onChange={handleEthnicity}
                          >
                            <option value="" selected>
                              Ethnicity
                            </option>
                            <option value="White American">
                              White American
                            </option>
                            <option value="European American">
                              European American
                            </option>
                            <option value="Middle Eastern American">
                              Middle Eastern American
                            </option>
                            <option value="Asian">Asian</option>
                            <option value="African">African</option>
                            <option value="Australian">Australian</option>
                          </select>
                        </span>
                      </span>
                    </div>

                    <div className="mt-3">
                      <h6 className="text-body text-center">Availability</h6>
                      <span className="text-body filterpopupopans">
                        <input
                          type="date"
                          className="form-control availty-input"
                          value={availability}
                          onChange={handleAvailability}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="p-2 mt-2">
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn sub-btn filter-btn-m w-25"
                        onClick={() =>{
                          resetFilters()
                          close()
                        }}
                        disabled={resetLoading}
                      >
                        Reset &nbsp;
                        {resetLoading ? (
                          <span
                            className="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : null}
                      </button>
                      <button
                        type="button"
                        className="btn sub-btn filter-btn-m ms-3 w-75 "
                        onClick={() =>{
                          onSearch()
                          close()
                        }}
                        disabled={loading}
                      >
                        <i
                          className="fa-solid fa-magnifying-glass"
                          disabled={loading}
                        ></i>{" "}
                        Search &nbsp;
                        {loading ? (
                          <span
                            className="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : null}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="actions"></div>
          </div>
        )}
      </Popup>
    </>
  );
};

export default FilterPopup;

const handleLastActive = (x) => {
  const year = new Date().getFullYear();
  const month = new Date().getMonth();
  const date = new Date().getDate();
  const hour = new Date().getHours();
  const min = new Date().getMinutes();
  const sec = new Date().getSeconds();
  if (
    x.substring(0, 4) === `${year}` &&
    x.substring(5, 7) === `${("0" + (month + 1)).slice(-2)}` &&
    x.substring(8, 10) === `${("0" + date).slice(-2)}` &&
    x.substring(11, 13) === `${("0" + hour).slice(-2)}`
  ) {
    return (
      <>
        <img
          style={{ height: "10px", width: "10px" }}
          src="https://img.icons8.com/external-flat-icons-inmotus-design/67/40C057/external-active-attachment-flat-icons-inmotus-design.png"
          alt="external-active-attachment-flat-icons-inmotus-design"
        />
        <span>Active</span>
      </>
    );
  } else if (
    x.substring(0, 4) === `${year}` &&
    x.substring(5, 7) === `${("0" + (month + 1)).slice(-2)}` &&
    x.substring(8, 10) === `${("0" + date).slice(-2)}`
  ) {
    return (
      <>
        <i className="fa-regular fa-clock"></i>
        <span>Last active, today</span>
      </>
    );
  } else if (
    x.substring(0, 4) === `${year}` &&
    x.substring(5, 7) === `${("0" + (month + 1)).slice(-2)}` &&
    x.substring(8, 10) != `${("0" + (date - 1)).slice(-2)}`
  ) {
    return (
      <>
        <i className="fa-regular fa-clock"></i>
        <span>Last active, yesterday</span>
      </>
    );
  } else {
    return (
      <>
        <i className="fa-regular fa-clock"></i>&nbsp;
        <span>
          Last active, {x.substring(8, 10)}-{x.substring(5, 7)}-
          {x.substring(0, 4)}
        </span>
      </>
    );
  }
};

export default handleLastActive;

/** @format */

import React, { useEffect, useState } from "react"
import $ from "jquery"
import userLogo from "../../../src/asset/img/userpro.png"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import axios from "axios"
import { isLoader } from "../../actions"
import { isToaster } from "../../actions"
import { userData } from "../../actions"
import { useNavigate } from "react-router-dom"

const AdminAccount = () => {
  const token = localStorage.getItem("userToken")
  const data = useSelector((state) => state.user_data)
  const [profileImageFile, setprofileImageFile] = useState()
  const [euser, setEuser] = useState(data)
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState({
    firstname: data.firstname ? data.firstname : "",
    lastname: data.lastname ? data.lastname : "",
    age: data.age ? data.age : "",
    email: data.email ? data.email : "",
    gender: data.gender ? data.gender : "",
    phone: data.phone ? data.phone : "",
    profile_photo: data.profile_photo,
    username: data.username ? data.username : "",
  })

  let dispatch = useDispatch()
  let navigate = useNavigate()

  const onSave = async () => {
    setLoading(true)
    // dispatch(isLoader(true))
    let postData = {
      firstname: euser.firstname,
      lastname: euser.lastname,
      gender: euser.gender,
      phone: euser.phone,
      age: euser.age,
    }
    const customConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        // "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/update-account-admin`,
        postData,
        customConfig
      )
      .then((res) => {
        setLoading(false)
        $(".editable-form").hide()
        $(".readonly-form").show()
        $("#editButton").show()
        $("#cancelButton").hide()
        // dispatch(isLoader(false))
        dispatch(
          isToaster({ flag: true, msg: "ACCOUNT UPDATED SUCCESSFULLY!" })
        )
        axios
          .get(`${process.env.REACT_APP_API_URL}/api/get-user`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            dispatch(userData(res.data.user))
          })
          .catch((err) => {
            // navigate("/login")
          })
      })
      .catch((err) => {
        dispatch(isLoader(false))
        console.log(err)
      })
  }

  const onEdit = () => {
    setEuser(data)
  }

  const profileUpload = async (e) => {
    setprofileImageFile(e.target.files[0])
    const [file] = document.getElementById("newProfilePhoto").files
    if (file) {
      const customConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
          // "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
      let postImage = {
        profile_photo: e.target.files[0],
      }
      dispatch(isLoader(true))
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/update-profile-photo-admin`,
          postImage,
          customConfig
        )
        .then((res) => {
          axios
            .get(`${process.env.REACT_APP_API_URL}/api/get-user`, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
              dispatch(userData(res.data.user))
            })
            .catch((err) => {
              dispatch(isLoader(false))
              // navigate("/login")
            })
        })
        .catch((err) => {
          dispatch(isLoader(false))
          console.log(err)
        })
      let image_url = URL.createObjectURL(file)
      dispatch(isToaster({flag: true, msg: 'Profile picture updated Successfully!'}))
      setUser({ ...user, profile_photo: image_url })
      dispatch(isLoader(false))
    }
  }
  let name, Value
  const handleInputs = (e) => {
    name = e.target.name
    Value = e.target.value
    setEuser({ ...euser, [name]: Value })
  }

  $(document).ready(function () {
    $("#editButton").click(function () {
      $(".editable-form").show()
      $(".readonly-form").hide()
      $("#editButton").hide()
      $("#cancelButton").show()
    })

    $("#cancelButton").click(function () {
      $(".editable-form").hide()
      $(".readonly-form").show()
      $("#editButton").show()
      $("#cancelButton").hide()
    })
  })

  return (
    <>
      <section className="addash">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex align-items-center">
                <h4>My Account</h4>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-4">
              <div className="viewcard-img">
                <div className="">
                  <div className="d-flex justify-content-center">
                    <div className="pic-holder-account">
                      <img
                        src={data.profile_photo ? data.profile_photo : userLogo}
                        alt="UploadPhoto"
                        id="blah1"
                        className="pic"
                      />
                      <label
                        htmlFor="newProfilePhoto"
                        className="upload-file-block"
                      >
                        <input
                          id="newProfilePhoto"
                          className="form-control"
                          type="file"
                          onChange={profileUpload}
                          accept="image/*"
                        />
                        <span className="text-center">
                          <i className="fa fa-camera fa-2x"></i>
                          <br />
                          Update <br /> Profile Photo
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="account-name">
                    <h4>{data.firstname + " " + data.lastname}</h4>
                    <p>{data.username}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="viewcard ">
                <div className="row  basic-detail">
                  <div className="d-flex justify-content-between align-items-center">
                    <h4 className="infoedit m-0"> Profile Information </h4>
                    <span className="infoedit1">
                      <button
                        id="editButton"
                        className="custom-btn"
                        onClick={onEdit}
                      >
                        Edit
                      </button>
                    </span>
                  </div>
                  <span className="basic-detail-border"></span>
                </div>
                <div className="row mt-3">
                  <div className="readonly-form">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p className="basicinfo">First Name</p>
                        <h6 className="basicdata">{data.firstname}</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p className="basicinfo">Last Name</p>
                        <h6 className="basicdata">{data.lastname}</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p className="basicinfo">Gender</p>
                        <h6 className="basicdata">{data.gender}</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p className="basicinfo">Phone Number</p>
                        <h6 className="basicdata">{data.phone}</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p className="basicinfo">Age</p>
                        <h6 className="basicdata">{data.age}</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p className="basicinfo">Email</p>
                        <h6 className="basicdata">{data.email}</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p className="basicinfo">Username</p>
                        <h6 className="basicdata">{data.username}</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p className="basicinfo">User Role</p>
                        <h6 className="basicdata">{data.role}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="editable-form" style={{ display: "none" }}>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault()
                      }}
                    >
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="input-label">
                            <label className="mb-1">
                              {" "}
                              <span>First Name</span>{" "}
                            </label>
                            <input
                              type="text"
                              name="firstname"
                              value={euser.firstname}
                              onChange={handleInputs}
                              // placeholder="First Name"
                              className="form-control form-control-ctm"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="input-label">
                            <label className="mb-1">
                              {" "}
                              <span>Last Name</span>{" "}
                            </label>
                            <input
                              type="text"
                              name="lastname"
                              value={euser.lastname}
                              onChange={handleInputs}
                              // placeholder="Last Name"
                              className="form-control form-control-ctm"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="input-label">
                            <label className="mb-1">
                              {" "}
                              <span>Gender</span>{" "}
                            </label>
                            <input
                              type="text"
                              name="gender"
                              value={euser.gender}
                              onChange={handleInputs}
                              // placeholder="Gender"
                              className="form-control form-control-ctm"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="input-label">
                            <label className="mb-1">
                              {" "}
                              <span>Phone</span>{" "}
                            </label>
                            <input
                              type="number"
                              name="phone"
                              value={euser.phone}
                              onChange={handleInputs}
                              // placeholder="Phone"
                              className="form-control form-control-ctm"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="input-label">
                            <label className="mb-1">
                              {" "}
                              <span>Age</span>{" "}
                            </label>
                            <input
                              type="number"
                              name="age"
                              value={euser.age}
                              onChange={handleInputs}
                              // placeholder="Age"
                              className="form-control form-control-ctm"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-12">
                          <button
                            type="submit"
                            disabled={loading}
                            id="saveButton"
                            className="btn cus-btn"
                            onClick={onSave}
                          >
                            Save&nbsp;
                            {loading ? (
                              <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : null}
                          </button>
                          &nbsp;
                          <button
                            type="submit"
                            id="cancelButton"
                            className="btn sec-btn"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-lg-6"></div>
                <div className="col-lg-6 text-right">
                  <Link to="/admin/updatePassword">
                    {" "}
                    <button type="button" className="btn pri-btn">
                      Change Password
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AdminAccount

/** @format */
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"

const ViewProfileInfoCard = () => {
  const data = useSelector((s) => s.advertiser_data)

  return (
    <>
      <div>
        <div className="profile-info-nav">
          <div className="row position-relative profile-info-tab">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="m-0">
                Profile <span className="profile-info-tab-txt">Info</span>
              </h4>
            </div>
            <span className="profile-info-border"></span>
          </div>
          <div className="row mt-2">
            <div className="col-lg-12">
              <div className="profile-tab-card">
                <span className="profile-tab-card-head">Name</span>
                <span className="profile-tab-card-txt">{data.firstname + ' ' + data.lastname}</span>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-lg-12">
              <div className="profile-tab-card">
                <span className="profile-tab-card-head">Gender</span>
                <span className="profile-tab-card-txt">{data.gender}</span>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-lg-12">
              <div className="profile-tab-card">
                <span className="profile-tab-card-head">Age</span>
                <span className="profile-tab-card-txt">{data.age}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewProfileInfoCard

/** @format */

import React, { useEffect, useState } from "react"
import axios from "axios"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { isLoader, isToaster } from "../../../actions"

const EditUser = () => {
  const token = localStorage.getItem("userToken")
  const ID = localStorage.getItem("editUserId")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  let dispatch = useDispatch()
  let navigate = useNavigate()
  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    phone: "",
    gender: "",
    password: "",
    password_confirmation: "",
    age: null,
    role: "",
  })
  let name, Value
  const handleInputs = (e) => {
    setError("")
    name = e.target.name
    Value = e.target.value
    setData({ ...data, [name]: Value })
  }

  const onUpdate = () => {
    const customConfig = {
      headers: {
        // "Content-Type": "multipart/form-data",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    setLoading(true)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/update-manager-support-by-admin/${ID}`,
        data,
        customConfig
      )
      .then((res) => {
        if (res.data.status) {
          setLoading(false)
          navigate("/admin/user")
          // dispatch(isLoader(false))
          dispatch(isToaster({ flag: true, msg: "USER UPDATED SUCCESSFULLY!" }))
        } else {
          console.log("error hai: ", res)
        }
      })
      .catch((err) => {
        setLoading(false)
        dispatch(isToaster({ flag: true, msg: err.response.data.message }))
      })
  }

  useEffect(() => {
    const customConfig = {
      headers: {
        // "Content-Type": "multipart/form-data",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    dispatch(isLoader(true))
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/get-user/${ID}`,
        customConfig
      )
      .then((res) => {
        if (res.data.status) {
          dispatch(isLoader(false))
          setData(res.data.user)
          // navigate("/admin/user")
        } else {
          console.log("error hai: ", res)
        }
      })
      .catch((err) => {
        dispatch(isLoader(false))
        dispatch(isToaster({ flag: true, msg: err.response.data.message }))
      })
  }, [])
  return (
    <>
      <section className="addash">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between align-items-center">
                <span className="d-flex justify-content-between align-items-center">
                  <h4 className="m-0">Edit Users</h4>
                </span>
                <span></span>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="addprofile">
                <div
                  className={`${error ? "d-block" : "d-none"} text-center mb-2`}
                >
                  {error}
                </div>
                <form autoComplete="off" noValidate="noValidate">
                  <div className="row input-label">
                    <div className="col-lg-6 col-md-6">
                      <label className="mb-1">
                        <span>First Name</span>
                      </label>
                      <input
                        name="firstname"
                        value={data.firstname}
                        onChange={handleInputs}
                        type="text"
                        // placeholder="First Name"
                        className="form-control form-control-ctm"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <label className="mb-1">
                        <span>Last Name</span>
                      </label>
                      <input
                        name="lastname"
                        value={data.lastname}
                        onChange={handleInputs}
                        type="text"
                        // placeholder="Last Name"
                        className="form-control form-control-ctm "
                      />
                    </div>
                  </div>
                  <div className="row input-label">
                    <div className="col-lg-6 col-md-6">
                      <label className="mb-1">
                        <span>Email</span>
                      </label>
                      <input
                        name="email"
                        value={data.email}
                        onChange={handleInputs}
                        type="text"
                        // placeholder="Enter Email"
                        className="form-control form-control-ctm "
                      />
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <label className="mb-1">
                        <span>User Name</span>
                      </label>
                      <input
                        name="username"
                        value={data.username}
                        onChange={handleInputs}
                        type="text"
                        // placeholder="User Name"
                        className="form-control form-control-ctm"
                      />
                    </div>
                  </div>
                  <div className="row input-label">
                    <div className="col-lg-6 col-md-6">
                      <label className="mb-1">
                        <span>Gender</span>
                      </label>
                      <select
                        className="form-select-ctm "
                        aria-label="select"
                        name="gender"
                        value={data.gender}
                        onChange={handleInputs}
                      >
                        <option selected>-Gender-</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <label className="mb-1">
                        <span>Age</span>
                      </label>
                      <input
                        name="age"
                        value={data.age}
                        onChange={handleInputs}
                        type="number"
                        // placeholder="Enter Age"
                        className="form-control form-control-ctm "
                      />
                    </div>
                  </div>
                  <div className="row input-label">
                    <div className="col-lg-6 col-md-6">
                      <label className="mb-1">
                        <span>Password</span>
                      </label>
                      <input
                        name="password"
                        value={data.password}
                        onChange={handleInputs}
                        type="text"
                        // placeholder="Enter Password"
                        className="form-control form-control-ctm "
                      />
                    </div>
                  </div>
                  <div className="row input-label">
                    <div className="col-lg-6 col-md-6">
                      <label className="mb-1">
                        <span>Role</span>
                      </label>
                      <select
                        className="form-select-ctm "
                        aria-label="select"
                        name="role"
                        value={data.role}
                        onChange={handleInputs}
                      >
                        <option selected>-Select Role-</option>
                        {/* <option value="Admin">Admin</option> */}
                        <option value="Manager">Manager</option>
                        <option value="Support">Support</option>
                      </select>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <label className="mb-1">
                        <span>Phone Number</span>
                      </label>
                      <input
                        name="phone"
                        value={data.phone}
                        onChange={handleInputs}
                        type="number"
                        // placeholder="Enter Phone Number"
                        className="form-control form-control-ctm "
                      />
                    </div>
                    <div className="col-lg-6 col-md-6"></div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-lg-12 text-center">
                      <button
                        type="button"
                        className="btn pri-btn"
                        onClick={onUpdate}
                      >
                        Update&nbsp;
                        {loading ? (
                          <span
                            className="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : null}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default EditUser

const initialState = []

const all_list1 = (state = initialState, action) => {
     switch(action.type) {
        case "ALL_LIST1": return state.concat(action.payload);
        case "RESET_STATE": return state = initialState;
        default: return state;
     }
}

export default all_list1;
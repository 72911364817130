import React from 'react'

const RejectOrder = () => {
  return (
    <>
     <span>
        <p
          type="button"
          className="m-0"
          data-toggle="modal"
          data-target="#refundpopup"
        >
         <button type='button' className='btn custom-btn'>Reject / Refund</button>
        </p>

<div className="modal fade" id="refundpopup" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="refundpopup" aria-hidden="true">
<div className="modal-dialog">
    <div className="modal-content price-table p-4">
    <div className="position-relative">
        <h2 className="modal-title fs-5 text-body" id="refundpopupLabel">Reject / Refund Order</h2>
        <span className='position-absolute top-0 end-0'>
        <i className="fa-solid fa-x"  data-dismiss="modal" aria-label="Close"></i>
       </span>
      </div>
      <div className="modal-body">
  <h2 className='fs-6 text-body'>Are you sure to Reject / Refund this Ads ?</h2>  
</div>
      
      <div className="p-2">
        <div className='d-flex justify-content-center'>      
        <button type="button" className="btn cus-btn ">Refund / Reject</button>
        <button type="button" className="btn sec-btn ms-3" data-dismiss="modal">Cancel</button> 
        </div>
      </div>
    </div>
  </div>
</div>
 </span>
    </>
  )
}

export default RejectOrder
/** @format */

import React from "react"
import { useSelector } from "react-redux"

const ViewPriceCard = () => {
  const data = useSelector((s) => s.ad_data).duration_price

  return (
    <>
      <div className="mt-3">
        <div className="profile-info-nav">
          <div className="row position-relative profile-info-tab">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="m-0">
                Price <span className="profile-info-tab-txt"></span>
              </h4>
            </div>
            <span className="profile-info-border"></span>
          </div>
          <div className="row mt-2">
            <div className="col-lg-12">
              {data
                ? data.map((x) => {
                    return (
                      <div className="profile-tab-card">
                        <span className="profile-tab-card-head">
                          {x.hour == null? "00" : x.hour} {" : "} 
                          {x.min == null? "00" : x.min} Hours
                        </span>
                        <span className="profile-tab-card-txt">
                          {x.price} $
                        </span>
                      </div>
                    )
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewPriceCard

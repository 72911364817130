import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { chatMessages } from "../../actions";

const MyMessage = () => {
  const data = useSelector((state) => state.user_data);
  const chatStore = useSelector((s) => s.chat_messages);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const socket = useMemo(() => io(process.env.REACT_APP_CHAT_URL), []);
  const [inputMessage, setInputMessage] = useState("");
  const [room, setRoom] = useState("");
  const [isJoined, setIsJoined] = useState(false);
  const [socketId, setSocketId] = useState("");
  const [accepted, setAccepted] = useState(false);
  const [messages, setMessages] = useState([]);
  const [msg, setMsg] = useState("");
  const [profile, setProfile] = useState("");

  const handleMsg = (e) => {
    setInputMessage(e.target.value);
  };

  dispatch(chatMessages(messages));

  console.log(messages);

  const onJoinRoom = (room, name, msg, profile, time) => {
    if (accepted) {
    } else {
      setIsJoined(true);
      socket.emit("join room", room, name, msg, profile, time);
    }
  };

  const onEndChat = () => {
    socket.emit(
      "chat reply",
      "Chat ended",
      room,
      data.firstname,
      data.profile_photo
    );
    socket.emit("leave room", room);
    window.location.reload();
  };

  const onSend = () => {
    if (inputMessage && isJoined) {
      const hr = new Date().getHours();
      const min = new Date().getMinutes();
      socket.emit(
        "chat reply",
        inputMessage,
        room,
        data.firstname,
        data.profile_photo,
        `${hr}:${min}`
      );
      setInputMessage("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSend();
    }
  };

  useEffect(() => {
    const item = document.getElementById("chat_box");
    item.scrollTop = item.scrollHeight;
  }, [messages]);

  useEffect(() => {
    socket.on("connect", () => {
      // Perform necessary actions to initialize chat here
      console.log("Connected to socket server");
    });

    socket.on("received-message", (msg, role, name, profile, time) => {
      setMessages((messages) => [
        ...messages,
        { msg: msg, role: role, name: name, profile: profile, time: time },
      ]);
      // const messages = document.getElementById("messages");
      // const item = document.createElement("li");
      // item.textContent = msg;
      // messages.appendChild(item);
      const item = document.getElementById("chat_box");
      item.scrollTop = item.scrollHeight;
    });
    socket.on("redirect to dashboard", (room) => {
      navigate("/admin");
    });
    socket.on(
      "join room request to admin",
      (userRoom, name, msg, profile, time) => {
        setProfile(profile);
        setMsg(msg);
        setRoom(userRoom);
        const messages = document.getElementById("request_to_join");
        const item = document.createElement("button");
        const duplicate = document.getElementById(`request_to_join${userRoom}`);
        if (duplicate) {
          duplicate.textContent = `Join ${name}: ${msg}`;
          duplicate.setAttribute("id", `request_to_join${userRoom}`);
          duplicate.classList.add("sub-btn");
          duplicate.addEventListener("click", () =>
            onJoinRoom(userRoom, name, msg, profile, time)
          );
          window.scrollTo(0, document.body.scrollHeight);
        } else {
          item.textContent = `Join ${name}: ${msg}`;
          item.setAttribute("id", `request_to_join${userRoom}`);
          item.classList.add("sub-btn");
          item.addEventListener("click", () =>
            onJoinRoom(userRoom, name, msg, profile, time)
          );
          messages.appendChild(item);
          window.scrollTo(0, document.body.scrollHeight);
        }
      }
    );

    socket.on(
      "room joined by support",
      (status, msg, profile, role, name, time) => {
        status === true ? setAccepted(true) : setAccepted(false);
        setMessages((messages) => [
          ...messages,
          { msg: msg, role: role, name: name, profile: profile, time: time },
        ]);
      }
    );

    socket.on("this room is closed", (room, name) => {
      const item = document.getElementById(`request_to_join${room}`);
      if (item) {
        item.style.display = "none";
      }
    });
    return () => {
      // Clean up socket listeners when component unmounts
      socket.off("connect");
      socket.off("received-message");
      socket.off("redirect to dashboard");
      socket.off("join room request to admin");
      socket.off("room joined by support");
      socket.off("this room is closed");
    };
  }, []);

  return (
    <section className="addash">
      <div className="container-fluid">
        <div className="chat-bot-container">
          <ul
            id="request_to_join"
            className={`${accepted ? "d-none" : "d-block"}`}
          ></ul>
          <div id="chat_box" className="chat-bot-box">
            {chatStore
              ? chatStore.map((x) => {
                  if (x.role === "Support") {
                    return (
                      <div className="chat-list">
                        <span className="chat-list-message">
                          {" "}
                          <h5 className="chat-message"> {x.msg} </h5>
                          <p className="chat-time">{x.time}</p>
                          <span className="chatbot__arrow chatbot__arrow--right"></span>
                        </span>

                        <img className="user-img-chat" src={x.profile} />
                      </div>
                    );
                  } else if (x.role === "Advertiser") {
                    return (
                      <div className="chat-list-admin ">
                        <img className="admin-img-chat" src={x.profile} />
                        <span className="chat-list-message admin-chat-msg">
                          {" "}
                          <h5 className="chat-message"> {x.msg} </h5>
                          <p className="chat-time">{x.time}</p>
                          <span className="chatbot__arrow chatbot__arrow--left"></span>
                        </span>
                      </div>
                    );
                  }
                })
              : null}
          </div>
          <span className="position-relative">
            <input
              type="text"
              name="inputMessage"
              value={inputMessage}
              onChange={handleMsg}
              onKeyDown={handleKeyDown}
              placeholder="Type a Message"
              className="form-control form-control-ctm snd-msg-inpt"
            />
            <i
              className="fa fa-paper-plane position-absolute mt-2 end-0 send-msg-btn"
              onClick={onSend}
            ></i>
          </span>
          <span className="text-center mt-3">
            <span
              onClick={onEndChat}
              className={`end-chat-btn w-40 ${isJoined ? "d-block" : "d-none"}`}
            >
              End Chat
            </span>
          </span>
        </div>
      </div>
    </section>
  );
};

export default MyMessage;

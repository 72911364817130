import React from "react";
import Header from "../../component/header";
import Footer from "../../component/footer";
import freezone from "../../asset/img/free-zone.png";
import { Link } from "react-router-dom";
const FreeZone = () => {
  return (
    <>
      <Header />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p className="text-center m-0">
                <img
                  src={freezone}
                  alt=""
                  width={150}
                  height={100}
                  className="img-fluid"
                />
              </p>
              <div class="free-zone-message text-center">
                <h2>Welcome to the Free Zone!</h2>
                <p>
                  Enjoy access to exclusive content and features without any
                  subscription or fees for one month.
                </p>
              </div>
              <div className="text-center">
              <Link to="/login"> <button className="btn cus-btn mx-2">
                    Click Here to Proceed 
                </button>
            </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default FreeZone;

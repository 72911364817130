/** @format */

export const toggleMenu = (flag) => {
  return {
    type: "TOGGLE_SIDEBAR",
    payload: flag
  }
}

export const advertiserSideTab = (tab) => {

  return {
    type: "ADVERTISER_SIDE_TAB",
    payload: tab
  }
}

export const userData = (data) => {
  return{
    type: "USER_DATA",
    payload: data
  }
}

export const adData = (data) => {
  return{
    type: "AD_DATA",
    payload: data
  }
}

export const showPricePopup = (flag) => {
  return{
    type: "SHOW_PRICE_POPUP",
    payload: flag
  }
}

export const showRenewPopup = (flag) => {
  return{
    type: "SHOW_RENEW_POPUP",
    payload: flag
  }
}

export const editAdData = (data) => {
  return{
    type: "EDIT_AD_DATA",
    payload: data
  }
}

export const availabilityData = (data) => {
  return {
    type: "AVAILABILITY_DATA",
    payload: data
  }
}

export const advertiserData = (data) => {
  return {
    type: "ADVERTISER_DATA",
    payload: data
  }
}

export const availableDays = (data) => {
  return {
    type: "AVAILABLE_DAYS",
    payload: data
  }
}

export const showAvailability = (flag) => {
  return {
    type: "SHOW_AVAILABILITY",
    payload: flag
  }
}

export const showEditableCalendar = (flag) => {
  return {
    type: "SHOW_EDITABLE_CALENDAR",
    payload: flag
  }
}

export const EditableCalendarData = (data) => {
  return {
    type: "EDITABLE_CALENDAR_DATA",
    payload: data
  }
}

export const idToDelete = (data) => {
  return {
    type: "ID_TO_DELETE",
    payload: data
  }
}

export const videoIdToDelete = (data) => {
  return {
    type: "VIDEO_ID_TO_DELETE",
    payload: data
  }
}

export const isLoader = (flag) => {
  return {
    type: "IS_LOADER",
    payload: flag
  }
}

export const isAgeConfirmed = (flag) => {
  return {
    type: "IS_AGE_CONFIRMED",
    payload: flag
  }
}

export const isToaster = (data) => {
  return {
    type: "IS_TOASTER",
    payload: {flag: data.flag, msg: data.msg}
  }
}

export const isJoinRequest = (data) => {
  return {
    type: "IS_JOIN_REQUEST",
    payload: {flag: data.flag, msg: data.msg}
  }
}

export const viewAdData = (data) => {
  return {
    type: "VIEW_AD_DATA",
    payload: data
  }
}

export const chatMessages = (data) => {
  return{
    type: "CHAT_MESSAGES",
    payload: data
  }
}

export const topListAction = (data) => {
  return{
    type: "TOP_LIST",
    payload: data
  }
}

export const searchGender = (data) => {
  return{
    type: "SEARCH_GENDER",
    payload: data
  }
}

export const searchCity = (data) => {
  return{
    type: "SEARCH_CITY",
    payload: data
  }
}

export const allAdvertiser = (data) => {
  return{
    type: "ALL_ADVERTISER",
    payload: data
  }
}

export const premiumList = (data) => {
  return{
    type: "PREMIUM_LIST",
    payload: data
  }
}

export const availableList = (data) => {
  return{
    type: "AVAILABLE_LIST",
    payload: data
  }
}

export const allList1 = (data) => {
  return{
    type: "ALL_LIST1",
    payload: data
  }
}

export const allList2 = (data) => {
  return{
    type: "ALL_LIST2",
    payload: data
  }
}

export const availableListMore = (data) => {
  return{
    type: "AVAILABLE_LIST_MORE",
    payload: data
  }
}

export const advertiserCity = (data) => {
  return{
    type: "ADVERTISER_CITY",
    payload: data
  }
}

export const searchAge = (data) => {
  return{
    type: "SEARCH_AGE",
    payload: data
  }
}

export const searchEyeColor = (data) => {
  return{
    type: "SEARCH_EYE_COLOR",
    payload: data
  }
}

export const searchHairColor = (data) => {
  return{
    type: "SEARCH_HAIR_COLOR",
    payload: data
  }
}

export const searchHeight = (data) => {
  return{
    type: "SEARCH_HEIGHT",
    payload: data
  }
}

export const searchBodyType = (data) => {
  return{
    type: "SEARCH_BODY_TYPE",
    payload: data
  }
}

export const searchBreastSize = (data) => {
  return{
    type: "SEARCH_BREAST_SIZE",
    payload: data
  }
}

export const searchEthnicity = (data) => {
  return{
    type: "SEARCH_ETHNICITY",
    payload: data
  }
}

export const searchAvailability = (data) => {
  return{
    type: "SEARCH_AVAILABILITY",
    payload: data
  }
}

export const couponsList = (data) => {
  return{
    type: "COUPONS_LIST",
    payload: data
  }
}

export const promotedZone = (data) => {
  return{
    type: "PROMOTED_ZONE",
    payload: data
  }
}

export const resetState = (data) => {
  return{
    type: "RESET_STATE",
    payload: data
  }
}

export const advertiserId = (data) => {
  return{
    type: "ADVERTISER_ID",
    payload: data
  }
}

export const advertisementId = (data) => {
  return{
    type: "ADVERTISEMENT_ID",
    payload: data
  }
}
import React from "react";

const FaqsAccordian = () => {
  return (
    <>
      <section className="spacing-page">
        <div className="container">
          <div className="row mt-4">
            <div className="col-lg-9 col-sm-12 mx-auto">
              <div className="accordion " id="faqs">
                <div className="accordion-item ">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button  collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      <p className="m-0">
                        Lorem ipsum is placeholder text
                      </p>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse "
                    aria-labelledby="headingOne"
                    data-parent="#faqs"
                  >
                    <div className="accordion-body faqsbody">
                      <p className="m-0">
                        Lorem ipsum is placeholder text commonly used in the
                        graphic, print, and publishing industries for previewing
                        layouts and visual mockups.
                      </p>
                    </div>
                  </div>
                </div>
               
                <div className="accordion-item mt-3">
                  <h2 className="accordion-header" id="headingtwo">
                    <button
                      className="accordion-button  collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapsetwo"
                      aria-expanded="false"
                      aria-controls="collapsetwo"
                    >
                      <p className="m-0">
                        Lorem ipsum is placeholder text
                      </p>
                    </button>
                  </h2>
                  <div
                    id="collapsetwo"
                    className="accordion-collapse collapse "
                    aria-labelledby="headingtwo"
                    data-parent="#faqs"
                  >
                    <div className="accordion-body faqsbody">
                      <p className="m-0">
                        Lorem ipsum is placeholder text commonly used in the
                        graphic, print, and publishing industries for previewing
                        layouts and visual mockups.
                      </p>
                    </div>
                  </div>
                </div>
                 <div className="accordion-item mt-3">
                  <h2 className="accordion-header" id="heading3">
                    <button
                      className="accordion-button  collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapse3"
                      aria-expanded="false"
                      aria-controls="collapse3"
                    >
                      <p className="m-0">
                        Lorem ipsum is placeholder text
                      </p>
                    </button>
                  </h2>
                  <div
                    id="collapse3"
                    className="accordion-collapse collapse "
                    aria-labelledby="heading3"
                    data-parent="#faqs"
                  >
                    <div className="accordion-body faqsbody">
                      <p className="m-0">
                        Lorem ipsum is placeholder text commonly used in the
                        graphic, print, and publishing industries for previewing
                        layouts and visual mockups.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item mt-3">
                  <h2 className="accordion-header" id="heading4">
                    <button
                      className="accordion-button  collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapse4"
                      aria-expanded="false"
                      aria-controls="collapse4"
                    >
                      <p className="m-0">
                        Lorem ipsum is placeholder text
                      </p>
                    </button>
                  </h2>
                  <div
                    id="collapse4"
                    className="accordion-collapse collapse "
                    aria-labelledby="heading4"
                    data-parent="#faqs"
                  >
                    <div className="accordion-body faqsbody">
                      <p className="m-0">
                        Lorem ipsum is placeholder text commonly used in the
                        graphic, print, and publishing industries for previewing
                        layouts and visual mockups.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item mt-3">
                  <h2 className="accordion-header" id="heading5">
                    <button
                      className="accordion-button  collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapse5"
                      aria-expanded="false"
                      aria-controls="collapse5"
                    >
                      <p className="m-0">
                        Lorem ipsum is placeholder text
                      </p>
                    </button>
                  </h2>
                  <div
                    id="collapse5"
                    className="accordion-collapse collapse "
                    aria-labelledby="heading5"
                    data-parent="#faqs"
                  >
                    <div className="accordion-body faqsbody">
                      <p className="m-0">
                        Lorem ipsum is placeholder text commonly used in the
                        graphic, print, and publishing industries for previewing
                        layouts and visual mockups.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FaqsAccordian;

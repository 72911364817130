import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import { useDispatch } from "react-redux";
import { isToaster } from "../../../actions";

const CreateCoupon = () => {
  let token = localStorage.getItem("userToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const [discountType, setDiscountType] = useState("");
  const [planType, setPlanType] = useState("");
  const [amountOff, setAmountOff] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponName, setCouponName] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [loading, setLoading] = useState(false);

  const getPlans = () => {
    const customConfig1 = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/get-plans`, customConfig1)
      .then((Res) => {
        setPlans(Res.data.plans.filter((x) => x.type != "Free"));
        // dispatch(adData(Res.data.allAds.data));
        // console.log("plans : ", Res);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const getDefaultExpiry = () => {
    const year = new Date().getFullYear();
    let month = new Date().getMonth();
    const date = new Date().getDate();
    if (month == 12) {
      month = 1;
    } else {
      month += 1;
    }
    return `${year}-${("0" + (month + 1)).slice(-2)}-${("0" + (date - 1)).slice(
      -2
    )}`;
  };

  const onCreateCoupon = () => {
    const customConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const postData = {
      discount_type: discountType,
      coupon_name: couponName,
      plan: planType,
      coupon_code: couponCode,
      amount: amountOff,
      expiry_date: expiryDate,
    };
    if (
      discountType &&
      couponName &&
      planType &&
      couponCode &&
      amountOff &&
      expiryDate
    ) {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/create-coupon`,
          postData,
          customConfig
        )
        .then((res) => {
          navigate("/admin/Coupon");
          setLoading(false);
          dispatch(
            isToaster({ flag: true, msg: "Coupon created successfully!" })
          );
        })
        .catch((err) => {
          setLoading(false);
          dispatch(
            isToaster({ flag: true, msg: "Oops! Something went wrong" })
          );
        });
    } else {
      isToaster({ flag: true, msg: "Please enter all details!" });
    }
  };

  const handleDiscountType = (e) => {
    setDiscountType(e.target.value);
  };
  const handlePlanType = (e) => {
    setPlanType(e.target.value);
  };

  const handleCouponCode = (e) => {
    setCouponCode(e.target.value);
  };

  const handleCouponName = (e) => {
    setCouponName(e.target.value);
  };

  const handleAmountOff = (e) => {
    let value = e.target.value;
    if (discountType === "fixed") {
      let amount = planType
        ? plans.filter((x) => x.type === planType)[0].amount
        : null;
      value > amount
        ? dispatch(
            isToaster({
              flag: true,
              msg: `You cannot provide more than ${amount} off for ${planType}!`,
            })
          )
        : setAmountOff(value);
    } else {
      value > 100
        ? dispatch(
            isToaster({
              flag: true,
              msg: "You cannot provide more than 100% off!",
            })
          )
        : setAmountOff(value);
    }
  };

  useEffect(() => {
    const couponcode = document.getElementById("couponcode");

    $(couponcode).on("input", function () {
      var c = this.selectionStart,
        r = /[^a-z0-9]/gi,
        v = $(this).val();
      if (r.test(v)) {
        $(this).val(v.replace(r, ""));
        c--;
      }
      this.setSelectionRange(c, c);
    });

    getPlans();
  }, []);

  return (
    <>
      <section className="addash">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex ">
                <h4 className="m-0">Create Coupons</h4>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="addprofile">
                <form autoComplete="off" noValidate="noValidate">
                  <div className="row input-label">
                    <div className="col-lg-6">
                      <label className="mb-1">
                        <span>Discount Type</span>
                      </label>
                      <select
                        className="form-select-ctm "
                        onChange={handleDiscountType}
                        aria-label="select"
                        name="discount type"
                      >
                        <option value="" selected>
                          
                        </option>
                        <option value="fixed">Fixed Amount Off</option>
                        <option value="percentage">Percentage Off</option>
                      </select>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <label className="mb-1">
                        <span>Select Plan</span>
                      </label>
                      <select
                        className="form-select-ctm "
                        aria-label="select"
                        onChange={handlePlanType}
                        name="select-plan"
                      >
                        <option value="" selected>
                          
                        </option>
                        {plans
                          ? plans.map((x) => {
                              return <option value={x.type}>{x.type}</option>;
                            })
                          : null}
                      </select>
                    </div>
                  </div>
                  <div className="row input-label mt-2">
                    <div className="col-lg-6 col-md-6">
                      <label className="mb-1">
                        <span>Coupon Name</span>
                      </label>
                      <input
                        name="couponName"
                        value={couponName}
                        onChange={handleCouponName}
                        id="couponname"
                        type="text"
                        // placeholder="Coupon Name"
                        className="form-control form-control-ctm "
                      />
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <label className="mb-1">
                        <span>Coupon Code</span>
                      </label>
                      <input
                        name="couponCode"
                        value={couponCode}
                        onChange={handleCouponCode}
                        id="couponcode"
                        type="text"
                        // placeholder="Coupon Code"
                        className="form-control form-control-ctm "
                      />
                    </div>
                  </div>
                  <div className="row input-label mt-2">
                    <div className="col-lg-6 col-md-6">
                      <label className="mb-1">
                        <span>Amount</span>
                      </label>
                      <input
                        name="amountOff"
                        value={amountOff}
                        onChange={handleAmountOff}
                        disabled={!planType}
                        type="number"
                        // placeholder="Enter Amount"
                        className="form-control form-control-ctm "
                      />
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <label className="mb-1">
                        <span>Expiry Date</span>
                      </label>
                      <input
                        name="expirydate"
                        type="date"
                        // value={expiryDate}
                        min={new Date().toISOString().split("T")[0]}
                        value={expiryDate ? expiryDate : getDefaultExpiry()}
                        onChange={(e) => setExpiryDate(e.target.value)}
                        className="form-control form-control-ctm "
                      />
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-lg-12 text-center">
                      <button
                        type="button"
                        className="btn pri-btn"
                        disabled={loading}
                        onClick={onCreateCoupon}
                      >
                        Create Coupon&nbsp;
                        {loading ? (
                          <span
                            className="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : null}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateCoupon;

/** @format */

import React, { useState } from "react";
import DeleteTableData from "./DeleteTableData";
import { idToDelete, isLoader, isToaster } from "../../actions/index.js";
import data from "./availabilityData.js";
import no_data from "../../asset/img/no_data.png";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  EditableCalendarData,
  adData,
  showAvailability,
  showEditableCalendar,
} from "../../actions/index.js";
import "../../asset/css/table.css";
import DatePicker from "react-multi-date-picker";
import axios from "axios";
import { useDispatch } from "react-redux";
import { availabilityData } from "../../actions/index.js";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { Input } from "postcss";
import Calendar from "./Calendar.jsx";
import EditAvailablity from "./EditAvailablity.jsx";
import UpdateAvailability from "./UpdateAvailability.jsx";

const Availablity_Table = () => {
  let dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [data, setData] = useState();
  const BASE_URL = `${process.env.REACT_APP_API_URL}/api/get-availabilities?searchText=${search}&page=`;
  let token = localStorage.getItem("userToken");
  const [loading, setLoading] = useState(false);
  const [showAdAvailability, setShowAdAvailability] = useState(false);
  const ad_data = useSelector((s) => s.ad_data).filter(
    (x) => x.expired === "0"
  );
  const availability = useSelector((s) => s.availability_data);
  let [adsWithoutAvailability, setAdsWithoutAvailability] = useState([]);

  const onEdit = (value) => {
    // adsWithoutAvailability = [{ad_name: value.ad_name, id: value.id}]
    // document.getElementById("ad_name").selectedIndex = 0
    // setSelectedAd(value)
    // setDates(value)
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const onSearch = () => {
    dispatch(isLoader(true));

    axios
      .get(BASE_URL, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch(isLoader(false));
        setData(res.data.availabilities);
        dispatch(availabilityData(res.data.availabilities.data));
        console.log("Get all Admins : ", res.data.availabilities);
      })
      .catch((err) => {
        dispatch(isLoader(false));
        console.log(err);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  const updateData = (url) => {
    dispatch(isLoader(true));
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch(isLoader(false));
        setData(res.data.availabilities);
        dispatch(availabilityData(res.data.availabilities.data));
      })
      .catch((err) => {
        dispatch(isLoader(false));
        console.log(err);
      });
  };

  const format = "YYYY/MM/DD";
  const [dates, setDates] = useState([]);
  const [selectedAd, setSelectedAd] = useState("");
  const [showCalendar, setShowCalendar] = useState();

  // const stringLimiter = (text, count) => {
  //   return text.slice(0, count) + (text.length > count ? "..." : "")
  // }

  const onSubmit = async () => {
    localStorage.setItem("selectedAdName", "");
    let obj;
    let postDate = [];
    dates.map((x) => {
      obj = `${x.year}-${String(x.month.number).padStart(2, "0")}-${String(
        x.day
      ).padStart(2, "0")}`;
      postDate.push(obj);
    });
    const customConfig1 = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let postdata = {
      ad_id: selectedAd,
      dates: postDate,
    };
    if (postdata.ad_id) {
      setLoading(true);
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/create-availability`,
          postdata,
          customConfig1
        )
        .then((res) => {
          setSelectedAd("");
          dispatch(
            isToaster({ flag: true, msg: "Availability added successfully!" })
          );
          setLoading(false);
          setDates(null);
          setLoading(true);
          axios
            .get(
              `${process.env.REACT_APP_API_URL}/api/get-availabilities`,
              customConfig1
            )
            .then((Res) => {
              setLoading(false);
              dispatch(availabilityData(Res.data.availabilities.data));
              console.log(Res);
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
            });
          console.log(res);
        })
        .catch((err) => {
          setLoading(false);
          dispatch(
            isToaster({ flag: true, msg: `${err.response.data.message}` })
          );
        });
    } else {
      dispatch(
        isToaster({ flag: true, msg: "Please select Ads to add availability!" })
      );
    }
  };

  const handleDatePicker = (value) => {
    setDates(value);
  };

  const onCheckAvailability = (dates) => {
    dispatch(showAvailability(true));
    setShowCalendar(dates);
  };

  useEffect(() => {
    setSelectedAd(localStorage.getItem("selectedAdName"));
  }, []);

  useEffect(() => {
    dispatch(isLoader(true));
    const customConfig1 = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/get-active-ads-without-availability`,
        customConfig1
      )
      .then((Res) => {
        dispatch(isLoader(false));
        setAdsWithoutAvailability(Res.data.activeAds);
        console.log("Ads name without availability : ", Res.data.activeAds);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/get-advertisements`,
        customConfig1
      )
      .then((Res) => {
        dispatch(isLoader(false));
        dispatch(adData(Res.data.allAds.data));
        console.log("get Ads data : ", Res.data.allAds.data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(BASE_URL, customConfig1)
      .then((Res) => {
        dispatch(isLoader(false));
        setData(Res.data.availabilities);
        dispatch(availabilityData(Res.data.availabilities.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [loading]);

  return (
    <>
      <div className="row ">
        <div className="col-lg-12">
          <div className="d-flex justify-content-between ">
            <span>
              <h4>Availability</h4>
            </span>
            <span className="position-relative">
              <input
                type="text"
                placeholder="Search"
                name="search"
                value={search}
                onChange={handleSearch}
                onKeyDown={handleKeyDown}
                className="form-control form-control-ctm "
              />
              <i
                className="fa fa-magnifying-glass position-absolute mx-3 mt-2 top-0 end-0 fglass pointer"
                onClick={onSearch}
              ></i>
            </span>
          </div>
        </div>
        <div className={`col-lg-12 mt-3 input-label`}>
          <div className="row">
            <div className="col-lg-4 col-md-4 btn-gap-top ds-card" >
              <label>
                <span>Ads Name</span>
              </label>
              <select
                className="form-select-ctm"
                id="ad_name"
                aria-label="select"
                value={selectedAd}
                onChange={(e) => {
                  setSelectedAd(e.target.value);
                  setDates([]);
                  localStorage.setItem("selectedAdName", "");
                }}
                name="ad_name"
              >
                <option value="">Select an ad</option>
                {Array.isArray(adsWithoutAvailability) &&
                adsWithoutAvailability.length != 0
                  ? adsWithoutAvailability.map((x) => (
                      <option key={x.ad_id} value={x.ad_id}>
                        {x.ad_name}
                      </option>
                    ))
                  : null}
              </select>
            </div>
            <div className="col-lg-4 col-md-4 btn-gap-top ds-card">
              <div className="d-flex flex-column">
                <label>
                  <span>Availability</span>
                </label>
                <span>
                  {/* <DatePicker
                    value={dates}
                    minDate={new Date().toISOString().split("T")[0]}
                    onChange={handleDatePicker}
                    multiple
                    sort
                    format={format}
                    calendarPosition="bottom-center"
                    plugins={[<DatePanel />]}
                    render={
                      <input
                        type="text"
                        className="date-picker-input"
                        placeholder="dd/mm/yyyy"
                      />
                    }
                  /> */}
                  {/* <UpdateAvailability/> */}
                  {/* <i className="fas fa-calendar-alt calendar-icon calender-icon"></i> */}
                </span>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 d-flex align-items-end btn-gap-top btn-gap-center ds-card">
              <button
                type="button"
                className="btn pri-btn"
                disabled={loading}
                onClick={onSubmit}
              >
                Submit &nbsp;
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : null}
              </button>
            </div>
          </div>
        </div>

        <div className="col-lg-12 mt-3">
          <div className="">
            <div className="dashboardtable">
              <div
                className={`table-scrollable ${
                  2>1
                    ? "border-radius-w-pagination"
                    : "border-radius-n-pagination"
                }`}
              >
                <table className="table table-color table-hover table-fixed-first-columns">
                  <thead>
                    <tr>
                      <th style={{ width: "15%" }}>Ads Name</th>
                      <th style={{ width: "70%" }}>Available Date</th>
                      <th style={{ width: "15%" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {availability
                      ? availability.map((value) => {
                          return (
                            <tr>
                              <td style={{ width: "15%" }}>{value.ad_name}</td>
                              <td style={{ width: "70%" }}>
                                <p
                                  className="m-0"
                                  // data-toggle="modal"
                                  // data-target="#pricepopup"
                                >
                                  <button
                                    type="button"
                                    className="btn custom-btn"
                                    onClick={() =>
                                      onCheckAvailability(value.dates)
                                    }
                                  >
                                    Check Availability
                                  </button>
                                </p>
                              </td>
                              <td
                                className="table-action"
                                style={{ width: "15%" }}
                              >
                                <div className="d-flex justify-content-center align-items-center">
                                  <span>
                                    <p
                                      type="button"
                                      className="m-0"
                                      data-toggle="modal"
                                      data-target="#editcalender"
                                      onClick={() => {
                                        dispatch(showEditableCalendar(true))
                                        dispatch(
                                          EditableCalendarData({
                                            id: value.id,
                                            dates: value.dates,
                                          })
                                        )
                                      }}
                                    >
                                      <i className="fa-regular fa-pen-to-square"></i>
                                    </p>
                                  </span>
                                  <p
                                    type="button"
                                    className="my-0 mx-2"
                                    // data-toggle="modal"
                                    // data-target="#deletepopup"
                                    onClick={() =>
                                      dispatch(idToDelete(value.id))
                                    }
                                  >
                                    <i className="far fa-trash-alt"></i>
                                  </p>
                                </div>
                              </td>
                            </tr>
                          )
                        })
                      : null}
                  </tbody>
                </table>
              </div>

              {/* pagination code */}

              {data ? (
                data.last_page > 1 ? (
                  <div className="d-flex justify-content-center w-100 mx-auto pt-4 pb-3">
                    <div
                      className="pagination-css-pre"
                      onClick={() => updateData(data.prev_page_url)}
                      disabled={data.current_page === 1}
                    >
                      <span aria-hidden="true">
                        <i
                          className="fa fa-chevron-left"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </div>
                    <div className="d-flex">
                      <span className={`pagination-css-active`}>
                        {data.current_page <= data.last_page
                          ? data.current_page
                          : data.last_page}
                      </span>
                      <span
                        className={`pagination-css ${
                          data.current_page <= data.last_page - 1
                            ? "d-block"
                            : "d-none"
                        }`}
                        onClick={() =>
                          updateData(`${BASE_URL}${data.current_page + 1}`)
                        }
                      >
                        {data.current_page + 1}
                      </span>
                      <span
                        className={`pagination-css ${
                          data.current_page <= data.last_page - 2
                            ? "d-block"
                            : "d-none"
                        }`}
                        onClick={() =>
                          updateData(`${BASE_URL}${data.current_page + 2}`)
                        }
                      >
                        {data.current_page + 2}
                      </span>
                      <span className="pagination-css ">{` ... `}</span>
                      <button
                        className="pagination-css "
                        disabled={data.current_page === data.last_page}
                        onClick={() =>
                          updateData(`${BASE_URL}${data.last_page}`)
                        }
                      >{` ${data.last_page}`}</button>
                    </div>
                    <div
                      className="pagination-css-next"
                      onClick={() => updateData(data.next_page_url)}
                      disabled={data.current_page === data.last_page}
                    >
                      <span aria-hidden="true">
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </div>
                  </div>
                ) : null
              ) : null}

              {/* pagination code */}
            </div>
          </div>
        </div>
      </div>
      <Calendar
        availableDates={
          showCalendar ? showCalendar : ["2024-03-05", "2024-03-18"]
        }
      />
      <EditAvailablity />
      <DeleteTableData type="availability" />
    </>
  );
};

export default Availablity_Table;

/** @format */
import { Link, useNavigate } from "react-router-dom";
import logo from "../asset/img/logo.png";
import portugal from "../asset/img/portugal.png";
import spain from "../asset/img/spain.png";
import us from "../asset/img/us.png";
import usericon from "../asset/img/user-icon.png";
import { useEffect, useRef, useState } from "react";

function Header() {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const checkToken = () => {
      const token = localStorage.getItem("userToken");
      if (token) {
        setIsLogin(true);
      } else {
        setIsLogin(false);
      }
    };
    checkToken();
    const handleStorageChange = (event) => {
      if (event.key === "userToken") {
        checkToken(); // Re-check token if it changes
      }
    };

    window.addEventListener("storage", handleStorageChange);

    // Cleanup listener on component unmount
    return () => window.removeEventListener("storage", handleStorageChange);
  }, [navigate]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <>
      <nav id="topbar">
        <div className="container ">
          <div className="row">
            <div className="col-lg-6 col-sm-6 top-res">
              <span className="">
                <i className="fa  fa-facebook navicon mx-1"></i>
                <i className="fa-brands fa-whatsapp navicon mx-1"></i>
                <i className="fa-brands fa-x-twitter navicon mx-1"></i>
                <i className="fa-regular fa-envelope navicon mx-1"></i>
              </span>
            </div>
            <div className="col-lg-6 col-sm-6 top-res1">
              <span className="float-end d-flex">
                <span className="navflag mx-1">
                  <img src={us} alt="us flag" />
                </span>
                <span className="navflag mx-1">
                  <img src={portugal} alt="prt flag" />
                </span>
                <span className="navflag mx-1">
                  <img src={spain} alt="Spain flag" />
                </span>
              </span>
            </div>
          </div>
        </div>
      </nav>

      <header className="main-header">
        <nav className="container navbar navbar-expand-lg navbar-m stickey-top">
          <Link className="navbar-brand logo-class" to="/">
            <img src={logo} alt="logo" />
          </Link>
          <span className="header-nav1">
            <span
              className="navbar-nav position-relative me-3"
              ref={dropdownRef}
            >
              <span className="pro">
                <div
                  className="dropdown-icon d-flex align-items-center"
                  type="button"
                  onClick={() => setIsOpen(!isOpen)}
                  aria-controls="submenu4"
                  aria-expanded={isOpen}
                >
                  <span className="profile_user">
                    <img src={usericon} alt="profile" className="h-25" />
                  </span>
                </div>
                <div
                  className={`dropdown-menu profile_menu ${
                    isOpen ? "show" : ""
                  }`}
                  id="submenu4"
                >
                  <ul className="p-0 m-0">
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/login"
                        onClick={handleLinkClick}
                      >
                        Login
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/signup"
                        onClick={() => {
                          handleLinkClick();
                          localStorage.removeItem("userToken");
                        }}
                      >
                        Sign Up
                      </Link>
                    </li>
                  </ul>
                </div>
              </span>
            </span>
          </span>

          <div
            class="navbar-toggler m-0"
            data-bs-toggle="collapse"
            data-bs-target="#headernavbar"
            aria-controls="headernavbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
            <span class="navbar-toggler-icon"></span>
            <span class="navbar-toggler-icon"></span>
          </div>

          <div
            className="collapse navbar-collapse justify-content-center"
            id="headernavbar"
          >
            <ul className="navbar-nav">
              <li className="nav-item active">
                <Link className="nav-link" to="/premium">
                  Premium/VIP
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/premium">
                  Online Services
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/premium">
                  Advanced Search
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/faqs">
                  FAQ
                </Link>
              </li>
            </ul>
          </div>
          <div className="header-nav">
            <Link to="/login">
              <button
                className={`btn cus-btn mx-2 ${isLogin ? "d-none" : null}`}
              >
                Login
              </button>
            </Link>

            <button
              className={`btn cus-btn mx-2 ${isLogin ? null : "d-none"}`}
              onClick={() =>
                localStorage.removeItem("userToken") & setIsLogin(false)
              }
            >
              Logout
            </button>

            <Link to="/signup">
              <button className={`btn cus-btn ${isLogin ? "d-none" : null}`}>Sign Up</button>
            </Link>
            <button
              className={`btn cus-btn mx-2 ${isLogin ? null : "d-none"}`}
              onClick={() =>
                navigate((localStorage.getItem("userRole")).toLowerCase())
              }
            >
              Dashboard
            </button>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;

/** @format */
import { Link } from "react-router-dom"
import login from "../../asset/img/login.png"
import Header from "../../component/header.js"
import Footer from "../../component/footer.js"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { useDispatch } from "react-redux"
import { userData } from "../../actions/index.js"

function Login() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [user, setUser] = useState({
    email: "",
    password: "",
  })
  const [isLoginErr, setLoginErr] = useState(false)
  const [loading, setLoading] = useState(false) // State variable to control loader visibility

  const handleInputs = (e) => {
    const { name, value } = e.target
    setUser({ ...user, [name]: value })
  }

  const showPassword = (a) => {
    let x = document.getElementById(a)
    if (x.type === "password") {
      x.type = "text"
    }
    setTimeout(() => {
      x.type = "password"
    }, 800)
  }

  const onLogin = () => {
    setLoginErr(false)
    setLoading(true) // Show loader when login button is clicked

    let postData = {
      email: user.email,
      password: user.password,
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/api/login`, postData)
      .then((res) => {
        setLoading(false) // Hide loader when login request is successful
        if (res.status === 200) {
          setLoginErr(false)
          dispatch(userData(res.data))
          localStorage.setItem("userRole", res.data.user.role)
          localStorage.setItem("userToken", res.data.token)
          if (res.data.user.role === "Advertiser") {
            navigate("/advertiser")
          } else if (
            res.data.user.role === "Super_Admin" ||
            "Support" ||
            "Manager"
          ) {
            navigate("/admin")
          }
        }
      })
      .catch((err) => {
        setLoading(false) // Hide loader when login request fails
        setLoginErr(true)
        console.error(err)
      })
  }
  return (
    <>
      <Header />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <div className="form-layout">
                <div className="row p-3 d-flex align-items-center">
                  <div className="col-lg-6">
                    <img src={login} alt="sign in" className="img-fluid" />
                  </div>
                  <div className="col-lg-6">
                    <div
                      style={{ color: "#c01f25", fontSize: "14px" }}
                      className={`${
                        isLoginErr ? "visible" : "invisible"
                      } text-center -mb-3`}
                    >
                      The email address you entered isn't in our database !
                    </div>
                    <form
                      autocomplete="off"
                      id="multistep-form"
                      onSubmit={(e) => {
                        e.preventDefault()
                      }}
                    >
                      <div>
                        <div className="singinform p-lg-4 d-flex justify-content-space-between h-fit flex-column">
                          <h4>
                            SIGN INTO <span>YOUR ACCOUNT</span>
                          </h4>
                          <div className="inputField inputFieldtop">
                            <input
                              type="email"
                              id="email"
                              name="email"
                              value={user.email}
                              onChange={handleInputs}
                              placeholder=" "
                              className="form-control form-control-ctm my-2"
                            />
                            <span>Email</span>
                          </div>

                          {/* <input
                            type="email"
                            id="email"
                            name="email"
                            value={user.email}
                            onChange={handleInputs}
                            className="form-control form-control-ctm my-2"
                            // placeholder="Email"
                          /> */}
                          <div className="inputField inputFieldtop">
                            <input
                              type="password"
                              id="password"
                              name="password"
                              value={user.password}
                              onChange={handleInputs}
                              className="form-control form-control-ctm my-2"
                              placeholder=" "
                            />
                            <span>Password</span>
                            <div
                              className="pwdeyelogin"
                              onClick={() => showPassword("password")}
                            >
                              <i className="fa fa-eye"></i>
                            </div>
                          </div>
                          <p className="sendmsg mt-2">
                            <span className="float-start d-flex justify-content-space-between">
                              <input
                                className="form-check-input ml-0"
                                type="checkbox"
                                value=""
                                id="defaultCheck1"
                              />
                              <label
                                className="form-check-label ml-4"
                                style={{ marginLeft: "10px" }}
                                htmlFor="defaultCheck1"
                              >
                                Remember me
                              </label>
                            </span>
                            <span className="float-end">
                              <Link to="/forgotPassword">Forgot Password</Link>
                            </span>
                          </p>
                          <div>
                            <button
                              type="submit"
                              disabled={loading}
                              onClick={onLogin ?? onLogin}
                              className="btn sub-btn w-100"
                            >
                              {" "}
                              Sign In &nbsp;
                              {loading ? (
                                <span
                                  className="spinner-border spinner-border-sm me-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : null}
                            </button>
                          </div>
                          <p className="mt-2">
                            Don't have an account?
                            <Link to="/signup" className="login-register">
                              Register Here
                            </Link>
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-1"></div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Login

import React from "react";
import { useSelector } from "react-redux";
import no_data from "../../asset/img/no_data.png";

const ViewImagesCard = () => {
  const data = useSelector((s) => s.advertiser_data);

  return (
    <>
      <div>
        <div className="profile-info-nav">
          <div className="row position-relative profile-info-tab">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="m-0">
                Photos <span className="profile-info-tab-txt"></span>
              </h4>
            </div>
            <span className="profile-info-border"></span>
          </div>
          <div className="row mt-3">
            {Array.isArray(data.images) && data.images.length != 0 ? (
              data.images.map((x) => {
                return (
                  <div className="col-lg-6 col-md-6 col-12 p-2">
                    <div className="profile-view-img">
                      <img
                        src={x.image}
                        alt="advertiser"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center">
                <img src={no_data} alt="Description of the image" width={277} />
              </div>
            )}
          </div>
          {/* <div className="row mt-3">
            <div className="col-lg-12">
                <div className="text-center">
                    <button type="button" className="btn cus-btn">Load More</button>
                </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ViewImagesCard;

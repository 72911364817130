/** @format */

import axios from "axios"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import no_data from "../../asset/img/no_data.png";
import { adData, allAdvertiser, idToDelete, isLoader } from "../../actions"
import DeleteEntry from "../component/DeleteEntry"

const AllAdvertisers = () => {
  const token = localStorage.getItem("userToken")
  const [search, setSearch] = useState("")
  const BASE_URL = `${process.env.REACT_APP_API_URL}/api/get-all-advertisers?searchText=${search}&page=`
  const role = localStorage.getItem("userRole")
  let dispatch = useDispatch()
  const advertiser = useSelector((s) => s.all_advertiser)
  const [data, setData] = useState({
    current_page: 1
  })

  useEffect(() => {
    const fetchData = async () => {
      dispatch(isLoader(true)); // Show loader before fetching data
      try {
        const response = await axios.get(BASE_URL + "1", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setData(response.data.advertisers);
        dispatch(allAdvertiser(response.data.advertisers.data));
        console.log("Get all Advertisers : ", response.data.advertisers);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(isLoader(false)); // Hide loader after fetching data
      }
    };
  
    fetchData(); // Call the fetchData function when the component mounts
  
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value)
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSearch()
    }
  }

  const onSearch = () => {
    dispatch(isLoader(true))

    axios
      .get(BASE_URL, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch(isLoader(false))
        setData(res.data.advertisers)
        dispatch(allAdvertiser(res.data.advertisers.data))
        console.log("Get all Admins : ", res.data.advertisers)
      })
      .catch((err) => {
        dispatch(isLoader(false))
        console.log(err)
      })
  }

  const updateData = (url) => {
    dispatch(isLoader(true))
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch(isLoader(false))
        setData(res.data.advertisers)
        dispatch(allAdvertiser(res.data.advertisers.data))
        console.log("Get all Admins : ", res.data.advertisers)
      })
      .catch((err) => {
        dispatch(isLoader(false))
        console.log(err)
      })
  }

  return (
    <>
      <DeleteEntry type="Advertiser" page={data.current_page}/>
      <section className="addash">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between ">
                <span>
                  <h4>All Advertisers</h4>
                </span>
                <span className="position-relative">
                  <input
                    type="text"
                    placeholder="Search"
                    name="search"
                    value={search}
                    onChange={handleSearch}
                    onKeyDown={handleKeyDown}
                    className="form-control form-control-ctm "
                  />
                  <i
                    className="fa fa-magnifying-glass position-absolute mx-3 mt-2 top-0 end-0 fglass pointer"
                    onClick={onSearch}
                  ></i>
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mt-3">
              <div className="dashboardtable">
                <div
                  className={`table-scrollable ${
                    data > 1
                      ? "border-radius-n-pagination"
                      : "border-radius-w-pagination"
                  }`}
                >
                  <table className="table table-color table-hover table-fixed-first-columns">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Age</th>
                        <th>Plan</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(advertiser) && advertiser.length != 0
                        ? advertiser.map((x) => {
                            return (
                              <tr>
                                <td>{x.firstname + " " + x.lastname}</td>
                                <td>{x.email}</td>
                                <td>{x.phone}</td>
                                <td>{x.age}</td>
                                <td>{x.plan}</td>
                                <td className="table-action">
                                  <div className="d-flex justify-content-center align-items-center">
                                    <span>
                                      <Link
                                        to="/admin/viewAdvertiser"
                                        onClick={() =>
                                          localStorage.setItem(
                                            "viewAdvertiser",
                                            x.id
                                          )
                                        }
                                      >
                                        <i className="fa-regular fa-eye"></i>
                                      </Link>
                                    </span>
                                    &nbsp; &nbsp; &nbsp;
                                    <p
                                      type="button"
                                      className={`my-0 mx-2 ${
                                        role === "Support"
                                          ? "d-none"
                                          : "d-block"
                                      }`}
                                      // data-toggle="modal"
                                      // data-target="#deletepopup"
                                      onClick={() => dispatch(idToDelete(x.id))}
                                    >
                                      <i className="far fa-trash-alt"></i>
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            )
                          })
                        : (
                          <tr>
                            <td colSpan={6}>
                              <img
                                src={no_data}
                                alt="Description of the image"
                                width={277}
                         />
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>

                {/* pagination code */}

                {data ? (
                  data.last_page > 1 ? (
                    <div className="d-flex justify-content-center w-100 mx-auto pt-4 pb-3">
                      <div
                        className="pagination-css-pre"
                        onClick={() => updateData(data.prev_page_url)}
                        disabled={data.current_page === 1}
                      >
                        <span aria-hidden="true">
                          <i className="fa fa-chevron-left" aria-hidden="true"></i>
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className={`pagination-css-active`}>
                          {data.current_page <= data.total / data.per_page
                            ? data.current_page
                            : data.last_page}
                        </span>
                        <span
                          className={`pagination-css ${
                            data.current_page <= data.last_page - 1
                              ? "d-block"
                              : "d-none"
                          }`}
                          onClick={() =>
                            updateData(
                              `${BASE_URL}${
                                data.current_page + 1
                              }`
                            )
                          }
                        >
                          {data.current_page + 1}
                        </span>
                        <span
                          className={`pagination-css ${
                            data.current_page <= data.last_page - 2
                              ? "d-block"
                              : "d-none"
                          }`}
                          onClick={() =>
                            updateData(
                              `${BASE_URL}${
                                data.current_page + 2
                              }`
                            )
                          }
                        >
                          {data.current_page + 2}
                        </span>
                        <span className="pagination-css ">{` ... `}</span>
                        <span className="pagination-css ">{` ${data.last_page}`}</span>
                      </div>
                      <div
                        className="pagination-css-next"
                        onClick={() => updateData(data.next_page_url)}
                        disabled={data.current_page === data.last_page}
                      >
                        <span aria-hidden="true">
                          <i className="fa fa-chevron-right" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>
                  ) : null
                ) : null}

                {/* pagination code */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AllAdvertisers

/** @format */

import React from "react";
import axios from "axios";
import { useEffect } from "react";
import Sidebar from "./component/Sidebar";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isLoader, userData } from "../actions/index";

const AdvertiserDashboard = () => {
  const token = localStorage.getItem("userToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(isLoader(true));
    if (token) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/get-advertiser`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          dispatch(isLoader(false));
          localStorage.setItem("userData", JSON.stringify(res.data.user))
          dispatch(userData(res.data.user));
          console.log("advertiser data at advertiser panel: ", res.data.user);
        })
        .catch((err) => {
          dispatch(isLoader(false));
          navigate("/login");
        });
    } else {
      dispatch(isLoader(false));
      navigate("/login");
    }
  }, []);
  return (
    <>
      {token ? (
        <>
          <Sidebar />
        </>
      ) : (
        () => navigate("/login")
      )}
    </>
  );
};

export default AdvertiserDashboard;

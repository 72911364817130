/** @format */

import React from "react";
import { useSelector } from "react-redux";

const AboutProfileCard = () => {
  const data = useSelector((s) => s.advertiser_data);

  return (
    <>
      <div className="mt-3">
        <div className="profile-info-nav">
          <div className="row position-relative profile-info-tab">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="m-0"> About </h4>
            </div>
            <span className="profile-info-border"></span>
          </div>
          <div className="mt-4">
            <div className="text-center">
              <img
                src={data.profile_photo}
                alt="Advertiser"
                className="img-fluid model-round-profile"
              />
            </div>
            <div className="text-center mt-2">
              <h4 className="profile-tab-card-head m-0">
                {data.firstname + " " + data.lastname}
              </h4>
              <p className="profile-tab-card-txt">Escort Model From {data.city}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutProfileCard;

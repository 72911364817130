/** @format */

import React, { useEffect, useMemo, useState } from "react";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import "../../asset/css/sidebar.css";
import activeAds from "../../asset/img/icon/Ads/activeAds.png";
import createAds from "../../asset/img/icon/Ads/createAds.png";
import expiredAds from "../../asset/img/icon/Ads/expiredAds.png";
import message from "../../asset/img/icon/Message.png";
import account from "../../asset/img/icon/account.png";
import ads from "../../asset/img/icon/ads.png";
import availability from "../../asset/img/icon/availability.png";
import dashboard from "../../asset/img/icon/dashboard.png";
// import plan from "../../asset/img/icon/plan.png";
import profile from "../../asset/img/icon/profile.png";
import profile_logo from "../../asset/img/icon/profile_logo.png";
import logo from "../../asset/img/logo.png";
import portugal from "../../asset/img/portugal.png";
import spain from "../../asset/img/spain.png";
import { io } from "socket.io-client";
import us from "../../asset/img/us.png";
import managment from "../../asset/img/managment.png";
import coupon from "../../asset/img/coupon.png";
import promotezone from "../../asset/img/promote-zone.png";
import trashads from "../../asset/img/trash-ads.png";
import { useDispatch, useSelector } from "react-redux";
import { isJoinRequest } from "../../actions";
import JoinRequest from "../../component/joinRequest";
const AdminSidebar = () => {
  const data = useSelector((state) => state.user_data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const socket = useMemo(() => io(process.env.REACT_APP_CHAT_URL), []);
  const role = localStorage.getItem("userRole");
  const [notification, setNotification] = useState(0);
  // const toggleSidebar = useSelector((state) => state.isSidebar)
  // const [isProfileDrop, setProfileDrop] = useState(false)
  // const [sideDrop, setSideDrop] = useState("")
  // console.log(sideTab)
  // const dispatch = useDispatch()

  function handleSidebarToggle(event) {
    event.preventDefault();
    if (window.innerWidth < 767) {
      document.body.classList.toggle("sb-sidenav-toggled");
      localStorage.setItem(
        "sb|sidebar-toggle",
        document.body.classList.contains("sb-sidenav-toggled")
      );
    }
  }
  function handleSidebarButton(event) {
    event.preventDefault();
    document.body.classList.toggle("sb-sidenav-toggled");
    localStorage.setItem(
      "sb|sidebar-toggle",
      document.body.classList.contains("sb-sidenav-toggled")
    );
  }
  // window.onload = () => {
  //   socket.on("join room request to admin", (userRoom, name) => {
  //     setNotification(notification + 1);
  //   });
  // };

  useEffect(() => {
    // socket.on("connect", () => {
    //   console.log("socket ID: ", socket.id);
    // });
    socket.on(
      "join room request to admin",
      (userRoom, name, msg, profile, time) => {
        dispatch(
          isJoinRequest({
            flag: true,
            roomName: userRoom,
            name: name,
            msg: msg,
            profile: profile,
            time: time,
            accepted: false,
          })
        );
      }
    );
    const sidebarToggle = document.body.querySelector("#sidebarToggle");

    if (sidebarToggle) {
      sidebarToggle.addEventListener("click", handleSidebarButton);

      return () => {
        sidebarToggle.removeEventListener("click", handleSidebarButton);
      };
    }
  }, []);

  return (
    <>
      {/* <JoinRequest /> */}
      <div id="wrapper" className="d-flex">
        <div id="sidebar-wrapper">
          <div className="d-flex flex-column flex-shrink-0 text-white sidebar">
            <div className="sidebar-heading">
              <div className="d-flex justify-content-center align-items-center">
                <span className="dashboard-logo"  onClick={() => navigate("/admin")}>
                  <img src={logo} alt="logo" className="img-fluid" />
                </span>
                <button
                  className="btn togglebtn position-absolute end-0"
                  onClick={handleSidebarButton}
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 6L6 18"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6 6L18 18"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="list-group list-group-flush" id="accordion">
              <ul className="nav nav-pills flex-column mb-auto sidebar_advertiser">
                <li>
                  <div onClick={handleSidebarToggle}>
                    <NavLink
                      exact
                      activeclassname="active"
                      to="/admin"
                      className="nav-link text-white d-flex align-items-center sidebarmenu"
                      end
                    >
                      <img src={dashboard} alt="dashboard" />
                      <span className="mx-3 iconmenu">Dashboard</span>
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div onClick={handleSidebarToggle}>
                    <NavLink
                      activeclassname="active"
                      to="/admin/alladvertiser"
                      className="nav-link text-white d-flex align-items-center sidebarmenu"
                    >
                      <img src={profile} alt="profile" />
                      <span className="mx-3 iconmenu">
                        <span>Advertisers</span>
                      </span>
                    </NavLink>
                  </div>
                </li>

                <li>
                  <div className="accordion-item">
                    <Link
                      activeclassname="active"
                      to="#"
                      className="nav-link text-white position-relative d-flex align-items-center sidebarmenu"
                      exact="true"
                      data-toggle="collapse"
                      data-bs-auto-close="outside"
                      data-target="#submenu1"
                    >
                      <img src={ads} alt="profile" />
                      <span className="mx-3 iconmenu menudropdown">
                        <span>Ads </span>
                        <i className="fa fa-caret-fa float-end position-absolute"></i>
                      </span>
                    </Link>
                    <div
                      id="submenu1"
                      className="accordion-collapse collapse"
                      data-parent="#accordion"
                    >
                      <ul className="navi nav-list">
                        <li className="dropdown-item">
                          <div onClick={handleSidebarToggle}>
                            <NavLink
                              activeclassname="active"
                              to="/admin/totalAds"
                              className="nav-link text-white d-flex align-items-center p-0 sidebarmenu sidebarmenuitem"
                            >
                              <img src={createAds} alt="profile" />
                              <span className="iconmenu">
                                {" "}
                                &nbsp; Total Ads
                              </span>
                            </NavLink>
                          </div>
                        </li>
                        <li className="dropdown-item">
                          <div onClick={handleSidebarToggle}>
                            <NavLink
                              activeclassname="active"
                              to="/admin/latestAds"
                              className="nav-link text-white d-flex align-items-center p-0 sidebarmenu sidebarmenuitem"
                            >
                              <img src={activeAds} alt="profile" />
                              <span className="iconmenu">
                                {" "}
                                &nbsp; Recent Ads
                              </span>
                            </NavLink>{" "}
                          </div>
                        </li>
                        <li className="dropdown-item">
                          <div onClick={handleSidebarToggle}>
                            <NavLink
                              activeclassname="active"
                              to="/admin/pauseAds"
                              className="nav-link text-white d-flex align-items-center p-0  sidebarmenu sidebarmenuitem"
                            >
                              <img src={expiredAds} alt="profile" />
                              <span className="iconmenu">
                                {" "}
                                &nbsp; Paused Ads
                              </span>
                            </NavLink>{" "}
                          </div>
                        </li>
                        <li className="dropdown-item">
                          <div onClick={handleSidebarToggle}>
                            <NavLink
                              activeclassname="active"
                              to="/admin/trashAds"
                              className="nav-link text-white d-flex align-items-center p-0 sidebarmenu sidebarmenuitem"
                            >
                              <img src={trashads} alt="profile" />
                              <span className="iconmenu"> &nbsp; Trash</span>
                            </NavLink>{" "}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li
                  className={`${
                    role === "Manager" || role === "Support"
                      ? "d-none"
                      : "d-block"
                  }`}
                >
                  <div className="accordion-item">
                    <Link
                      activeclassname="active"
                      to="#"
                      className={`nav-link text-white position-relative d-flex align-items-center sidebarmenu ${
                        role === "Super_Admin" ? null : "d-none"
                      }`}
                      exact="true"
                      data-toggle="collapse"
                      data-bs-auto-close="outside"
                      data-target="#submenuCoupon"
                    >
                      <img src={managment} alt="profile" />
                      <span className="mx-3 iconmenu menudropdown">
                        <span>Marketing </span>
                        <i className="fa fa-caret-fa float-end position-absolute"></i>
                      </span>
                    </Link>
                    <div
                      id="submenuCoupon"
                      className="accordion-collapse collapse"
                      data-parent="#accordion"
                    >
                      <ul className="navi nav-list">
                        <li className="dropdown-item">
                          <div onClick={handleSidebarToggle}>
                            <NavLink
                              activeclassname="active"
                              to="/admin/Coupon"
                              className="nav-link text-white d-flex align-items-center p-0 sidebarmenu sidebarmenuitem"
                            >
                              <img src={coupon} alt="profile" />
                              <span className="iconmenu"> &nbsp; Coupon</span>
                            </NavLink>
                          </div>
                        </li>
                        <li className="dropdown-item">
                          <div onClick={handleSidebarToggle}>
                            <NavLink
                              activeclassname="active"
                              to="/admin/promotezone"
                              className="nav-link text-white d-flex align-items-center p-0 sidebarmenu sidebarmenuitem"
                            >
                              <img src={promotezone} alt="profile" />
                              <span className="iconmenu">
                                {" "}
                                &nbsp; Promote Zone
                              </span>
                            </NavLink>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li
                  className={`${
                    role === "Manager" || role === "Support"
                      ? "d-none"
                      : "d-block"
                  }`}
                >
                  <div onClick={handleSidebarToggle}>
                    <NavLink
                      activeclassname="active"
                      to="/admin/adsSales"
                      className="nav-link text-white  d-flex align-items-center sidebarmenu"
                    >
                      <img src={availability} alt="profile" />
                      <span className="mx-3 iconmenu">Ads Sales</span>
                    </NavLink>
                  </div>
                </li>

                <li
                  className={`${
                    role === "Manager" || role === "Support"
                      ? "d-none"
                      : "d-block"
                  }`}
                >
                  <div className="accordion-item">
                    <Link
                      activeclassname="active"
                      to="#"
                      className="nav-link text-white position-relative d-flex align-items-center sidebarmenu"
                      exact="true"
                      data-toggle="collapse"
                      data-bs-auto-close="outside"
                      data-target="#submenu"
                    >
                      <img src={ads} alt="profile" />
                      <span className="mx-3 iconmenu menudropdown">
                        <span>Reports</span>
                        <i className="fa fa-caret-fa float-end position-absolute"></i>
                      </span>
                    </Link>
                    <div
                      id="submenu"
                      className="accordion-collapse collapse"
                      data-parent="#accordion"
                    >
                      <ul className="navi nav-list">
                        <li className="dropdown-item">
                          <div onClick={handleSidebarToggle}>
                            <NavLink
                              activeclassname="active"
                              to="/admin/advertiserRevenue"
                              className="nav-link text-white d-flex align-items-center p-0 sidebarmenu sidebarmenuitem"
                            >
                              <img src={createAds} alt="profile" />
                              <span className="iconmenu">
                                {" "}
                                &nbsp; Advertiser
                              </span>
                            </NavLink>
                          </div>
                        </li>
                        <li className="dropdown-item ">
                          <div onClick={handleSidebarToggle}>
                            <NavLink
                              activeclassname="active"
                              to="/admin/revenueReport"
                              className="nav-link text-white d-flex align-items-center p-0 sidebarmenu sidebarmenuitem"
                            >
                              <img src={activeAds} alt="profile" />
                              <span className="iconmenu"> &nbsp; Revenue</span>
                            </NavLink>{" "}
                          </div>
                        </li>
                        <li className="dropdown-item ">
                          <div onClick={handleSidebarToggle}>
                            <NavLink
                              activeclassname="active"
                              to="/admin/salesReport"
                              className="nav-link text-white d-flex align-items-center p-0 sidebarmenu sidebarmenuitem"
                            >
                              <img src={expiredAds} alt="profile" />
                              <span className="iconmenu"> &nbsp; Sales</span>
                            </NavLink>{" "}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li
                  className={`${
                    role === "Manager" || role === "Super_Admin"
                      ? "d-none"
                      : "d-block"
                  }`}
                >
                  <div onClick={handleSidebarToggle}>
                    <NavLink
                      activeclassname="active"
                      to="/admin/myMessage"
                      className="nav-link text-white  d-flex align-items-center sidebarmenu"
                    >
                      <img src={message} alt="message" />
                      <span className="mx-3 iconmenu">Chat</span>
                    </NavLink>
                  </div>
                </li>
                <li
                  className={`${
                    role === "Manager" || role === "Support"
                      ? "d-none"
                      : "d-block"
                  }`}
                >
                  <div onClick={handleSidebarToggle}>
                    <NavLink
                      activeclassname="active"
                      to="/admin/user"
                      className="nav-link text-white  d-flex align-items-center sidebarmenu"
                    >
                      <img src={account} alt="profile" />
                      <span className="mx-3 iconmenu">Users</span>
                    </NavLink>
                  </div>
                </li>

                <li>
                  <div onClick={handleSidebarToggle}>
                    <NavLink
                      activeclassname="active"
                      to="/admin/myAccount"
                      className="nav-link text-white  d-flex align-items-center sidebarmenu"
                    >
                      <img src={account} alt="profile" />
                      <span className="mx-3 iconmenu">My Account</span>
                    </NavLink>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* NavBar */}

        <div className="page-content-wrapper ">
          <nav className="navbar navbar-expand-lg dash-header" id="header">
            <div className="container-fluid">
              <div className="text-light togglebar" id="sidebarToggle">
                <i className="fa fa-bars-staggered"></i>
              </div>

              <span className="dashboard-logo-menu" onClick={() => navigate("/admin")}>
                <img src={logo} alt="logo" className="img-fluid" />
              </span>

              <span className="position-relative searchbar-nav">
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control form-control-ctm searchip"
                />
                <i className="fa fa-magnifying-glass position-absolute mx-3 mt-2 top-0 end-0 fglass"></i>
              </span>
              <span className="nav-flag">
                <span className="navflag mx-1 navflagdash">
                  <img src={us} alt="us flag" />
                </span>
                <span className="navflag mx-1 navflagdash nav-flag-spain">
                  <img src={portugal} alt="prt flag" />
                </span>
                <span className="navflag mx-1 navflagdash">
                  <img src={spain} alt="Spain flag" />
                </span>
              </span>

              <span className="navbar-nav position-relative me-3">
                <span className="nav-bell">
                  <i className="fa-regular fa-bell"></i>
                  {/* {notification} */}
                </span>
                <span
                  className="pro"
                  exact="true"
                  activeclassname="active"
                  data-toggle="collapse"
                  data-target="#submenu4"
                >
                  <div
                    className="dropdown-icon d-flex align-items-center "
                    type="button"
                    data-toggle="dropdown"
                    data-auto-close="outside"
                    aria-expanded="false"
                  >
                    <span className="profile_user">
                      <img
                        src={
                          data.profile_photo ? data.profile_photo : profile_logo
                        }
                        alt="profile"
                        className=""
                      />
                      <i className="arrow-down"></i>
                    </span>
                  </div>
                  <ul
                    className="dropdown-menu collapse profile_menu"
                    id="submenu4"
                  >
                    <li>
                      <Link className="dropdown-item" to="/admin/myAccount">
                        My Account
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/"
                        onClick={() => localStorage.removeItem("userToken")}
                      >
                        Log Out
                      </Link>
                    </li>
                  </ul>
                </span>
              </span>
            </div>
          </nav>

          <Outlet />

          <div className=" p-3 fixed-bottom1">
            <div className="container-fluid">
              <div className="d-flex align-items-center justify-content-between small text-center">
                <div className="text-muted">
                  <span className="copyright quick-links footer_height text-light">
                    Copyright © 2024{" "}
                    <Link to="#" className="text-light">
                      QuickVisit.com
                    </Link>{" "}
                    All Rights Reserved{" "}
                    <Link to="#" className="text-light">
                      Term and Conditions
                    </Link>
                    .
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSidebar;

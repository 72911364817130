/** @format */

import React, { useEffect, useState } from "react";
// import play from "../../asset/img/play.png";
import { useSelector } from "react-redux";
import no_data from "../../asset/img/no_data.png";
// import { isLoader } from "../../actions";
// import axios from "axios";

const ViewVideoCard = () => {
  const data = useSelector((s) => s.advertiser_data);

  return (
    <>
      <div className="mt-3">
        <div className="profile-info-nav">
          <div className="row position-relative profile-info-tab">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="m-0">
                {" "}
                Videos <span className="profile-info-tab-txt"></span>
              </h4>
            </div>
            <span className="profile-info-border"></span>
          </div>
          {Array.isArray(data.videos) && data.videos.length != 0 ? (
            data.videos.map((x) => {
              return (
                <div className="row mt-4 viewcardtop1">
                  <div className="col-lg-6 col-md-6">
                    <div className="video-container">
                      <video
                        id="videoElement"
                        className="w-100"
                        loop
                        muted
                        controls
                      >
                        <source
                          src={x.video}
                          loop
                          className="hover-to-play w-100"
                        />
                      </video>
                      {/* <span
                      id="customPlayButton"
                      className="play-button"
                      onClick={() => togglePlayPause() & setisplay(!isplay)}
                    >
                      {isplay ? null : <img src={play} alt="play/pause" />}
                    </span> */}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="text-center">
              <img
                src={no_data}
                alt="Description of the image"
                width={277}
              />
            </div>
          )}

          {/* <div className="row mt-3">
            <div className="col-lg-12">
              <div className="text-center">
                <button type="button" className="btn cus-btn">
                  Load More
                </button>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ViewVideoCard;

import React, { useEffect, useState } from 'react'

const BacktoTop = () => {

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
  
    const handleScroll = () => {
      if (window.pageYOffset > 20) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
  
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
  
  return (
    <>
     <span
      type="button"
      className={`btn btn-floating back-to-top btn-lg ${
        isVisible ? "d-block" : "d-none"
      }`}
      id="btn-back-to-top"
      onClick={scrollToTop}
    >
          <i className="fa fa-arrow-up"></i>
    </span>
    </>
  )
}

export default BacktoTop
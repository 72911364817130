/** @format */

import React, { useEffect, useState } from "react"
import Calendar from "../../advertiser/component/Calendar"
import { showAvailability } from "../../actions"
import { useDispatch, useSelector } from "react-redux"
import DisplayAvailability from "../../advertiser/component/DisplayAvailability"

const ViewAvaibilityTable = () => {
  const data = useSelector((s) => s.availability_data)
  console.log("available days in end", data)
  const dispatch = useDispatch()
  const onCheckAvailability = () => {
    dispatch(showAvailability(true))
  }

  return (
    <>
      {/* <button
        type="button"
        className="btn custom-btn"
        onClick={() => onCheckAvailability()}
      >
        Check Availability
      </button> */}
      {/* <Calendar
        availableDates={
          data ? data : ["2024-03-05", "2024-03-18", "2024-03-19", "2024-03-20"]
        }
        directShow={true}
      /> */}
      <DisplayAvailability/>
    </>
  )
}

export default ViewAvaibilityTable

import React, { useEffect, useState } from "react";
import plan from "../../../src/asset/img/crown.png";
import { Link } from "react-router-dom";
import service from "../../../src/asset/img/service.png";
import cplan from "../../../src/asset/img/cplan.png";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { isLoader } from "../../actions";

const Plan = () => {
  const data = useSelector((state) => state.user_data);
  const dispatch = useDispatch();
  const [plans, setPlans] = useState([]);

  const getPlans = () => {
    dispatch(isLoader(true));
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/get-plans`)
      .then((Res) => {
        dispatch(isLoader(false));
        setPlans(Res.data.plans);
        // setSelectedPlan(Res.data.plans[0]);
        // dispatch(adData(Res.data.allAds.data));
        console.log("plans : ", Res.data.plans);
      })
      .catch((err) => {
        dispatch(isLoader(false));
        // console.log(err);
      });
  };
  useEffect(() => {
    getPlans();
  }, []);
  return (
    <>
      <section className="addash">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex align-items-center">
                <h4>View Plan</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <div className="plan">
                <div className="d-flex align-items-center ">
                  <img src={plan} alt="plan" className="img-fluid" />
                  <span className="mx-4 plan-text">
                    <p className="m-0">
                      Your active package is -{" "}
                      <span className="plan-detail">{data.plan}</span> -{" "}
                      <Link>Click to pick another one</Link>
                      <span> Expire : 365 Days</span>
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            {plans
              ? plans.map((x) => {
                  return (
                    <div className="col-sm-6 col-md-6 col-lg-3">
                      <div
                        className="single_price_plan wow fadeInUp"
                        data-wow-delay="0.2s"
                      >
                        <div className="single_price_header">
                          <div className="single_price_header-img">
                            <img
                              src={service}
                              alt="planicon"
                              className="img-fluid"
                            />
                          </div>
                          <div className="title ">
                            <h3>{x.type} </h3>
                          </div>
                        </div>
                        <div className="price">
                          <h4>{x.amount > 0? x.amount : 0} BTC</h4>
                          <span>/ Month</span>
                          <div className="line"></div>
                        </div>
                        <div className="plan-description">
                          <p>
                            <i className="fa-regular fa-circle-check"></i>Add {x.total_photos}
                            Photos
                          </p>
                          <p>
                            <i className="fa-regular fa-circle-check"></i>Add {x.total_videos}
                            Videos
                          </p>
                          <p>
                            <i className="fa-regular fa-circle-xmark lni-close"></i>
                            No Hidden Fees
                          </p>
                        </div>
                        <div className="button-plan">
                          <div>
                            <img src={cplan} alt="" className={`img-fluid BTC{data.plan == x.type? null : 'd-none'}`} />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
            {/* <div className="col-sm-6 col-md-6 col-lg-3">
              <div className="single_price_plan wow fadeInUp" data-wow-delay="0.2s" >
                <div className='single_price_header'>
                  <div className='single_price_header-img'>
                    <img src={service} alt="planicon" className="img-fluid" />
                  </div>
                  <div className="title ">
                    <h3>Basic </h3>
                  </div>
                </div>
                <div className="price">
                  <h4>BTC50</h4>
                  <span>/ Month</span>
                  <div className="line"></div>
                </div>
                <div className="plan-description">
                  <p><i className="fa-regular fa-circle-check"></i>Add 4 Photos</p>
                  <p><i className="fa-regular fa-circle-check"></i>Add 2 Videos</p>
                  <p><i className="fa-regular fa-circle-xmark lni-close"></i>No Hidden Fees</p>
                  <p><i className="fa-regular fa-circle-xmark lni-close"></i>No Hidden Fees</p>
                </div>
                <div className="button-plan"><Link className="btn pri-btn" to="#">Choose Plan</Link></div>
              </div>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-3">
              <div className="single_price_plan wow fadeInUp" data-wow-delay="0.2s" >
                <div className='single_price_header'>
                  <div className='single_price_header-img'>
                    <img src={service} alt="planicon" className="img-fluid" />
                  </div>
                  <div className="title ">
                    <h3>Standard </h3>
                  </div>
                </div>
                <div className="price">
                  <h4>BTC80</h4>
                  <span>/ Month</span>
                  <div className="line"></div>
                </div>
                <div className="plan-description">
                  <p><i className="fa-regular fa-circle-check"></i>Add 4 Photos</p>
                  <p><i className="fa-regular fa-circle-check"></i>Add 2 Videos</p>
                  <p><i className="fa-regular fa-circle-xmark lni-close"></i>No Hidden Fees</p>
                  <p><i className="fa-regular fa-circle-xmark lni-close"></i>No Hidden Fees</p>
                </div>
                <div className="button-plan"><Link className="btn pri-btn" to="#">Choose Plan</Link></div>
              </div>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-3">
              <div className="single_price_plan wow fadeInUp" data-wow-delay="0.2s" >
                <div className='single_price_header'>
                  <div className='single_price_header-img'>
                    <img src={service} alt="planicon" className="img-fluid" />
                  </div>
                  <div className="title ">
                    <h3>Premium </h3>
                  </div>
                </div>
                <div className="price">
                  <h4>BTC100</h4>
                  <span>/ Month</span>
                  <div className="line"></div>
                </div>
                <div className="plan-description">
                  <p><i className="fa-regular fa-circle-check"></i>Add 4 Photos</p>
                  <p><i className="fa-regular fa-circle-check"></i>Add 2 Videos</p>
                  <p><i className="fa-regular fa-circle-check"></i>No Hidden Fees</p>
                  <p><i className="fa-regular fa-circle-check"></i>No Hidden Fees</p>
                </div>
                <div className="button-plan"><Link className="btn pri-btn" to="#">Choose Plan</Link></div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Plan;

/** @format */

import React, { useEffect, useState } from "react";
import Footer from "../component/footer";
import Header from "../component/header";
import AllAdvertisements from "./HomeComponent/AllAdvertisements";
import BacktoTop from "./HomeComponent/BacktoTop"; 
import Filter from "./HomeComponent/Filter";
import HeroCrausel from "./HomeComponent/HeroCrausel";
import { Membership } from "./HomeComponent/Membership";
import OnlineService from "./HomeComponent/OnlineService";
import OurBlog from "./HomeComponent/OurBlog";
import AvailableNow from "./HomeComponent/AvailableNow";
import Testimonial from "./HomeComponent/Testimonial";
import { TopFooter } from "./HomeComponent/TopFooter";
import AgeConfirmation from "./AgeConfirmation/AgeConfirmation";
import { useSelector } from "react-redux";

const Home = () => {
  const isVisible = useSelector((s) => s.is_age_confirmed);

  return (
    <>
      <AgeConfirmation />
      <BacktoTop />
      <Header />
      <HeroCrausel />
      <Filter />
      <AvailableNow />
      <AllAdvertisements />
      <OnlineService />
      <OurBlog />                           
      <Testimonial /> 
      <Membership />
      <TopFooter />
      <Footer />
    </>
  );
};

export default Home;

/** @format */

import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { isLoader, isToaster } from "../../actions"
import axios from "axios"

const UpdatePasswordAdmin = () => {
  let dispatch = useDispatch()
  let token = localStorage.getItem("userToken")
  const [error, setError] = useState("")
  let navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    password: "",
    cpassword: "",
    currentPassword: "",
  })
  const showPassword = (a) => {
    var x = document.getElementById(a)
    if (x.type === "password") {
      x.type = "text"
    }
    setTimeout(() => {
      x.type = "password"
    }, 800)
  }
  let name, Value
  const handleInputs = (e) => {
    setError("")
    name = e.target.name
    Value = e.target.value
    setData({ ...data, [name]: Value })
  }

  const onSubmit = async () => {
    if (data.password !== data.cpassword) {
      setError("Password and confirm Password should match")
    } else {
      setError("")
      // dispatch(isLoader(true))
      const customConfig1 = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      let postdata = {
        old_password: data.currentPassword,
        new_password: data.password,
        new_password_confirmation: data.cpassword,
      }
      setLoading(true)
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/update-password-admin`,
          postdata,
          customConfig1
        )
        .then((res) => {
          setLoading(false)
          // dispatch(isLoader(false))
          navigate("/admin/myAccount")
          console.log(res)
          dispatch(
            isToaster({ flag: true, msg: "password changed Successfully!" })
          )
        })
        .catch((err) => {
          setLoading(false)
          // dispatch(isLoader(false))
          console.log("response status: ", err.response.data.message)
          setError(err.response.data.message)
        })
    }
  }
  return (
    <>
      <section className="addash">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex align-items-center">
                <h4>Change Password</h4>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="d-flex justify-content-center">
              <div className="col-lg-5 passwordsec">
                <div
                  className={`${
                    error ? "d-block" : "d-none"
                  } text-center -mb-3`}
                >
                  {error}
                </div>
                <form>
                  <div className="passwordPage">
                    <div className="input-label">
                      <label className="mb-1">
                        <span>Current Password</span>
                      </label>
                      <input
                        type="password"
                        name="currentPassword"
                        id="currentPassword"
                        value={data.currentPassword}
                        onChange={handleInputs}
                        // placeholder="Current Password"
                        className="form-control form-control-ctm "
                      />
                      <span
                        className="pwdeye"
                        onClick={() => showPassword("currentPassword")}
                      >
                        <i className="fa fa-eye"></i>
                        {/* <i className="fa fa-eye-slash"></i> */}
                      </span>
                    </div>
                  </div>
                  <div className="passwordPage mt-3">
                    <div className="input-label">
                      <label className="mb-1">
                        {" "}
                        <span>New Password</span>{" "}
                      </label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        value={data.password}
                        onChange={handleInputs}
                        // placeholder="New Password"
                        className="form-control form-control-ctm "
                      />
                      <span
                        className="pwdeye"
                        onClick={() => showPassword("password")}
                      >
                        <i className="fa fa-eye"></i>
                        {/* <i className="fa fa-eye-slash"></i> */}
                      </span>
                    </div>
                  </div>
                  <div className="passwordPage mt-3">
                    <div className="input-label">
                      <label className="mb-1">
                        {" "}
                        <span>Confirm Password</span>{" "}
                      </label>
                      <input
                        type="password"
                        name="cpassword"
                        id="cpassword"
                        value={data.cpassword}
                        onChange={handleInputs}
                        // placeholder="Confirm Password"
                        className="form-control form-control-ctm "
                      />
                      <span
                        className="pwdeye"
                        onClick={() => showPassword("cpassword")}
                      >
                        <i className="fa fa-eye"></i>
                        {/* <i className="fa fa-eye-slash"></i> */}
                      </span>
                    </div>
                  </div>
                  <div className="ratebtn mt-4">
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn pri-btn"
                        onClick={onSubmit}
                      >
                        Change Password&nbsp;
                        {loading ? (
                          <span
                            className="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : null}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default UpdatePasswordAdmin

/** @format */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { adData, isToaster } from "../../../actions";
import { useNavigate } from "react-router-dom";
import $ from "jquery"
import {
  GetCountries,
  GetState,
  GetCity,
  // GetLanguages, //async functions
} from "react-country-state-city";

const CreateAds = () => {
  const [countryid, setCountryid] = useState(-1);
  const [stateid, setStateid] = useState(-1);
  const [cityid, setCityid] = useState(-1);

  const [countryindex, setCountryindex] = useState();
  const [stateindex, setStateindex] = useState();
  const [cityindex, setCityindex] = useState();

  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let token = localStorage.getItem("userToken");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [adData, setAdData] = useState({
    adname: "",
    phone: "",
    email: "",
    about_me: "",
    date: "",
    location: "",
    mileradius: "",
    state: "",
    country: "",
    pricelist: [],
  });

  const [price, setPrice] = useState({
    hr: "",
    min: "",
    amount: "",
  });

  let name, Value;

  const handleInputs = (e) => {
    setError("");
    name = e.target.name;
    Value = e.target.value;
    setAdData({ ...adData, [name]: Value });
  };

  const handlePriceList = (e) => {
    setError("");
    name = e.target.name;
    Value = e.target.value;
    setPrice({ ...price, [name]: Value });
  };

  const onSave = async () => {
    // console.log(adData);
    const customConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const customConfig1 = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    // Safely access the properties of the arrays using optional chaining
    const locationName = cityList.find((x) => x.id == cityid)?.name || "";
    const stateName = stateList.find((x) => x.id == stateid)?.name || "";
    const countryName =
      countriesList.find((x) => x.id == countryid)?.name || "";

    let postData = {
      ad_name: adData.adname,
      phone: adData.phone,
      email: adData.email,
      about_me: adData.about_me,
      publish_date: adData.date,
      location: locationName,
      mile_radius: adData.mileradius,
      state: stateName,
      country: countryName,
      duration_price: adData.pricelist.map((x) => x),
    };

    // Validation checks
    if (!postData.ad_name) {
      dispatch(isToaster({ flag: true, msg: "Please enter Ad name" }));
    } else if (price.hr != "" || price.min != "" || price.amount != "") {
      dispatch(
        isToaster({
          flag: true,
          msg: "Please add the entered Price details by clicking add button ",
        })
      );
    } else if (!postData.phone) {
      dispatch(isToaster({ flag: true, msg: "Please enter Phone" }));
    } else if (postData.phone.length !== 10) {
      dispatch(
        isToaster({ flag: true, msg: "Please enter a valid Phone Number" })
      );
    } else if (!postData.email) {
      dispatch(isToaster({ flag: true, msg: "Please enter Email" }));
    } else if (!postData.about_me) {
      dispatch(isToaster({ flag: true, msg: "Please enter About me" }));
    } else if (!postData.publish_date) {
      dispatch(isToaster({ flag: true, msg: "Please enter Publish date" }));
    } else if (!postData.location) {
      dispatch(isToaster({ flag: true, msg: "Please enter location" }));
    } else if (!postData.mile_radius) {
      dispatch(isToaster({ flag: true, msg: "Please enter Mile radius" }));
    } else if (!postData.state) {
      dispatch(isToaster({ flag: true, msg: "Please enter State" }));
    } else if (!postData.country) {
      dispatch(isToaster({ flag: true, msg: "Please enter Country" }));
    } else if (postData.duration_price.length === 0) {
      dispatch(isToaster({ flag: true, msg: "Please add Price list" }));
    } else {
      setLoading(true);
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/create-advertisement`,
          postData,
          customConfig
        );
        setLoading(false);
        dispatch(isToaster({ flag: true, msg: "Ad created Successfully!" }));
        // console.log("create Ads data : ", res);
        // localStorage.setItem("selectedAdName", res.data.newAdId);
        navigate("/advertiser/activeAds");

        try {
          const Res = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/get-advertisements`,
            customConfig1
          );
          dispatch(adData(Res.data.allAds.data));
          // console.log("get Ads data : ", Res);
        } catch (err) {
          // console.log(err);
        }
      } catch (err) {
        setLoading(false);
        // console.log(err);
      }
    }
  };

  const addPriceList = () => {
    if (price.hr || price.min) {
      if (price.amount) {
        const d = new Date();
        let time = Math.round(d.getTime());
        price.id = time;
        // console.log("object before push in array: ", price);
        setAdData({
          ...adData,
          pricelist: [
            ...adData.pricelist,
            {
              hour: price.hr,
              min: price.min,
              price: price.amount,
              id: price.id,
            },
          ],
        });
        setPrice({
          hr: "",
          min: "",
          amount: "",
        });
        // console.log("ad data from add price button: ", adData);
      }
    }
  };

  const deletePriceEntry = (Id) => {
    // console.log("id to delete: ", Id);
    // console.log(adData);
    let arr = adData.pricelist.filter((x) => x.id !== Id);
    setAdData({ ...adData, pricelist: [...arr] });
  };

  // console.log("Country list: ", countriesList);

  useEffect(() => {
    GetCountries()
      .then((result) => {
        setCountriesList(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const validate = () => {
    const $result = $("#result");
    const email = $("#email").val();
    $result.text("");

    if (validateEmail(email)) {
      $result.text("");
    } else {
      $result.text("Invalid Email.");
      $result.css("display", "block");
      $result.addClass("underline_label")
    }
    return false;
  };

  $("#email").on("input", validate);

  useEffect(() => {
    // Fetch states when countryid changes
    if (countryid !== -1) {
      GetState(countryid)
        .then((result) => {
          // console.log("stateList: ", result)
          setStateList(result);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [countryid]);

  useEffect(() => {
    // Fetch cities when both countryid and stateid change
    if (countryid !== -1 && stateid !== -1) {
      GetCity(countryid, stateid).then((result) => {
        // console.log("CityList: ", result)
        setCityList(result);
      });
    }
  }, [countryid, stateid]);

  return (
    <>
      <section className="addash">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex align-items-center">
                <h4>Create Ads</h4>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="addprofile">
              <div className="row input-label">
                <div
                  className={`${error ? "d-block" : "d-none"} text-center mb-2`}
                >
                  {error}
                </div>
                <div className="col-lg-6 col-md-6">
                  <label className="mb-1">
                    {" "}
                    <span>Ad Name</span>{" "}
                  </label>
                  <input
                    value={adData.adname}
                    name="adname"
                    onChange={handleInputs}
                    type="text"
                    // placeholder="Ad Name"
                    className="form-control form-control-ctm mb-2"
                  />
                </div>

                <div className="col-lg-6 col-md-6">
                  <label className="mb-1">
                    {" "}
                    <span>Phone Number</span>{" "}
                  </label>
                  <input
                    value={adData.phone}
                    name="phone"
                    onChange={(e) => {
                      handleInputs(e);
                    }}
                    type="number"
                    // placeholder="Phone Number"
                    className="form-control form-control-ctm mb-2"
                  />
                  <div
                    className={`underline_label ${
                      adData.phone.length != 10 && adData.phone.length != 0
                        ? null
                        : "d-none"
                    }`}
                  >{`Invalid Phone Number`}</div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <label className="mb-1">
                    {" "}
                    <span>Email</span>{" "}
                  </label>
                  <input
                    value={adData.email}
                    id="email"
                    name="email"
                    onChange={handleInputs}
                    type="email"
                    // placeholder="Email"
                    className="form-control form-control-ctm mb-2"
                  />
                  <span id="result"></span>
                </div>

                <div className="col-lg-6 col-md-6">
                  <label className="mb-1">
                    {" "}
                    <span>Publish Date</span>{" "}
                  </label>
                  <input
                    value={adData.date}
                    name="date"
                    min={new Date().toISOString().split("T")[0]}
                    onChange={handleInputs}
                    type="date"
                    className="form-control form-control-ctm mb-2"
                  />
                </div>

                <div className="col-lg-12 col-md-12">
                  <label className="mb-1">
                    {" "}
                    <span>About Me</span>{" "}
                  </label>
                  <textarea
                    id="about_me"
                    maxlength="150"
                    name="about_me"
                    rows="4"
                    cols="50"
                    value={adData.about_me}
                    onChange={handleInputs}
                    type="text"
                    // placeholder="About Me"
                    className="form-control form-control-ctm"
                  ></textarea>
                  <div
                    className={`underline_label ${
                      adData.about_me.length <= 0 ? "d-none" : null
                    }`}
                  >{`Remaining letters: ${150 - adData.about_me.length}`}</div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-12">
                  <h6>Target Location </h6>
                </div>
              </div>

              <div className="row input-label mt-2">
                <div className="col-lg-6 col-md-6">
                  <label className="mb-1">
                    <span>Country</span>
                  </label>
                  <select
                    className="form-control form-select-ctm mb-2"
                    aria-label="select"
                    // value={adData.country}
                    name="country"
                    // onChange={handleInputs}
                    onChange={(e) => {
                      const country = countriesList[e.target.value];
                      // console.log("country", country) //here you will get full country object.
                      setCountryid(country.id);
                      // GetState(country.id).then((result) => {
                      //   setStateList(result);
                      // });
                    }}
                    value={countryindex}
                    // placeholder="Country"
                  >
                    <option selected></option>
                    {countriesList.map((item, index) => (
                      <option key={index} value={index}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {/* <input
                    type="text"
                    value={adData.country}
                    name="country"
                    onChange={handleInputs}
                    placeholder="Country"
                    className="form-control form-control-ctm"
                  /> */}
                </div>
                <div className="col-lg-6 col-md-6">
                  <label className="mb-1">
                    <span>State</span>
                  </label>
                  <select
                    className="form-control form-select-ctm mb-2"
                    aria-label="select"
                    // value={adData.country}
                    name="state"
                    // onChange={handleInputs}
                    onChange={(e) => {
                      const state = stateList[e.target.value];
                      // console.log("state", state) //here you will get full state object.
                      setStateid(state.id);
                      // GetCity(countryid, state.id).then((result) => {
                      //   setCityList(result);
                      // });
                    }}
                    value={stateindex}
                    // placeholder="State"
                  >
                    <option selected></option>
                    {stateList.map((item, index) => (
                      <option key={index} value={index}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {/* <input
                    type="text"
                    value={adData.state}
                    name="state"
                    onChange={handleInputs}
                    placeholder="State"
                    className="form-control form-control-ctm"
                  /> */}
                </div>
              </div>

              <div className="row input-label ">
                <div className="col-lg-6 col-md-6">
                  <label className="mb-1">
                    {" "}
                    <span>City</span>{" "}
                  </label>
                  <select
                    className="form-control form-select-ctm mb-2"
                    aria-label="select"
                    // value={adData.country}
                    name="city"
                    // onChange={handleInputs}
                    onChange={(e) => {
                      const city = cityList[e.target.value];
                      // console.log("city", city) //here you will get full city object.
                      setCityid(city.id);
                    }}
                    value={cityindex}
                    // placeholder="City"
                  >
                    <option selected></option>
                    {cityList.map((item, index) => (
                      <option key={index} value={index}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {/* <input
                    type="text"
                    value={adData.location}
                    name="location"
                    onChange={handleInputs}
                    placeholder="City"
                    className="form-control form-control-ctm"
                  /> */}
                </div>

                <div className="col-lg-6 col-md-6">
                  <label className="mb-1">
                    {" "}
                    <span>Mile Radius</span>{" "}
                  </label>
                  <input
                    type="number"
                    aria-label="select"
                    value={adData.mileradius}
                    name="mileradius"
                    onChange={handleInputs}
                    // placeholder="Mile Radius"
                    className="form-control form-control-ctm mb-2"
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-12">
                  <h6>Price</h6>
                </div>
              </div>

              {adData.pricelist.map((x) => {
                return (
                  <div className="row input-label position-relative mb-2">
                    <div className="col-lg-6 col-md-6">
                      <div className="row">
                        <div className="col-lg-9 col-md-9">
                          <label className="mb-1">
                            {" "}
                            <span>Duration</span>{" "}
                          </label>
                          <span className="d-flex justify-content-between ">
                            <select
                              className="form-select-ctm pointer_d"
                              onChange={handlePriceList}
                              name="hr"
                              disabled
                              value={x.hour}
                              aria-label="select"
                            >
                              <option selected value="00">
                                00 Hour
                              </option>
                              <option value="01">01 Hour</option>
                              <option value="02">02 Hour</option>
                              <option value="03">03 Hour</option>
                              <option value="04">04 Hour</option>
                              <option value="05">05 Hour</option>
                              <option value="06">06 Hour</option>
                              <option value="07">07 Hour</option>
                              <option value="08">08 Hour</option>
                            </select>
                            <select
                              className="form-select-ctm add-row-duration pointer_d"
                              aria-label="select"
                              onChange={handlePriceList}
                              name="min"
                              disabled
                              value={x.min}
                            >
                              <option selected value="00">
                                00 Minutes
                              </option>
                              <option value="15">15 Minutes</option>
                              <option value="30">30 Minutes</option>
                              <option value="45">45 Minutes</option>
                            </select>
                          </span>
                        </div>
                        <div className="col-lg-3 col-lg-3"></div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="row">
                        <div className="col-lg-9 col-md-9 col-sm-9">
                          <div className="add-row-width">
                            <label className="mb-1">
                              {" "}
                              <span>Price in USD</span>{" "}
                            </label>
                            <input
                              type="text"
                              // placeholder="Amount"
                              disabled
                              onChange={handlePriceList}
                              name="amount"
                              value={x.price}
                              className="form-control form-control-ctm pointer_d"
                            />
                          </div>
                        </div>
                        <div
                          className="col-lg-3 col-md-3 col-sm-3"
                          onClick={() => deletePriceEntry(x.id)}
                        >
                          <span className="add-row-btn">
                            <i className="fa  fa-minus"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="row input-label position-relative">
                <div className="col-lg-6 col-md-6">
                  <div className="row">
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                      <div className="add-row-width">
                        <label className="mb-1">
                          {" "}
                          <span>Duration</span>{" "}
                        </label>
                        <span className="d-flex justify-content-between">
                          <select
                            className="form-select-ctm"
                            onChange={handlePriceList}
                            name="hr"
                            value={price.hr}
                            aria-label="select"
                          >
                            <option selected value="00">
                              00 Hour
                            </option>
                            <option value="01">01 Hour</option>
                            <option value="02">02 Hour</option>
                            <option value="03">03 Hour</option>
                            <option value="04">04 Hour</option>
                            <option value="05">05 Hour</option>
                            <option value="06">06 Hour</option>
                            <option value="07">07 Hour</option>
                            <option value="08">08 Hour</option>
                          </select>
                          <select
                            className="form-select-ctm add-row-duration"
                            aria-label="select"
                            onChange={handlePriceList}
                            name="min"
                            value={price.min}
                          >
                            <option selected value="00">
                              00 Minutes
                            </option>
                            <option value="15">15 Minutes</option>
                            <option value="30">30 Minutes</option>
                            <option value="45">45 Minutes</option>
                          </select>
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-lg-3 col-sm-3 col-xs-3"></div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="row">
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                      <div className="add-row-width">
                        <label className="mb-1">
                          <span>Price in USD</span>
                        </label>
                        <input
                          type="number"
                          // placeholder="Amount"
                          onChange={handlePriceList}
                          name="amount"
                          value={price.amount}
                          className="form-control form-control-ctm"
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-3 col-md-3 col-sm-3 col-xs-3"
                      onClick={addPriceList}
                    >
                      <span className="add-row-btn">
                        <i className="fa-regular fa-plus"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-12 p-0">
              <div className="float-end">
                <button
                  type="button"
                  className="btn pri-btn"
                  onClick={onSave}
                  disabled={loading}
                >
                  Save & Finish&nbsp;
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : null}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateAds;

import React, { useEffect, useState } from "react";
import Header from "../../component/header";
import Footer from "../../component/footer";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { isLoader, isToaster } from "../../actions";
import { useDispatch } from "react-redux";
import ConfettiExplosion from "react-confetti-explosion";

const Payment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [plans, setPlans] = useState("");
  const [coupon, setCoupon] = useState("");
  const [loading, setLoading] = useState(false);
  const [couponLoading, setCouponLoading] = useState(false);
  const signupDetails = localStorage.getItem("accountToCreate");
  const [isExploding, setIsExploding] = React.useState(false);
  const [dimensions, setDimensions] = useState({ width: 80, height: 0 });
  const [isCouponApplied, setIsCouponApplied] = useState({
    flag: false,
    code: "",
    amount: 0,
  });

  const onCreateAccount = () => {
    const postData = JSON.parse(signupDetails);
    postData.plan = selectedPlan.type;
    const customConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/register`,
        postData,
        customConfig
      )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          navigate("/login");
          dispatch(
            isToaster({
              flag: true,
              msg: `Hi ${postData.firstname}, Your account is created Successfully!`,
            })
          );
          localStorage.removeItem("accountToCreate")
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        setLoading(false);
        dispatch(isToaster({ flag: true, msg: err.response.data.message }));
      });
  };

  const haveCoupon = () => {
    $("#js-promo-box").removeClass("d-none");
  };

  const getPlans = () => {
    dispatch(isLoader(true));
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/get-plans`)
      .then((Res) => {
        dispatch(isLoader(false));
        setPlans(Res.data.plans);
        setSelectedPlan(Res.data.plans[0]);
        // dispatch(adData(Res.data.allAds.data));
        console.log("plans : ", Res);
      })
      .catch((err) => {
        dispatch(isLoader(false));
        // console.log(err);
      });
  };

  const createFreeAccount = () => {
    const postData = JSON.parse(signupDetails);
    postData.plan = selectedPlan.type;
    localStorage.setItem("accountToCreate", JSON.stringify(postData))
    navigate(`/signup?step=4&success=0`)
  }

  const proceedToPay = async () => {
    const postData = JSON.parse(signupDetails);
    postData.plan = selectedPlan.type;
    localStorage.setItem("accountToCreate", JSON.stringify(postData))
    try {
      const response = await fetch("https://api.oxapay.com/merchants/request", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          merchant: "sandbox",
          amount: selectedPlan.amount - isCouponApplied.amount,
          currency: "USD",
          email: postData.email,
          lifeTime: 15,
          feePaidByPayer: 0,
          underPaidCover: 0,
          returnUrl: process.env.REACT_APP_HOME_URL,
          description: `${selectedPlan}`,
          orderId: "ORD-12345",
          callbackUrl: "https://example.com/plan",
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const responseData = await response.json();
      if (responseData && responseData.payLink) {
        console.log(responseData.payLink);
        window.location.href = responseData.payLink;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const updateDimensions = () => {
      setDimensions({
        width: window.innerWidth / 2,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", updateDimensions);
    updateDimensions();

    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    if(!localStorage.getItem("accountToCreate")){
       navigate("/")
    }
    getPlans();
  }, []);

  const checkCoupon = () => {
    setCouponLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/check-coupon/${coupon}`)
      .then((res) => {
        setCouponLoading(false);
        if (typeof res.data.coupons === "object") {
          if (res.data.coupons.plan === selectedPlan.type) {
            setIsExploding(true);
            let discount =
              res.data.coupons.discount_type === "fixed"
                ? res.data.coupons.amount
                : (selectedPlan.amount / 100) * res.data.coupons.amount;
            setIsCouponApplied({
              flag: true,
              code: res.data.coupons.coupon_code,
              amount: discount,
            });
          } else {
            dispatch(
              isToaster({ flag: true, msg: "No matching coupon found." })
            );
          }
        } else {
          dispatch(isToaster({ flag: true, msg: res.data.coupons }));
          setIsCouponApplied({ flag: false, code: "", amount: 0 });
        }
      })
      .catch((err) => {
        setCouponLoading(false);
        setIsCouponApplied({ flag: false, code: "", amount: 0 });
        console.log(err);
      });
  };

  return (
    <>
      <Header />
      <section id="payment-page-signup">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="singinform">
                <h4>
                  CHOOSE A <span>PLAN</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-3">
          <div className="row plan-info-row">
            <div className="col-lg-8 col-md-7">
              <ul
                className="nav nav-pills s-payment-section"
                id="pills-tab"
                role="tablist"
              >
                {plans
                  ? plans.map((x) => {
                      return (
                        <li
                          className="nav-item"
                          role="presentation"
                          onClick={() => {
                            setSelectedPlan(x);
                            setIsCouponApplied({
                              flag: false,
                              code: "",
                              amount: 0,
                            });
                            setCoupon("");
                          }}
                        >
                          <p
                            className={`nav-link position-relative s-payment-op1 m-0 rounded ${
                              x.type === "Premium" ? "active" : null
                            }`}
                            id="pills-free-tab"
                            data-toggle="pill"
                            data-target="#pills-free"
                            type="button"
                            role="tab"
                            aria-controls="pills-free"
                            aria-selected="true"
                          >
                            <div className="payment-type s-single_price_header">
                              <div className="types ">
                                <div className="text">
                                  <h4>{x.type}</h4>
                                </div>
                                <div class="price">
                                  <div>
                                    <h4 className="m-0">
                                      {x.amount ? x.amount : 0} USD
                                    </h4>
                                    <span>/ Month</span>
                                  </div>
                                  <div class="line"></div>
                                </div>
                              </div>
                            </div>
                          </p>
                        </li>
                      );
                    })
                  : null}
              </ul>
            </div>
            <div className="col-lg-4 col-md-5">
              <div
                className="tab-content s-payment-info-section"
                id="pills-tabContent"
              >
                <div
                  className={`tab-pane fade show active ${
                    selectedPlan.length !== 0 ? null : "d-none"
                  }`}
                  id="pills-free"
                  role="tabpanel"
                  aria-labelledby="pills-free-tab"
                >
                  <div className="s-payment-info-title">
                    <h4>{selectedPlan.type} plan Information</h4>
                  </div>
                  <div className="plan-description">
                    <p>
                      <i className="fa-regular fa-circle-check"></i>Add{" "}
                      {selectedPlan.total_photos} Photos
                    </p>
                    <p>
                      <i className="fa-regular fa-circle-check"></i>Add{" "}
                      {selectedPlan.total_videos} Videos
                    </p>
                    <p>
                      <i className="fa-regular fa-circle-xmark lni-close"></i>No
                      Hidden Fees
                    </p>
                    <p>
                      <i className="fa-regular fa-circle-xmark lni-close"></i>No
                      Hidden Fees
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row billing-detail-row">
            <div className="col-lg-8">
              <div
                className={`mt-4 ${
                  selectedPlan.type === "Free" ? "d-none" : null
                }`}
              >
                <div className="w-100 mt-4 ">
                  <div className="coupon-code mx-auto">
                    <div className="promotion_code">
                      <p
                        id="js-apply-promo"
                        className="m-1"
                        onClick={haveCoupon}
                      >
                        <i class="fa fa-ticket"></i> I have a promo code
                      </p>
                      <div id="js-promo-box" class="promo-box d-none">
                        <input
                          type="text"
                          disabled={isCouponApplied.flag}
                          name="coupon"
                          value={coupon}
                          onChange={(e) => setCoupon(e.target.value)}
                          placeholder="Enter promo code"
                          className="form-control form-control-ctm "
                        />
                        <button
                          className={`btn cus-btn ${
                            isCouponApplied.flag ? "d-none" : "d-block"
                          }`}
                          disabled={couponLoading}
                          onClick={checkCoupon}
                        >
                          Apply &nbsp;
                          {couponLoading ? (
                            <span
                              className="spinner-border spinner-border-sm me-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : null}
                        </button>
                        <i
                          class={`fa-regular fa-circle-check ${
                            isCouponApplied.flag ? "d-block" : "d-none"
                          }`}
                        ></i>
                        {isExploding && (
                          <ConfettiExplosion
                            style={{
                              zIndex: "9",
                            }}
                            colors={[
                              "#C01F25",
                              "#C01F25",
                              "#FFC700",
                              "#FF0000",
                              "#2E3191",
                              "#41BBC7",
                            ]}
                            onComplete={() => setIsExploding(false)}
                            force={0.4}
                            duration={2200}
                            particleCount={200}
                            width={1000}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="biling-detail-bd mt-4">
                  <div className="billing-detail">
                    <h4 className="text-center ">
                      <span>Billing</span> Detail
                    </h4>

                    <div className="billing-detail-list">
                      <div className="d-flex justify-content-between">
                        <div>Your Plan</div>
                        <div className="biling-amount">{selectedPlan.type}</div>
                      </div>
                    </div>

                    <div className="billing-detail-list">
                      <div className="d-flex justify-content-between">
                        <div>Prize</div>
                        <div className="biling-amount">
                          {selectedPlan.amount} USD
                        </div>
                      </div>
                    </div>

                    <div
                      className={`billing-detail-list ${
                        isCouponApplied.flag ? null : "d-none"
                      }`}
                    >
                      <div className="d-flex justify-content-between">
                        <div>Coupon ({isCouponApplied.code}) applied</div>
                        <div className="biling-amount">
                          -{isCouponApplied.amount} USD
                        </div>
                      </div>
                    </div>

                    <div className="billing-detail-list">
                      <div className="d-flex justify-content-between">
                        <div>To Pay</div>
                        <div className="biling-amount">
                          {selectedPlan.amount - isCouponApplied.amount} USD
                        </div>
                      </div>
                    </div>

                    <div className="mt-3 text-center">
                      <button
                        type="submit"
                        className="btn cus-btn"
                        onClick={proceedToPay}
                      >
                        Proceed to Pay
                      </button>{" "}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`mt-5 text-center  ${
                  selectedPlan.type !== "Free" ? "d-none" : null
                }`}
              >
                
                  {" "}
                  <button
                    type="submit"
                    className="btn cus-btn"
                    disabled={loading}
                    onClick={createFreeAccount}
                    // onClick={onCreateAccount}
                  >
                    Create Account &nbsp;
                    {loading ? (
                      <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : null}
                  </button>{" "}
                
              </div>
            </div>
            <div className="col-lg-4"></div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Payment;

import React from "react";
import { Link } from "react-router-dom";

const AboutApplysec = () => {
  return (
    <>
      <section className="spacing-page apply-model">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="apply-model-text">
                <h4>Intertested in Exciting Experiences?</h4>
                <p>
                  Become the next shining star with Quick Visit! Your journey as
                  a Quick Visit angel begins here. Start your adventure now by
                  applying below! 🌟🚀
                </p>
                <p>
                   <Link to="/signup">
                   <button type="button" className="btn sub-btn">Apply Now</button>
                    </Link> 
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutApplysec;

import React, { useEffect } from 'react'
import FAQsBanner from './Faqs/FaqsBanner';
import Header from '../component/header';
import Footer from '../component/footer';
import FaqsAccordian from './Faqs/FaqsAccordian';
import { Membership } from './HomeComponent/Membership';
import { TopFooter } from './HomeComponent/TopFooter';
import BacktoTop from './HomeComponent/BacktoTop';

const Faqs = () => {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);


  return (
     <>
     <BacktoTop />
    <Header />
    <FAQsBanner />
<FaqsAccordian />
<Membership />
     <TopFooter />
     <Footer />
     </>
  )
}

export default Faqs;
/** @format */

import React, { useState } from "react";
import {
  adData,
  advertiserData,
  allAdvertiser,
  coupons,
  couponsList,
  idToDelete,
  isLoader,
  isToaster,
  promotedZone,
} from "../../actions";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const DeleteImage = (props) => {
  let ID = useSelector((s) => s.id_to_delete);
  let token = localStorage.getItem("userToken");
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState("");
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const onDelete = async () => {
    if (props.type === "Advertisement") {
      setLoading(true);
      await axios
        .delete(
          `${process.env.REACT_APP_API_URL}/api/delete-advertisement-by-admin/${ID}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setLoading(false);
          dispatch(idToDelete(""));
          navigate("/admin/trashAds");
          dispatch(
            isToaster({
              flag: true,
              msg: `${props.type} deleted Successfully!`,
            })
          );
          // console.log(res)
        })
        .catch((err) => {
          setLoading(false);
          dispatch(idToDelete(""));
          dispatch(isToaster({ flag: true, msg: "Something went wrong!" }));
          // console.log(err)
        });
      dispatch(isLoader(true));
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/get-trashed-advertisements?page=1`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          dispatch(isLoader(false));
          // setData(res.data.trashedAds)
          dispatch(adData(res.data.trashedAds.data));
          console.log("Get all Adevertisers : ", res.data.trashedAds);
        })
        .catch((err) => {
          dispatch(isLoader(false));
          console.log(err);
        });
    } else if (props.type === "Advertiser") {
      if (confirm === "CONFIRM") {
        setLoading(true);
        await axios
          .delete(
            `${process.env.REACT_APP_API_URL}/api/delete-user-permanently/${ID}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            setLoading(false);
            dispatch(idToDelete(""));
            dispatch(isLoader(true));
            axios
              .get(`${process.env.REACT_APP_API_URL}/api/get-all-advertisers?searchText=&page=` + `${props.page}`, {
                headers: { Authorization: `Bearer ${token}` },
              })
              .then((res) => {
                dispatch(isLoader(false));
                dispatch(allAdvertiser(res.data.advertisers.data));
                console.log("Get all Advertisers : ", res.data.advertisers);
              })
              .catch((err) => {
                dispatch(isLoader(false));
                console.log(err);
              });
            navigate("/admin/alladvertiser");
            dispatch(
              isToaster({
                flag: true,
                msg: `${props.type} deleted Successfully!`,
              })
            );
            // console.log(res)
          })
          .catch((err) => {
            setLoading(false);
            dispatch(idToDelete(""));
            dispatch(isToaster({ flag: true, msg: "Something went wrong!" }));
            // console.log(err)
          });
      } else {
        dispatch(
          isToaster({
            flag: true,
            msg: `Please type "CONFIRM" to delete the ${props.type}`,
          })
        );
      }
    } else if (props.type === "User") {
      if (confirm === "CONFIRM") {
        setLoading(true);
        await axios
          .delete(
            `${process.env.REACT_APP_API_URL}/api/delete-user-permanently/${ID}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            setLoading(false);
            dispatch(idToDelete(""));
            navigate("/admin/user");
            dispatch(
              isToaster({
                flag: true,
                msg: `${props.type} deleted Successfully!`,
              })
            );
          })
          .catch((err) => {
            setLoading(false);
            dispatch(idToDelete(""));
            dispatch(isToaster({ flag: true, msg: "Something went wrong!" }));
            // console.log(err)
          });
      } else {
        dispatch(
          isToaster({
            flag: true,
            msg: `Please type "CONFIRM" to delete the ${props.type}`,
          })
        );
      }
    } else if (props.type === "Coupon") {
      if (confirm === "CONFIRM") {
        setLoading(true);
        await axios
          .delete(`${process.env.REACT_APP_API_URL}/api/delete-coupon/${ID}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            setLoading(false);
            dispatch(isLoader(true));
            axios
              .get(`${process.env.REACT_APP_API_URL}/api/get-coupons`, {
                headers: { Authorization: `Bearer ${token}` },
              })
              .then((res) => {
                dispatch(isLoader(false));
                dispatch(couponsList(res.data.coupons));
              })
              .catch((err) => {
                dispatch(isLoader(false));
              });
            dispatch(idToDelete(""));
            navigate("/admin/Coupon");
            dispatch(
              isToaster({
                flag: true,
                msg: `${props.type} deleted Successfully!`,
              })
            );
            // console.log(res)
          })
          .catch((err) => {
            setLoading(false);
            dispatch(idToDelete(""));
            dispatch(isToaster({ flag: true, msg: "Something went wrong!" }));
            // console.log(err)
          });
      } else {
        dispatch(
          isToaster({
            flag: true,
            msg: `Please type "CONFIRM" to delete the ${props.type}`,
          })
        );
      }
    } else if (props.type === "Promoted Zone") {
      if (confirm === "CONFIRM") {
        setLoading(true);
        await axios
          .delete(
            `${process.env.REACT_APP_API_URL}/api/delete-promoting-zone/${ID}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            setLoading(false);
            dispatch(isLoader(true));
            dispatch(isLoader(true));
            axios
              .get(`${process.env.REACT_APP_API_URL}/api/get-promoting-zones`, {
                headers: { Authorization: `Bearer ${token}` },
              })
              .then((res) => {
                dispatch(isLoader(false));
                dispatch(promotedZone(res.data.promotingZones));
              })
              .catch((err) => {
                dispatch(isLoader(false));
              });
            dispatch(idToDelete(""));
            navigate("/admin/promotezone");
            dispatch(
              isToaster({
                flag: true,
                msg: `${props.type} deleted Successfully!`,
              })
            );
            // console.log(res)
          })
          .catch((err) => {
            setLoading(false);
            dispatch(idToDelete(""));
            dispatch(isToaster({ flag: true, msg: "Something went wrong!" }));
            // console.log(err)
          });
      } else {
        dispatch(
          isToaster({
            flag: true,
            msg: `Please type "CONFIRM" to delete the ${props.type}`,
          })
        );
      }
    }
  };

  return (
    <>
      <div
        className={`modal_container text-center m-auto ${
          ID ? "d-block" : "d-none"
        }`}
      >
        <div className="modal-dialog">
          <div className="modal-content price-table p-4">
            <div className="position-relative">
              <h2 className="modal-title fs-5 text-body" id="deletepopupLabel">
                Delete this {props.type}?
              </h2>
              <span className="position-absolute top-0 end-0">
                <i
                  className="fa-solid fa-x"
                  onClick={() => dispatch(idToDelete("")) & setConfirm("")}
                ></i>
              </span>
            </div>
            <div className="modal-body">
              <h2 className="fs-6 text-body">
                Are you sure to delete this {props.type}
              </h2>
            </div>
            <div
              className={`modal-body ${
                props.type === "Advertiser" || "User" ? "d-block" : "d-none"
              }`}
            >
              <label>
                {" "}
                <span>
                  Please type CONFIRM to Delete this {props.type}
                </span>{" "}
              </label>
              <input
                type="text"
                name="confirm"
                value={confirm}
                onChange={(e) => setConfirm(e.target.value)}
                className="form-control form-control-ctm"
              />
            </div>

            <div className="p-2">
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn cus-btn"
                  disabled={loading}
                  onClick={onDelete}
                >
                  Delete&nbsp;
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : null}
                </button>
                <button
                  type="button"
                  className="btn sec-btn ms-3"
                  data-dismiss="modal"
                  onClick={() => dispatch(idToDelete("")) & setConfirm("")}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteImage;

/** @format */

import React, { useEffect, useState } from "react"
import advertiser from "../../asset/img/userpro.png"
import model from "../../asset/img/userpro.png"
import { useSelector } from "react-redux"

const ViewProfileBanner = () => {
  const data = useSelector((s) => s.advertiser_data)

  useEffect(() => {
        // Add image to background

        document.getElementById(
          "header-image-bg"
        ).style.backgroundImage = `url(${
          data.cover_photo ? data.cover_photo : model
        })`
        document.getElementById("header-image-bg").style.backgroundColor =
          "rgba(0,0,0,0.6)"
        document.getElementById("header-image-bg").style.backgroundBlendMode =
          "darken"

        // Add image to background
  }, [data.cover_photo, model])

  return (
    <>
      <section className="header-image-bg" id="header-image-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="view-ads-profile">
                <img
                  src={data.profile_photo ? data.profile_photo : advertiser}
                  alt="Advertiser"
                  className="img-fluid model-round-profile"
                />
                <span>
                  <h4 className="model-name">{data.firstname + ' ' + data.lastname}</h4>
                  <p>Escort Model From {data.city}</p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ViewProfileBanner

/** @format */

import React from "react";
import { Link } from "react-router-dom";
import DeleteTableData from "./DeleteTableData";
import { useState } from "react";
import { useEffect } from "react";
import no_data from "../../asset/img/no_data.png";
import { adData, editAdData, isLoader } from "../../actions";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
// import data from "./adsLocationData";

const Ads_Location = () => {
  const token = localStorage.getItem("userToken");
  const [data, setData] = useState();
  const adsLocation = useSelector((s) => s.ad_data);
  const [search, setSearch] = useState("");
  const BASE_URL = `${process.env.REACT_APP_API_URL}/api/get-advertisements?searchText=${search}&page=`;
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(isLoader(true));
    const customConfig1 = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(BASE_URL, customConfig1)
      .then((Res) => {
        dispatch(isLoader(false));
        setData(Res.data.allAds);
        dispatch(adData(Res.data.allAds.data));
        // console.log("get Ads data : ", Res.data.allAds)
      })
      .catch((err) => {
        dispatch(isLoader(false));
      });
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const onSearch = () => {
    dispatch(isLoader(true));

    axios
      .get(BASE_URL, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch(isLoader(false));
        setData(res.data.allAds);
        dispatch(adData(res.data.allAds.data));
        console.log("Get all Admins : ", res.data.allAds);
      })
      .catch((err) => {
        dispatch(isLoader(false));
        console.log(err);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  const onEdit = (a) => {
    dispatch(editAdData(a));
    localStorage.setItem("adToEdit", JSON.stringify(a))
  };

  const updateData = (url) => {
    dispatch(isLoader(true));
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch(isLoader(false));
        setData(res.data.allAds);
        dispatch(adData(res.data.allAds.data));
      })
      .catch((err) => {
        dispatch(isLoader(false));
        console.log(err);
      });
  };

  return (
    <div className="row mt-5">
      <div className="col-lg-12">
        <div className="d-flex justify-content-between ads_location">
          <span>
            <h4>Ads Location</h4>
          </span>
          <span className="position-relative">
            <input
              type="text"
              placeholder="Search"
              className="form-control form-control-ctm"
              value={search}
              onChange={handleSearch}
              onKeyDown={handleKeyDown}
            />
            <i
              className="fa fa-magnifying-glass position-absolute mx-3 mt-2 top-0 end-0 fglass"
              onClick={onSearch}
            ></i>
          </span>
        </div>
      </div>
      <div className="col-lg-12 mt-3">
        <div className="dashboardtable">
          <div
            className={`table-scrollable ${
              data > 1
                ? "border-radius-n-pagination"
                : "border-radius-w-pagination"
            }`}
          >
            <table className="table table-color table-hover table-fixed-first-columns">
              <thead>
                <tr>
                  <th>Ads Name</th>
                  <th>Mile Radius</th>
                  <th>City</th>
                  <th>State</th>
                  <th>Country</th>
                  {/* <th>Action</th> */}
                </tr>
              </thead>
              <tbody>
                {Array.isArray(adsLocation) && adsLocation.length != 0 ? (
                  adsLocation.map((value, i) => {
                    return (
                      <tr>
                        <td>{value.ad_name}</td>
                        <td>{value.mile_radius}</td>
                        <td>{value.location}</td>
                        <td>{value.state}</td>
                        <td>{value.country}</td>
                        <td className="table-action">
                          <div className="d-flex justify-content-center align-items-center">
                            <span onClick={() => onEdit(value)}>
                              <Link to="/advertiser/editAds">
                                <i className="fa-regular fa-pen-to-square"></i>
                              </Link>
                            </span>
                            {/* &nbsp; &nbsp;
                            <DeleteTableData /> */}
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={5}>
                      <img
                        src={no_data}
                        alt="Description of the image"
                        width={277}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* pagination code */}

          {data ? (
            data.last_page > 1 ? (
              <div className="d-flex justify-content-center w-100 mx-auto pt-4 pb-3">
                <div
                  className="pagination-css-pre"
                  onClick={() => updateData(data.prev_page_url)}
                  disabled={data.current_page === 1}
                >
                  <span aria-hidden="true">
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </span>
                </div>
                <div className="d-flex">
                  <span className={`pagination-css-active`}>
                    {data.current_page <= data.last_page
                      ? data.current_page
                      : data.last_page}
                  </span>
                  <span
                    className={`pagination-css ${
                      data.current_page <= data.last_page - 1
                        ? "d-block"
                        : "d-none"
                    }`}
                    onClick={() =>
                      updateData(`${BASE_URL}${data.current_page + 1}`)
                    }
                  >
                    {data.current_page + 1}
                  </span>
                  <span
                    className={`pagination-css ${
                      data.current_page <= data.last_page - 2
                        ? "d-block"
                        : "d-none"
                    }`}
                    onClick={() =>
                      updateData(`${BASE_URL}${data.current_page + 2}`)
                    }
                  >
                    {data.current_page + 2}
                  </span>
                  <span className="pagination-css ">{` ... `}</span>
                  <button
                    className="pagination-css "
                    disabled={data.current_page === data.last_page}
                    onClick={() => updateData(`${BASE_URL}${data.last_page}`)}
                  >{` ${data.last_page}`}</button>
                </div>
                <div
                  className="pagination-css-next"
                  onClick={() => updateData(data.next_page_url)}
                  disabled={data.current_page === data.last_page}
                >
                  <span aria-hidden="true">
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            ) : null
          ) : null}

          {/* pagination code */}

          {/* <div className="col-lg-12 pagination-css mt-3">
            <nav aria-label="Page navigation ">
              <ul className="pagination m-0">
                <li className="page-item">
                  <Link className="page-link" to="#">
                    {" "}
                    1
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#">
                    {" "}
                    2
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#">
                    {" "}
                    3
                  </Link>
                </li>
                <li className="page-item">
                  {" "}
                  <Link className="page-link pagegap" to="#">
                    {" "}
                    ...
                  </Link>{" "}
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#">
                    12
                  </Link>
                </li>
              </ul>
            </nav>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Ads_Location;

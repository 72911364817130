/** @format */

import React, { useEffect, useState } from "react";
import { Calendar } from "react-multi-date-picker";
import { useDispatch, useSelector } from "react-redux";
import Popup from "reactjs-popup";
import ViewAvaibilityTable from "./ViewAvaibilityTable";
import DisplayAvailability from "../../advertiser/component/DisplayAvailability";
import {
  advertisementId,
  advertiserData,
  advertiserId,
  availabilityData,
  availableDays,
  isLoader,
} from "../../actions";
import axios from "axios";

const BookingButton = (props) => {
  const dispatch = useDispatch();
  const advId = props.advertiser_id;
  const advertiserID =
    useSelector((s) => s.advertiser_id) || localStorage.getItem("viewAdId");
  const advertisementID =
    useSelector((s) => s.advertisement_id) || localStorage.getItem("ad_id");

  useEffect(() => {
    if (!advertiserID) {
      const storedAdvertiserId = localStorage.getItem("viewAdId");
      if (storedAdvertiserId) {
        dispatch(advertiserId(storedAdvertiserId));
      }
    }
  }, [dispatch, advertiserID]);

  useEffect(() => {
    if (advertiserID) {
      localStorage.setItem("viewAdId", advertiserID);
      localStorage.setItem("ad_id", advertisementID);
      // getAdvertiser();
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [advertiserID]);
  return (
    <>
      <Popup
        trigger={
          <div class="hero-btn-cra-book">
            <button
              type="button"
              class={`btn cus-btn ${props.type === "hero" ? null : "w-100"}`}
              onClick={() => {
                if(props.type === "hero"){
                  dispatch(advertiserId(advId))
                }
              }}
            >
              Booking
            </button>
          </div>
        }
        modal
        nested
      >
        {(close) => (
          <div className="content booking-popup">
            {" "}
            <div className="modal-dialog">
              <div className="modal-content booking-popup-title">
                <h4>Booking Detail</h4>
              </div>
              <span className="position-absolute top-0 end-0">
                <i
                  className="fa-solid fa-x pe-auto"
                  onClick={close}
                  style={{ cursor: "pointer" }}
                ></i>
              </span>
            </div>
            <div className="modal-body mt-3">
              <p className="d-flex justify-content-between">
                {" "}
                <div>Phone Number </div>
                <div>
                  <a className="nav-link" href="tel:+1-8632168452">
                    <i class="fas fa-phone phonerotation"></i>{" "}
                    &nbsp;+1-8632168452
                  </a>
                </div>
              </p>
              <p className="d-flex justify-content-between">
                {" "}
                <div>Email </div>
                <div>
                  <a className="nav-link" href="mailto:abc@quickvisit.com">
                    <i class="fas fa-envelope"></i> &nbsp; abc@quickvisit.com
                  </a>
                </div>
              </p>
              <p
                className="d-flex justify-content-center"
                style={{ color: "#c01f25" }}
              >
                {" "}
                <div className="booking_button_availability">Availablity</div>
              </p>
              {/* <Calendar
                availableDates={
                  data
                    ? data
                    : ["2024-03-05", "2024-03-18", "2024-03-19", "2024-03-20"]
                }
                directShow={true}
              /> */}
              <DisplayAvailability />
            </div>
          </div>
        )}
      </Popup>
    </>
  );
};

export default BookingButton;

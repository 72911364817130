/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import DeleteTableData from "../../component/DeleteTableData";
import { idToDelete, isLoader } from "../../../actions";
import no_data from "../../../asset/img/no_data.png";
import { showRenewPopup } from "../../../actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { adData } from "../../../actions";
import ViewPrice from "../../component/ViewPrice";
import RenewAds from "../../component/RenewAds";
import { showPricePopup } from "../../../actions";
import { editAdData } from "../../../actions";
import { useEffect } from "react";
import axios from "axios";

const ExpiredAds = () => {
  let token = localStorage.getItem("userToken");
  const [search, setSearch] = useState("");
  const [data, setData] = useState();
  const BASE_URL = `${process.env.REACT_APP_API_URL}/api/get-expired-advertisements?searchText=${search}&page=`;
  const showPrice = useSelector((s) => s.show_price_popup);
  const showRenew = useSelector((s) => s.show_renew_popup);
  const addata = useSelector((state) => state.ad_data);

  const [viewpriceData, setViewPriceData] = useState([]);
  const [renewId, setRenewId] = useState(null);
  // let arr = [{hour: '1', min: '', price: '20'}, {hour: '2', min: '30', price: '40'}]
  let dispatch = useDispatch();
  const handlePricePopup = (a) => {
    console.log("arr data : ", a);
    dispatch(showPricePopup(true));
    setViewPriceData(a);
  };
  const onEdit = (a) => {
    dispatch(editAdData(a));
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const onSearch = () => {
    dispatch(isLoader(true));

    axios
      .get(BASE_URL, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch(isLoader(false));
        setData(res.data.expiredAds);
        dispatch(adData(res.data.expiredAds.data));
        console.log("Get all Admins : ", res.data.expiredAds);
      })
      .catch((err) => {
        dispatch(isLoader(false));
        console.log(err);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  const updateData = (url) => {
    dispatch(isLoader(true));
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch(isLoader(false));
        setData(res.data.expiredAds);
        dispatch(adData(res.data.expiredAds.data));
        console.log("Get all Admins : ", res.data.users);
      })
      .catch((err) => {
        dispatch(isLoader(false));
        console.log(err);
      });
  };

  const handleRenewPopup = (a) => {
    dispatch(showRenewPopup(true));
    setRenewId(a);
  };

  useEffect(() => {
    dispatch(isLoader(true));
    const customConfig1 = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(BASE_URL, customConfig1)
      .then((Res) => {
        dispatch(isLoader(false));
        setData(Res.data.expiredAds);
        dispatch(adData(Res.data.expiredAds.data));
        console.log("get Ads data : ", Res.data.expiredAds);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <RenewAds
        className={`${showRenew ? "d-block" : "d-none"}`}
        id={renewId}
      />
      <ViewPrice
        className={`${showPrice ? "d-block" : "d-none"}`}
        data={viewpriceData}
      />
      <DeleteTableData type="ads" />
      <section className="addash">
        <div className="container-fluid">
          <div className="row ">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between ads_location">
                <span>
                  <h4>Expired Ads</h4>
                </span>
                <span className="position-relative">
                  <input
                    type="text"
                    placeholder="Search"
                    name="search"
                    value={search}
                    onChange={handleSearch}
                    onKeyDown={handleKeyDown}
                    className="form-control form-control-ctm "
                  />
                  <i
                    className="fa fa-magnifying-glass position-absolute mx-3 mt-2 top-0 end-0 fglass pointer"
                    onClick={onSearch}
                  ></i>
                </span>
              </div>
            </div>
            <div className="col-lg-12 mt-3">
              <div className="dashboardtable">
                <div
                  className={`table-scrollable ${
                    data > 1
                      ? "border-radius-n-pagination"
                      : "border-radius-w-pagination"
                  }`}
                >
                  <table className="table table-color table-hover table-fixed-first-columns">
                    <thead>
                      <tr>
                        <th>Ads Name</th>
                        <th>Publish Date</th>
                        <th>Mile Radius</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Country</th>
                        <th>Prize</th>
                        <th>Renew Ad</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(addata) && addata.length != 0 ? (
                        addata.map((x) => {
                          return (
                            <tr key={x.id}>
                              <td>{x.ad_name}</td>
                              <td>{x.publish_date}</td>
                              <td>{x.mile_radius}</td>
                              <td>{x.location}</td>
                              <td>{x.state}</td>
                              <td>{x.country}</td>
                              <td>
                                <p
                                  type="button"
                                  className="m-0"
                                  // data-toggle="modal"
                                  // data-target="#pricepopup"
                                  onClick={() =>
                                    handlePricePopup(x.duration_price)
                                  }
                                >
                                  <button
                                    type="button"
                                    className="btn custom-btn"
                                  >
                                    View Price
                                  </button>
                                </p>
                              </td>
                              <td>
                                <p
                                  type="button"
                                  className="m-0"
                                  onClick={() => handleRenewPopup(x.id)}
                                >
                                  <button
                                    type="button"
                                    className="btn custom-btn"
                                  >
                                    Renew Ad
                                  </button>
                                </p>
                              </td>
                              <td className="table-action">
                                <div className="d-flex justify-content-center align-items-center">
                                  <span onClick={() => onEdit(x)}>
                                    <Link to="/advertiser/editAds">
                                      <i className="fa-regular fa-pen-to-square"></i>
                                    </Link>
                                  </span>
                                  <p
                                    type="button"
                                    className="my-0 mx-2"
                                    // data-toggle="modal"
                                    // data-target="#deletepopup"
                                    onClick={() => dispatch(idToDelete(x.id))}
                                  >
                                    <i className="far fa-trash-alt"></i>
                                  </p>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={9}>
                            <img
                              src={no_data}
                              alt="Description of the image"
                              width={277}
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                {/* pagination code */}

                {data ? (
                  data.last_page > 1 ? (
                    <div className="d-flex justify-content-center w-100 mx-auto pt-4 pb-3">
                      <div
                        className="pagination-css-pre"
                        onClick={() => updateData(data.prev_page_url)}
                        disabled={data.current_page === 1}
                      >
                        <span aria-hidden="true">
                          <i
                            className="fa fa-chevron-left"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className={`pagination-css-active`}>
                          {data.current_page <= data.last_page
                            ? data.current_page
                            : data.last_page}
                        </span>
                        <span
                          className={`pagination-css ${
                            data.current_page <= data.last_page - 1
                              ? "d-block"
                              : "d-none"
                          }`}
                          onClick={() =>
                            updateData(`${BASE_URL}${data.current_page + 1}`)
                          }
                        >
                          {data.current_page + 1}
                        </span>
                        <span
                          className={`pagination-css ${
                            data.current_page <= data.last_page - 2
                              ? "d-block"
                              : "d-none"
                          }`}
                          onClick={() =>
                            updateData(`${BASE_URL}${data.current_page + 2}`)
                          }
                        >
                          {data.current_page + 2}
                        </span>
                        <span className="pagination-css ">{` ... `}</span>
                        <button
                          className="pagination-css "
                          disabled={data.current_page === data.last_page}
                          onClick={() =>
                            updateData(`${BASE_URL}${data.last_page}`)
                          }
                        >{` ${data.last_page}`}</button>
                      </div>
                      <div
                        className="pagination-css-next"
                        onClick={() => updateData(data.next_page_url)}
                        disabled={data.current_page === data.last_page}
                      >
                        <span aria-hidden="true">
                          <i
                            className="fa fa-chevron-right"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                    </div>
                  ) : null
                ) : null}

                {/* pagination code */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ExpiredAds;

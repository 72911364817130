import React, { useEffect } from 'react'
import Header from '../component/header';
import TermBanner from './Termofuse/TermBanner';
import Footer from '../component/footer';
import TermConditions from './Termofuse/TermConditions';
import { Membership } from './HomeComponent/Membership';
import { TopFooter } from './HomeComponent/TopFooter';
import BacktoTop from './HomeComponent/BacktoTop';

const Termandcondition = () => {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return (
   <>
   <BacktoTop />
   <Header />
   <TermBanner />
<TermConditions />
<Membership />
<TopFooter />
   <Footer />   
   </>
  )
}

export default Termandcondition;
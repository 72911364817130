import React from "react";
import author from "../../asset/img/user.png";

const Testimonial = () => {
  return (
    <>
      <section className="spacing-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="premium-text">
                <h3>
                  User <span className="premium-text-color"> Feedback</span>
                </h3>
                <p>
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  visual mockups.
                </p>
              </div>
            </div>
          </div>
          <div className="row section-detail">
            <div className="d-flex justify-content-between p-0">
              <h4 className="section-infoedit m-0"> &nbsp;</h4>
              <span>
                <div className="carousel-btn">
                  <a
                    className="carousel-btn-pre"
                    href="#testslider"
                    role="button"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="carousel-btn-next"
                    href="#testslider"
                    role="button"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Next</span>
                  </a>
                </div>
              </span>
            </div>
            <span className="section-detail-border"></span>
          </div>
      <div id="testslider" className="carousel slide mt-4" data-ride="carousel">
            <ol className="carousel-indicators premium-carousel-dot">
              <li
                data-target="#testslider"
                data-bs-slide-to="0"
                className="active ms-1"
              ></li>
              <li data-target="#testslider" className="ms-1" data-bs-slide-to="1"></li>
              <li data-target="#testslider" className="ms-1"  data-bs-slide-to="2"></li>
            </ol>
            <div className="carousel-inner testimonial-slider p-0">
              <div className="carousel-item active">
              <div className="row">
            <div className="col-lg-4 col-md-6 test1-mobile test-mobile">
              <div className="imagebox">
                <div className="overlay-text">
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups.
                </div>
                <div className="bottom-bar">
                  <div className="content">
                    <div className="text">Name</div>
                  </div>
                </div>
                <div className="test-img">
                  <img src={author} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 test2-mobile test-mobile">
              <div className="imagebox">
                <div className="overlay-text">
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups.
                </div>
                <div className="bottom-bar">
                  <div className="content">
                    <div className="text">Name</div>
                  </div>
                </div>
                <div className="test-img">
                  <img src={author} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 test3-mobile test-mobile">
              <div className="imagebox">
                <div className="overlay-text">
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups.
                </div>
                <div className="bottom-bar">
                  <div className="content">
                    <div className="text">Name</div>
                  </div>
                </div>
                <div className="test-img">
                  <img src={author} alt="" />
                </div>
              </div>
            </div>
          </div>
              </div>
              <div className="carousel-item">
              <div className="row">
            <div className="col-lg-4 col-md-6 test1-mobile test-mobile">
              <div className="imagebox">
                <div className="overlay-text">
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups.
                </div>
                <div className="bottom-bar">
                  <div className="content">
                    <div className="text">Name</div>
                  </div>
                </div>
                <div className="test-img">
                  <img src={author} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 test2-mobile test-mobile">
              <div className="imagebox">
                <div className="overlay-text">
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups.
                </div>
                <div className="bottom-bar">
                  <div className="content">
                    <div className="text">Name</div>
                  </div>
                </div>
                <div className="test-img">
                  <img src={author} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 test3-mobile test-mobile">
              <div className="imagebox">
                <div className="overlay-text">
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups.
                </div>
                <div className="bottom-bar">
                  <div className="content">
                    <div className="text">Name</div>
                  </div>
                </div>
                <div className="test-img">
                  <img src={author} alt="" />
                </div>
              </div>
            </div>
          </div>
              </div>
              <div className="carousel-item">
              <div className="row">
            <div className="col-lg-4 col-md-6 test1-mobile test-mobile">
              <div className="imagebox">
                <div className="overlay-text">
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups.
                </div>
                <div className="bottom-bar">
                  <div className="content">
                    <div className="text">Name</div>
                  </div>
                </div>
                <div className="test-img">
                  <img src={author} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 test2-mobile test-mobile">
              <div className="imagebox">
                <div className="overlay-text">
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups.
                </div>
                <div className="bottom-bar">
                  <div className="content">
                    <div className="text">Name</div>
                  </div>
                </div>
                <div className="test-img">
                  <img src={author} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 test3-mobile test-mobile">
              <div className="imagebox">
                <div className="overlay-text">
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups.
                </div>
                <div className="bottom-bar">
                  <div className="content">
                    <div className="text">Name</div>
                  </div>
                </div>
                <div className="test-img">
                  <img src={author} alt="" />
                </div>
              </div>
            </div>
          </div>
              </div>
            </div>
          </div>  

          
        </div>
      </section>
    </>
  );
};

export default Testimonial;

/** @format */

import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { adData, idToDelete, isLoader, isToaster } from "../../actions"
import { useSelector } from "react-redux"
import axios from "axios"
import { availabilityData } from "../../actions"
import { useNavigate } from "react-router-dom"

const DeleteTableData = (props) => {
  let ID = useSelector((s) => s.id_to_delete)
  const [loading, setLoading] = useState(false)
  const [confirm, setConfirm] = useState('')
  let token = localStorage.getItem("userToken")
  let navigate = useNavigate()
  let dispatch = useDispatch()
  // const show = useSelector((s) => s.show_renew_popup)
  const onDelete = async () => {
    dispatch(isLoader(true))
    if (props.type === "ads") {
      await axios
        .delete(
          `${process.env.REACT_APP_API_URL}/api/delete-advertisement/${ID}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          dispatch(isToaster({flag: true, msg: 'Ad deleted Successfully!'}))
          dispatch(isLoader(false))
          dispatch(idToDelete(""))
          console.log(res)
          dispatch(isLoader(true))
          axios
            .get(
              `${process.env.REACT_APP_API_URL}/api/get-advertisements`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((Res) => {
              dispatch(isLoader(false))
              dispatch(adData(Res.data.allAds.data))
              console.log("get Ads data : ", Res)
            })
            .catch((err) => {
              console.log(err)
            })
          navigate("/advertiser/activeAds")
        })
        .catch((err) => {
          console.log(err)
        })
      // dispatch(showRenewPopup(false))
    } else if (props.type === "availability") {
      await axios
        .delete(
          `${process.env.REACT_APP_API_URL}/api/delete-availability/${ID}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          dispatch(isToaster({flag: true, msg: 'Availability deleted Successfully!'}))
          dispatch(isLoader(false))
          dispatch(idToDelete(""))
          console.log(res)
          dispatch(isLoader(true))
          axios
            .get(
              `${process.env.REACT_APP_API_URL}/api/get-availabilities`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((Res) => {
              dispatch(isLoader(false))
              dispatch(availabilityData(Res.data.availabilities.data))
            })
            .catch((err) => {
              console.log(err)
            })
          navigate("/advertiser/availability")
        })
        .catch((err) => {
          console.log(err)
        })
      // dispatch(showRenewPopup(false))
    } else if (props.type === "Account") {
      if (confirm === "CONFIRM"){
        setLoading(true)
        await axios
        .delete(
          `${process.env.REACT_APP_API_URL}/api/delete-advertiser`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setLoading(false)
          dispatch(isToaster({flag: true, msg: 'Your Account is Deleted Successfully'}))
          dispatch(isLoader(false))
          dispatch(idToDelete(""))
          localStorage.removeItem("userToken")
          localStorage.removeItem("userRole")
          navigate("/login")
        })
        .catch((err) => {
          setLoading(false)
          console.log(err)
        })
      } else {
        dispatch(
          isToaster({
            flag: true,
            msg: `Please type "CONFIRM" to delete the ${props.type}`,
          })
        )
      }
      
      // dispatch(showRenewPopup(false))
    }
  }
  return (
    <>
      <div
        className={`modal_container text-center m-auto ${
          ID ? "d-block" : "d-none"
        }`}
      >
        <div className="modal-dialog">
          <div className="modal-content price-table p-4">
            <div className="position-relative">
              <h2 className="modal-title fs-5 text-body" id="deletepopupLabel">
                Delete this Ads?
              </h2>
              <span
                className="position-absolute cross_close top-0 end-0"
                onClick={() => dispatch(idToDelete(""))}
              >
                <i
                  className="fa-solid fa-x"
                  data-dismiss="modal"
                  aria-label="Close"
                ></i>
              </span>
            </div>
            <div className="modal-body">
              <h2 className="fs-6 text-body">
                Are you sure to delete {props.type}
              </h2>
            </div>

            <div
              className={`modal-body ${
                props.type === "Account" ? "d-block" : "d-none"
              }`}
            >
              <label>
                {" "}
                <span>
                  Please type "CONFIRM" to Delete this {props.type}
                </span>{" "}
              </label>
              <input
                type="text"
                name="confirm"
                value={confirm}
                onChange={(e) => setConfirm(e.target.value)}
                className="form-control form-control-ctm"
              />
            </div>

            <div className="p-2">
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn cus-btn "
                  disabled={loading}
                  data-dismiss="modal"
                  onClick={onDelete}
                >
                  Delete&nbsp;
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : null}
                </button>
                <button
                  type="button"
                  className="btn sec-btn ms-3"
                  onClick={() => dispatch(idToDelete(""))}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DeleteTableData

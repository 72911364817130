import React, { useEffect, useState } from "react";
import $ from "jquery";
import {
  GetCountries,
  GetState,
  GetCity,
  // GetLanguages, //async functions
} from "react-country-state-city";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { isToaster, promotedZone } from "../../../actions";

const CreatePromotezone = () => {
  const token = localStorage.getItem("userToken");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const newdate = new Date().toISOString();
  const todayDate = newdate.substring(0, 10);

  const [zoneType, setZoneType] = useState("");

  const [validFrom, setValidFrom] = useState("");
  const [validTo, setValidTo] = useState("");
  const [zip, setZip] = useState("");

  const [countryid, setCountryid] = useState(-1);
  const [stateid, setStateid] = useState(-1);
  const [cityid, setCityid] = useState(-1);

  const [countryindex, setCountryindex] = useState();
  const [stateindex, setStateindex] = useState();
  const [cityindex, setCityindex] = useState();

  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const getDefaultValidTo = () => {
    const year = new Date().getFullYear();
    let month = new Date().getMonth();
    const date = new Date().getDate();
    if (month == 12) {
      month = 1;
    } else {
      month += 1;
    }
    return `${year}-${("0" + (month + 1)).slice(-2)}-${("0" + (date - 1)).slice(
      -2
    )}`;
  };

  const onCreatePromoteZone = () => {
    const customConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const postData = {
      zone_type: zoneType,
      country: countriesList.find((x) => x.id == countryid)? countriesList.find((x) => x.id == countryid).name : null,
      state: stateList.find((x) => x.id == stateid)? stateList.find((x) => x.id == stateid).name : null,
      city: cityList.find((x) => x.id == cityid)? cityList.find((x) => x.id == cityid).name : null,
      zip: zip,
      valid_from: validFrom ? validFrom : todayDate,
      // valid_to: validTo ? validTo : getDefaultValidTo(),
      // valid_from: validFrom,
      valid_to: validTo,
    };
    console.log(postData);
    if (!postData.zone_type) {
      dispatch(isToaster({ flag: true, msg: "Please Zone type!" }));
    } else if (postData.zone_type === "country" && !postData.country) {
      dispatch(isToaster({ flag: true, msg: "Please Select Country!" }));
    } else if (postData.zone_type === "state" && !postData.state) {
      dispatch(isToaster({ flag: true, msg: "Please Select State!" }));
    } else if (postData.zone_type === "city" && !postData.city) {
      dispatch(isToaster({ flag: true, msg: "Please Select City!" }));
    } else if (postData.zone_type === "zip" && !postData.zip) {
      dispatch(isToaster({ flag: true, msg: "Please Select ZIP code!" }));
    } else if (!postData.valid_from || !postData.valid_to) {
      dispatch(isToaster({ flag: true, msg: "Please Select Validity dates!" }));
    } else {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/create-promoting-zone`,
          postData,
          customConfig
        )
        .then((res) => {
          setLoading(false);
          dispatch(
            isToaster({ flag: true, msg: "Coupon created successfully!" })
          );
          navigate("/admin/promotezone");
        })
        .catch((err) => {
          setLoading(false);
          dispatch(
            isToaster({ flag: true, msg: "Oops! Something went wrong" })
          );
        });
    }
  };

  useEffect(() => {
    GetCountries().then((result) => {
      setCountriesList(result);
    });
  }, []);

  useEffect(() => {
    // Fetch states when countryid changes
    if (countryid !== -1) {
      GetState(countryid).then((result) => {
        // console.log("stateList: ", result)
        setStateList(result);
      });
    }
  }, [countryid]);

  useEffect(() => {
    // Fetch cities when both countryid and stateid change
    if (countryid !== -1 && stateid !== -1) {
      GetCity(countryid, stateid).then((result) => {
        // console.log("CityList: ", result)
        setCityList(result);
      });
    }
  }, [countryid, stateid]);
  return (
    <>
      <section className="addash">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex ">
                <h4 className="m-0">Promoting Zone</h4>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="addprofile">
                <form autoComplete="off" noValidate="noValidate">
                  <div className="row input-label">
                    <div className="col-lg-6">
                      <label className="mb-1">
                        <span>Zone type</span>
                      </label>
                      <select
                        className="form-control form-control-ctm"
                        aria-label="select"
                        name="zoneType"
                        onChange={(e) => {
                          setZoneType(e.target.value);
                        }}
                        value={zoneType}
                        // placeholder="Zone type"
                      >
                        <option value="" selected>
                          
                        </option>
                        <option value="country">Country</option>
                        <option value="state">State</option>
                        <option value="city">City</option>
                        <option value="zip">ZIP</option>
                      </select>
                    </div>
                  </div>
                  <div className="row input-label">
                    <div className="col-lg-6">
                      <label className="mb-1">
                        <span>Country</span>
                      </label>
                      <select
                        className="form-control form-control-ctm"
                        aria-label="select"
                        name="country"
                        onChange={(e) => {
                          const country = countriesList[e.target.value];
                          setCountryid(country.id);
                        }}
                        value={countryindex}
                        // placeholder="Country"
                      >
                        <option value="" selected>
                          
                        </option>
                        {countriesList.map((item, index) => (
                          <option key={index} value={index}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <label className="mb-1">
                        <span>State</span>
                      </label>
                      <select
                        className="form-control form-control-ctm"
                        aria-label="select"
                        name="state"
                        onChange={(e) => {
                          const state = stateList[e.target.value];
                          setStateid(state.id);
                        }}
                        value={stateindex}
                        // placeholder="State"
                      >
                        <option value="" selected>
                          
                        </option>
                        {stateList.map((item, index) => (
                          <option key={index} value={index}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row input-label mt-2">
                    <div className="col-lg-6 col-md-6">
                      <label className="mb-1">
                        {" "}
                        <span>City</span>{" "}
                      </label>
                      <select
                        className="form-control form-control-ctm"
                        aria-label="select"
                        name="city"
                        onChange={(e) => {
                          const city = cityList[e.target.value];
                          setCityid(city.id);
                        }}
                        value={cityindex}
                        // placeholder="City"
                      >
                        <option value="" selected>
                         
                        </option>
                        {cityList.map((item, index) => (
                          <option key={index} value={index}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <label className="mb-1">
                        <span>Zip Code</span>
                      </label>
                      <input
                        type="number"
                        aria-label="select"
                        name="mileradius"
                        value={zip}
                        onChange={(e) => setZip(e.target.value)}
                        // placeholder="Zip Code"
                        className="form-control form-control-ctm"
                      />
                    </div>
                  </div>
                  <div className="row input-label mt-2">
                    <div className="col-lg-6 col-md-6">
                      <label className="mb-1">
                        {" "}
                        <span>Valid From</span>{" "}
                      </label>
                      <input
                        type="date"
                        id="dateValidity"
                        min={new Date().toISOString().split("T")[0]}
                        name="validFrom"
                        value={validFrom ? validFrom : todayDate}
                        // value={validFrom}
                        onChange={(e) => setValidFrom(e.target.value)}
                        className="form-control form-control-ctm"
                      />
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <label className="mb-1">
                        <span>Valid to</span>
                      </label>
                      <input
                        type="date"
                        id="dateValidity"
                        min={new Date().toISOString().split("T")[0]}
                        name="validTo"
                        // value={validTo ? validTo : getDefaultValidTo()}
                        value={validTo}
                        onChange={(e) => setValidTo(e.target.value)}
                        className="form-control form-control-ctm"
                      />
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-lg-12 text-center">
                      <button
                        type="button"
                        className="btn pri-btn"
                        disabled={loading}
                        onClick={onCreatePromoteZone}
                      >
                        Create Promote Zone&nbsp;
                        {loading ? (
                          <span
                            className="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : null}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreatePromotezone;

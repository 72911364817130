import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import { isToaster } from "../../actions";
import { chatMessages } from "../../actions";

const MyMessage = () => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state.user_data);
  const chatStore = useSelector((s) => s.chat_messages);
  const socket = useMemo(() => io(process.env.REACT_APP_CHAT_URL), []);
  const [inputMessage, setInputMessage] = useState("");
  const [accepted, setAccepted] = useState(false);
  const [socketId, setSocketId] = useState("");
  const [messages, setMessages] = useState(
    JSON.parse(localStorage.getItem("chat")) || []
  );

  const handleMsg = (e) => {
    setInputMessage(e.target.value);
  };

  const onRedirect = () => {
    navigate("/advertiser");
  };

  const onSend = () => {
    const room = data.id;
    if (inputMessage && !accepted) {
      const hr = new Date().getHours();
      const min = new Date().getMinutes();
      socket.emit(
        "join room request",
        room,
        data.firstname,
        inputMessage,
        data.profile_photo,
        `${hr}:${min}`
      );
      socket.emit(
        "chat request",
        inputMessage,
        room,
        data.firstname,
        data.profile_photo,
        `${hr}:${min}`
      );
      setInputMessage("");
    } else if (inputMessage && accepted) {
      const hr = new Date().getHours();
      const min = new Date().getMinutes();
      socket.emit(
        "chat request",
        inputMessage,
        room,
        data.firstname,
        data.profile_photo,
        `${hr}:${min}`
      );
      setInputMessage("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSend();
    }
  };

  useEffect(() => {
    localStorage.setItem("chat", JSON.stringify(messages));
    const item = document.getElementById("chat_box");
    item.scrollTop = item.scrollHeight;
  }, [messages]);

  useEffect(() => {
    // Initialize chat when component mounts
    socket.on("connect", () => {
      // Perform necessary actions to initialize chat here
      console.log("Connected to socket server");
    });

    // Handle received messages
    socket.on("received-message", (msg, role, name, profile, time) => {
      setMessages((messages) => [
        ...messages,
        { msg: msg, role: role, name: name, profile: profile, time: time },
      ]);
      const item = document.getElementById("chat_box");
      item.scrollTop = item.scrollHeight;
    });

    socket.on("room joined by support", (status, msg, profile, role, name, time) =>
      status === true ? setAccepted(true) : setAccepted(false)
    );

    socket.on("leave room", (room) => {
      window.location.reload();
      socket.emit("leave room", room);
    });

    socket.on("wait-message", (text) => {
      dispatch(isToaster({ flag: true, msg: text }));
    });

    return () => {
      // Clean up socket listeners when component unmounts
      socket.off("connect");
      socket.off("received-message");
      socket.off("room joined by support");
      socket.off("leave room");
      socket.off("wait-message");
    };
  }, []);

  return (
    <section className="addash">
      <div className="container-fluid">
        <div className="chat-bot-container">
          <div id="chat_box" className="chat-bot-box">
            {messages
              ? messages.map((x) => {
                  if (x.role === "Advertiser") {
                    return (
                      <div className="chat-list">
                        <span className="chat-list-message">
                          {" "}
                          <h5 className="chat-message"> {x.msg} </h5>
                          <p className="chat-time">{x.time}</p>
                          <span className="chatbot__arrow chatbot__arrow--right"></span>
                        </span>

                        <img className="user-img-chat" src={x.profile} />
                      </div>
                    );
                  } else if (x.role === "Support") {
                    return (
                      <div className="chat-list-admin">
                        <img className="admin-img-chat" src={x.profile} />
                        <span className="chat-list-message">
                          {" "}
                          <h5 className="chat-message"> {x.msg} </h5>
                          <p className="chat-time">{x.time}</p>
                          <span className="chatbot__arrow chatbot__arrow--left"></span>
                        </span>
                      </div>
                    );
                  }
                })
              : null}
          </div>
          <span className="position-relative">
            <input
              type="text"
              name="inputMessage"
              value={inputMessage}
              onChange={handleMsg}
              onKeyDown={handleKeyDown}
              placeholder="Type a Message"
              className="form-control form-control-ctm snd-msg-inpt"
            /> 
            <i
              className="fa fa-paper-plane position-absolute mt-2 end-0 send-msg-btn"
              onClick={onSend}
            ></i>
          </span>

          {/* <div className="d-flex justify-content-between">
            <button onClick={onRedirect} className="btn sub-btn w-40 ">
              Return to Dashboard
            </button>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default MyMessage;

import React from "react";
import "../../asset/css/advertiser.css"; // Import your CSS file for styling
import { useSelector } from "react-redux";

const DisplayAvailability = () => {
  const availableDays = useSelector((s) => s.available_days);
  console.log(availableDays)
  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  return (
    <div className="availability-container">
      <div className="weekdays">
        {weekdays.map((day) => (
          <div
            key={day}
            className={`days ${
              availableDays.includes(day.substring(0, 3)) ? "available" : ""
            }`}
          >
            <div className="day-label">{day.substring(0, 3).toUpperCase()}</div>
            {availableDays.includes(day) && (
              <div className="availability-icon">
                <i className="fa fa-check"></i>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DisplayAvailability;

import React, { useEffect } from "react";
import Header from "../component/header";
// import PremiumBanner from "./Premium/PremiumBanner";
import VipFilter from "./Premium/VipFilter";
import PremiumAds from "./Premium/PremiumAds";
import AboutApplysec from "./AboutComponent/AboutApplysec";
import Testimonial from "./HomeComponent/Testimonial";
import { Membership } from "./HomeComponent/Membership";
import { TopFooter } from "./HomeComponent/TopFooter";
import Footer from "../component/footer";
import BacktoTop from "./HomeComponent/BacktoTop";
import AvailableNowBanner from "./AvailableNowPage/AvailableNowBanner";
import Filter from "./HomeComponent/Filter";
import AvailableNowCardData from "./AvailableNowPage/AvailableNowCardData";

const Vipads = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <BacktoTop />
      <Header />
      <AvailableNowBanner />
      <Filter />
      <AvailableNowCardData />
      <AboutApplysec />
      <Testimonial />
      <Membership />
      <TopFooter />
      <Footer />
    </>
  );
};

export default Vipads;

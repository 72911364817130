import React from "react";

export const TopFooter = () => {
  return (
    <>
      <section className="spacing-page ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h3>
                Lorium Ipsum{" "}
                <span className="premium-text-color"> Dummy Text</span>
              </h3>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-2">
              <div>
                <div className="section-detail">
                  <div className="d-flex justify-content-between align-items-center p-0">
                    <span>Gender</span>
                    <h4 className="section-infoedit m-0"> </h4>
                  </div>
                  <span className="section-detail-border d-flex mb-1"></span>
                </div>
              </div>
              <div className="top-footer">
                <ul>
                  <li>Male</li>
                  <li>Female</li>
                  <li>Non Binary</li>
                  <li>Other</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-8">
            <div>
                <div className="section-detail">
                  <div className="d-flex justify-content-between align-items-center p-0">
                    <span>Category</span>
                    <h4 className="section-infoedit m-0"> </h4>
                  </div>
                  <span className="section-detail-border d-flex mb-1"></span>
                </div>
              </div>
              <div className="row top-footer-mobile">
                <div className="col-lg-4 col-md-4">
                <div className="top-footer">
                <ul>
                  <li>Age</li>
                  <li>Ethnicity</li>
                  <li>Nationality</li>
                  <li>Eye Color</li>
                </ul>
              </div>
                </div>
                <div className="col-lg-4 col-md-4">
                <div className="top-footer">
                <ul>
                  <li>Hair Color</li>
                  <li>Height</li>
                  <li>Weight</li>
                  <li>Premium</li>
                </ul>
              </div>
                </div>
                <div className="col-lg-4 col-md-4">
                <div className="top-footer">
                <ul>
                  <li>VIP</li>
                  <li>Diamond</li>
                  <li>100% Verified</li>
                  <li>Available For</li>
                </ul>
              </div>
                </div>
              </div>
              <div className="row top-footer-mobile-block">
                <div className="col-6">
                <div className="top-footer">
                <ul>
                  <li>Age</li>
                  <li>Ethnicity</li>
                  <li>Nationality</li>
                  <li>Eye Color</li>
                  <li>Hair Color</li>
                  <li>Height</li>
                </ul>
              </div>
                </div> 
                <div className="col-6">
                <div className="top-footer">
                <ul>
                <li>Weight</li>
                  <li>Premium</li>
                  <li>VIP</li>
                  <li>Diamond</li>
                  <li>100% Verified</li>
                  <li>Available For</li>
                </ul>
              </div>
                </div>
              </div>
             
            </div>
            <div className="col-lg-2">
              <div>
                <div className="section-detail">
                  <div className="d-flex justify-content-between align-items-center p-0">
                    <span>Languages</span>
                    <h4 className="section-infoedit m-0"> </h4>
                  </div>
                  <span className="section-detail-border d-flex  mb-1"></span>
                </div>
              </div>
              <div className="top-footer">
                <ul>
                  <li>English</li>
                  <li>Portuguese</li>
                  <li>Spanish</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

/** @format */

import React, { useEffect, useState } from "react"
import "../../asset/css/advertiser.css"
import { useDispatch, useSelector } from "react-redux"
import profile_logo from "../../asset/img/firstAdvertiserProfile.png"
import advertising from "../../asset/img/advertising.png"
import activeplan from "../../asset/img/Active-plan.png"
import Ads_Location from "../component/Ads_Location"
import Availablity_Table_Dashboard from "../component/Availability_Table_Dashboard.js"
import userLogo from "../../asset/img/user.png"
import axios from "axios"
import { isLoader } from "../../actions"

const Dashboard = () => {
  const data = useSelector((state) => state.user_data)
  const token = localStorage.getItem("userToken")
  const dispatch = useDispatch()
  // const activeAds = 12
  // const expiredAds = 8
  const [stats, setStats] = useState({
    totalAds: "",
    activeAds: "",
    expiredAds: "",
    activePlan: "",
  })

  useEffect(() => {
    dispatch(isLoader(true))
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/get-advertiser-dashboard-totals`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        dispatch(isLoader(false))
        setStats(res.data)
        console.log("res from get-advertiser-total: ", res.data)
      })
      .catch((err) => {
        dispatch(isLoader(false))
        console.log(err)
      })
  }, [])

  return (
    <>
      <section className="addash">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex align-items-center">
                <span className="user_profile">
                  <img
                    src={data.profile_photo ? data.profile_photo : userLogo}
                    style={{ objectFit: "cover" }}
                    alt="user"
                  />
                </span>
                <span className="ms-3">
                  <h4 className="m-0">Welcome, {data.firstname} </h4>
                </span>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-3 col-md-6 ds-card">
              <div className="dash_card">
                <h5 className="text-center">TOTAL ADS</h5>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="dash-card-body">
                    <img src={advertising} alt="Total Ads" />
                  </span>
                  <span>
                    {" "}
                    <p className="m-0">
                      {stats.totalAds ? stats.totalAds : "0"}
                    </p>{" "}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 ds-card">
              <div className="dash_card">
                <h5 className="text-center">ACTIVE ADS</h5>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="dash-card-body">
                    <img src={advertising} alt="Total Ads" />
                  </span>
                  <span>
                    {" "}
                    <p className="m-0">
                      {stats.activeAds ? stats.activeAds : "0"}
                    </p>{" "}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 ds-card">
              <div className="dash_card">
                <h5 className="text-center">EXPIRED ADS</h5>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="dash-card-body">
                    <img src={advertising} alt="Total Ads" />
                  </span>
                  <span>
                    {" "}
                    <p className="m-0">
                      {stats.expiredAds ? stats.expiredAds : "0"}
                    </p>{" "}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 ds-card">
              <div className="dash_card dash_card-end">
                <h5 className="text-center">ACTIVE PLAN</h5>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="dash-card-body">
                    <img src={activeplan} alt="Total Ads" />
                  </span>
                  <span>
                    <p className="m-0">
                      {stats.activePlan ? stats.activePlan : null}
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <Ads_Location />
          {/* <div className="mt-5"></div> */}
          {/* <Availablity_Table_Dashboard /> */}
        </div>
      </section>
    </>
  )
}

export default Dashboard

/** @format */

import React, { useEffect, useState } from "react";
import profile_logo from "../../asset/img/profile.png";
import { Link, useNavigate } from "react-router-dom";
import viewad from "../../asset/img/viewads.png";
import advertiserPic from "../../asset/img/advertiserPic.png";
import play from "../../asset/img/play.png";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { isLoader, isToaster } from "../../actions";

const ViewAds = () => {
  const token = localStorage.getItem("userToken");
  const role = localStorage.getItem("userRole");
  const BASE_URL1 = `${process.env.REACT_APP_API_URL}/api/get-advertisement-by-id/${localStorage.getItem(
    "viewAdId"
  )}`;
  const BASE_URL2 = `${process.env.REACT_APP_API_URL}/api/get-advertiser-by-admin/${localStorage.getItem(
    "viewAdvertiser"
  )}`
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingt, setLoadingt] = useState(false);
  // const data = useSelector((s) => s.view_ad_data)
  const [data, setData] = useState({});
  const [advertiser, setAdvertiser] = useState({
    firstname: "",
    lastname: "",
    hair_coloe: "",
    gender: "",
    body_type: "",
    age: "",
    ethnicity: "",
    eye_color: "",
    breast_size: "",
    height: "",
    images: [],
    videos: [],
  });
  useEffect(() => {
    dispatch(isLoader(true));
    axios
      .get(BASE_URL1,{headers: { Authorization: `Bearer ${token}` }}
      )
      .then((res) => {
        dispatch(isLoader(false));
        setData(res.data.advertisement);
        // console.log("adevrtisement by id: ",res)
      })
      .catch((err) => {
        dispatch(isLoader(false));
        console.log(err);
      });
    dispatch(isLoader(true));
    axios
      .get(
        BASE_URL2,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        dispatch(isLoader(false));
        setAdvertiser(res.data.user);
        console.log("advertiser data at advertiser panel: ", res.data.user);
      })
      .catch((err) => {
        dispatch(isLoader(false));
      });
  }, []);
  const onPause = () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/pause-advertisement/${data.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setLoading(false);
        // dispatch(isToaster({ flag: true, msg: "Ad created Successfully!" }))
        navigate("/admin/totalAds");
        dispatch(
          isToaster({
            flag: true,
            msg: res.data.message,
          })
        );
        // console.log(res)
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err)
      });
  };
  const onTrash = async () => {
    setLoadingt(true);
    await axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/trash-advertisement/${data.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setLoadingt(false);
        // dispatch(isToaster({ flag: true, msg: "Ad created Successfully!" }))
        navigate("/admin/totalAds");
        dispatch(
          isToaster({
            flag: true,
            msg: "Advertisement Soft Deleted Successfully!",
          })
        );
        console.log(res);
      })
      .catch((err) => {
        setLoadingt(false);
        console.log(err);
      });
  };
  const onActivate = async () => {
    setLoading(true); // Show loader when login button is clicked
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/activate-paused-advertisement/${data.id}`,
        null,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setLoading(false);
        // dispatch(isToaster({ flag: true, msg: "Ad created Successfully!" }))
        navigate("/admin/pauseAds");
        dispatch(
          isToaster({
            flag: true,
            msg: "Advertisement Activated Successfully!",
          })
        );
        console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const onRestore = async () => {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/restore-advertisement/${data.id}`,
        null,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setLoading(false);
        // dispatch(isToaster({ flag: true, msg: "Ad created Successfully!" }))
        navigate("/admin/trashAds");
        dispatch(
          isToaster({
            flag: true,
            msg: "Advertisement Restored Successfully!",
          })
        );
        console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  return (
    <>
      <section className="addash">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between  align-items-center">
                <span className="d-flex align-items-center">
                  <span className="user_profile">
                    <img
                      src={
                        advertiser.profile_photo
                          ? advertiser.profile_photo
                          : profile_logo
                      }
                      style={{ objectFit: "cover" }}
                      alt="user"
                    />
                  </span>
                  <span className="ms-4">
                    <h4>{advertiser.firstname + " " + advertiser.lastname}</h4>
                  </span>
                </span>
                <span>
                  <div>
                    <Link to="/admin/viewAdvertiser">
                      {" "}
                      <button
                        type="button"
                        className="btn cus-btn"
                        onClick={localStorage.setItem(
                          "viewAdvertiser",
                          data.advertiser_id
                        )}
                      >
                        View Profile
                      </button>
                    </Link>
                  </div>
                </span>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-7 col-md-6">
              <div className="viewcard ">
                <div className="row basic-detail">
                  <h4 className="infoedit m-0"> Basic Info </h4>
                  <span className="basic-detail-border"></span>
                </div>
                <div className="row mt-3">
                  <div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p className="basicinfo">Name</p>
                        <h6 className="basicdata">
                          {advertiser.firstname + " " + advertiser.lastname}
                        </h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p className="basicinfo">Hair Color</p>
                        <h6 className="basicdata">{advertiser.hair_color}</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p className="basicinfo">Gender</p>
                        <h6 className="basicdata">{advertiser.gender}</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p className="basicinfo">Body Type</p>
                        <h6 className="basicdata">{advertiser.body_type}</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p className="basicinfo">Age</p>
                        <h6 className="basicdata">{advertiser.age}</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p className="basicinfo">Ethnicity</p>
                        <h6 className="basicdata">{advertiser.ethnicity}</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p className="basicinfo">Eye Color</p>
                        <h6 className="basicdata">{advertiser.eye_color}</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p className="basicinfo">Breast Size</p>
                        <h6 className="basicdata">{advertiser.breast_size}</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p className="basicinfo">Height</p>
                        <h6 className="basicdata">{advertiser.height}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-6">
              <div>
                <div className="plan-a">
                  <div className="d-flex align-items-center ">
                    <img src={viewad} alt="plan-a" className="img-fluid" />
                    <span className="mx-4 plan-a-text">
                      <p className="m-0">
                        ABC active package is -{" "}
                        <span className="plan-a-detail">FREE</span> <br />
                        Expire:{" "}
                        <span className="plan-a-detail">DD/MM/YYYY</span>
                      </p>
                    </span>
                  </div>
                </div>
              </div>

              <div className="viewcard mt-2">
                <div className="row basic-detail">
                  <h4 className="infoedit m-0"> Basic Info </h4>
                  <span className="basic-detail-border"></span>
                </div>
                <div className="row mt-3">
                  <div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p className="basicinfo">Ad Name</p>
                        <h6 className="basicdata">{data.ad_name}</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p className="basicinfo">City</p>
                        <h6 className="basicdata">{data.location}</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p className="basicinfo">Mile Radius</p>
                        <h6 className="basicdata">{data.mile_radius}</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p className="basicinfo">State</p>
                        <h6 className="basicdata">{data.state}</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p className="basicinfo">Country</p>
                        <h6 className="basicdata">{data.country}</h6>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p className="basicinfo">Age</p>
                        <h6 className="basicdata">{advertiser.age}</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p className="basicinfo">Ethnicity</p>
                        <h6 className="basicdata">{advertiser.ethnicity}</h6>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
 
          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="viewcard mt-2">
                <div className="row basic-detail">
                  <h4 className="infoedit m-0">Price </h4>
                  <span className="basic-detail-border"></span>
                </div>
                <div className="row mt-3">
                  <div>
                    {/* <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Duration</p>
                        <h6 className="basicdata" style={{ color: "#c01f25" }}>
                          Price
                        </h6>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Duration</p>
                        <h6 className="basicdata" style={{ color: "#c01f25" }}>
                          Price
                        </h6>
                      </div>
                    </div> */}

                    <div className="row">
                      {data.duration_price
                        ? data.duration_price.map((x) => {
                            return (
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <p className="basicinfo">
                                  {Number(x.hour) + Number(x.min) / 60} Hours
                                </p>
                                <h6 className="basicdata">{x.price} $</h6>
                              </div>
                            );
                          })
                        : null}

                      {/* <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">2 Hours</p>
                        <h6 className="basicdata">10 BTC</h6>
                      </div> */}
                    </div>
                    {/* <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">3 Hours</p>
                        <h6 className="basicdata">10 BTC</h6>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">6 Hours</p>
                        <h6 className="basicdata">10 BTC</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">12 Hours</p>
                        <h6 className="basicdata">10 BTC</h6>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">24 Hours</p>
                        <h6 className="basicdata">10 BTC</h6>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className={`col-lg-12 d-flex justify-content-end`}>
              <button
                type="button"
                disabled={loading}
                className={`btn pri-btn ${
                  (data.deleted_at != null) & (data.paused === 0)
                    ? "d-block"
                    : "d-none"
                }`}
                onClick={onRestore}
                // disabled={data.expired && data.paused != 0}
              >
                Restore &nbsp;
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : null}
              </button>
              <button
                type="button"
                disabled={loading}
                className={`btn pri-btn ${
                  (data.deleted_at == null) & (data.paused !== 0)
                    ? "d-block"
                    : "d-none"
                }`}
                onClick={onActivate}
                // disabled={data.expired && data.paused != 0}
              >
                Activate &nbsp;
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : null}
              </button>
              <button
                type="button"
                disabled={loading}
                className={`btn pri-btn ${
                  (data.deleted_at == null) & (data.paused === 0)
                    ? "d-block"
                    : "d-none"
                }`}
                onClick={onPause}
                // disabled={data.expired && data.paused != 0}
              >
                Pause Ads &nbsp;
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : null}
              </button>{" "}
              &nbsp; &nbsp; &nbsp;
              <button
                type="button"
                disabled={loadingt}
                className={`btn pri-btn ${
                  (data.deleted_at == null) & (data.paused === 0)
                    ? "d-block"
                    : "d-none"
                }`}
                onClick={onTrash}
                // disabled={data.expired && data.paused != 0}
              >
                Delete Ads &nbsp;
                {loadingt ? (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : null}
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewAds;

import React, { useEffect } from "react";
import Header from "../component/header";
import Footer from "../component/footer";
import AvailableNowBanner from "./AvailableNowPage/AvailableNowBanner";
import AvailableNowCardData from "./AvailableNowPage/AvailableNowCardData";
import AboutApplysec from "./AboutComponent/AboutApplysec";
import { AboutMember } from "./AboutComponent/AboutMember";
import { TopFooter } from "./HomeComponent/TopFooter";
import Filter from "./HomeComponent/Filter";
import BacktoTop from "./HomeComponent/BacktoTop";

const AvailableNowData = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <BacktoTop />
      <Header />
      <AvailableNowBanner />
      <Filter />
      <AvailableNowCardData />
      <AboutApplysec />
      <AboutMember />
      <TopFooter />
      <Footer />
    </>
  );
};

export default AvailableNowData;

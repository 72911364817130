/** @format */

import React from "react"
import { useSelector } from "react-redux"

const ViewLocationCard = () => {
  const data = useSelector((s) => s.ad_data)

  return (
    <>
      <div className="mt-3">
        <div className="profile-info-nav">
          <div className="row position-relative profile-info-tab">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="m-0">
                {" "}
                Location <span className="profile-info-tab-txt">Info</span>
              </h4>
            </div>
            <span className="profile-info-border"></span>
          </div>
          <div className="row mt-2">
            <div className="col-lg-12">
              <div className="profile-tab-card">
                <span className="profile-tab-card-head">Target City</span>
                <span className="profile-tab-card-txt">{data.location}</span>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-lg-12">
              <div className="profile-tab-card">
                <span className="profile-tab-card-head">Mile Radius</span>
                <span className="profile-tab-card-txt">{data.mile_radius} Mile Radius</span>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-lg-12">
              <div className="profile-tab-card">
                <span className="profile-tab-card-head">State</span>
                <span className="profile-tab-card-txt">{data.state}</span>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-lg-12">
              <div className="profile-tab-card">
                <span className="profile-tab-card-head">Country</span>
                <span className="profile-tab-card-txt">{data.country}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewLocationCard

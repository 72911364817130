/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { availableDays, isLoader, isToaster, userData } from "../../../actions";
import advertiserPic from "../../../asset/img/advertiserPic.png";
import camera from "../../../asset/img/camera.png";
import DisplayAvailability from "../../component/DisplayAvailability";
import play from "../../../asset/img/play.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import axios from "axios";

const ViewProfile = () => {
  let token = localStorage.getItem("userToken");
  const data = useSelector((state) => state.user_data);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [coverPhoto, setCoverPhoto] = useState();
  const [availableDay, setAvailableDay] = useState([]);
  let [edata, setEdata] = useState(data);
  // let edata = {...data}
  let name, Value;
  const handleInputs = (e) => {
    name = e.target.name;
    Value = e.target.value;
    setEdata({ ...edata, [name]: Value });
  };

  const onloadmore = () => {
    dispatch(isLoader(true));
    setTimeout(() => {
      dispatch(isLoader(false));
    }, 1000);
  };

  const profileUpload = (e) => {
    setEdata({ ...edata, profile_photo: e.target.files[0] });
    const [file] = document.getElementById("uploadPhoto").files;
    setEdata({ ...edata, profile_photo: { file } });
    // if (file) {
    //   console.log(file)
    //   setEdata({...edata, profile_photo: file})
    //   document.getElementById("blah1").src = URL.createObjectURL(file)
    // }
  };

  const uploadCoverPhoto = async (e) => {
    dispatch(isLoader(true));
    const [file] = document.getElementById("uploadPhoto").files;
    if (file) {
      let image_url = URL.createObjectURL(file);
      setCoverPhoto(file);
      document.getElementById(
        "headerimage-bg"
      ).style.backgroundImage = `url(${image_url})`;
      document.getElementById("headerimage-bg").style.backgroundColor =
        "rgba(0,0,0,0.6)";
      document.getElementById("headerimage-bg").style.backgroundBlendMode =
        "darken";
      const customConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      };
      let postCoverPhoto = {
        cover_photo: e.target.files[0],
      };
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/update-cover-photo`,
          postCoverPhoto,
          customConfig
        )
        .then((res) => {
          dispatch(
            isToaster({ flag: true, msg: "Cover photo updated Successfully!" })
          );
          dispatch(isLoader(false));
          console.log(res);
          console.log("cover photo file post after api call: ", coverPhoto);
          dispatch(isLoader(true));
          axios
            .get(`${process.env.REACT_APP_API_URL}/api/get-advertiser`, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
              if (res.data.user.availability != null) {
                dispatch(availableDays(res.data.user.availability));
              } else {
                dispatch(availableDays([]));
              }
              dispatch(isLoader(false));
              dispatch(userData(res.data.user));
              console.log(
                "advertiser data at advertiser panel: ",
                res.data.user
              );
            })
            .catch((err) => {
              navigate("/login");
            });
        })
        .catch((err) => {
          dispatch(isLoader(false));
          document.getElementById(
            "headerimage-bg"
          ).style.backgroundImage = `url(${data.cover_photo})`;
          dispatch(isToaster({ flag: true, msg: err.response.data.error }));
        });
    }
  };

  useEffect(() => {
    document.getElementById("headerimage-bg").style.backgroundColor =
      "rgba(0,0,0,0.6)";
    document.getElementById("headerimage-bg").style.backgroundBlendMode =
      "darken";
    dispatch(isLoader(true));
    document.getElementById(
      "headerimage-bg"
    ).style.backgroundImage = `url(${data.cover_photo})`;
    console.log("user data: ", data);
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/get-advertiser`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch(isLoader(false));
        dispatch(userData(res.data.user));
        dispatch(availableDays(res.data.user.availability));
      })
      .catch((err) => {
        dispatch(isLoader(false));
        dispatch(isToaster({ flag: true, msg: err.response.data.message }));
        navigate("/login");
      });
  }, []);

  const onSave = async () => {
    const customConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    let postData = {
      firstname: edata.firstname,
      ethnicity: edata.ethnicity,
      height: edata.height,
      breastSize: edata.breast_size,
      eyeColor: edata.eye_color,
      hairColor: edata.hair_color,
      bodyType: edata.body_type,
      // profilePhoto: edata.profile_photo ? edata.profile_photo : {},
    };
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/update-advertiser`,
        postData,
        customConfig
      )
      .then((res) => {
        dispatch(
          isToaster({
            flag: true,
            msg: "Advertiser details updated successfully!",
          })
        );
        setLoading(false);
        console.log(res);
        if (res.data.status) {
          if (token) {
            dispatch(isLoader(true));
            axios
              .get(`${process.env.REACT_APP_API_URL}/api/get-advertiser`, {
                headers: { Authorization: `Bearer ${token}` },
              })
              .then((res) => {
                dispatch(isLoader(false));
                dispatch(userData(res.data.user));
                console.log("advertiser data at view profile: ", res.data.user);
                $(".editable-form").hide();
                $(".readonly-form").show();
                $("#editButton1").show();
                $("#cancelButton").hide();
              })
              .catch((err) => {
                dispatch(isLoader(false));
                navigate("/login");
              });
          } else {
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        dispatch(isToaster({ flag: true, msg: err.response.data.message }));
        console.log(err);
      });
  };

  $(document).ready(function () {
    $("#editButton1").click(function () {
      $(".editable-form").show();
      $(".readonly-form").hide();
      $("#editButton1").hide();
      $("#cancelButton").show();
    });

    $("#cancelButton").click(function () {
      $(".editable-form").hide();
      $(".readonly-form").show();
      $("#editButton1").show();
      $("#cancelButton").hide();
    });
  });

  const heightArray = [];
  for (let i = 152; i <= 210; i++) {
    heightArray.push(i);
  }

  const bSizeArray = [];
  for (let i = 34; i <= 38; i += 2) {
    bSizeArray.push(i + " (A)");
    bSizeArray.push(i + " (B)");
    bSizeArray.push(i + " (C)");
  }

  const ageArray = [];
  for (let i = 18; i <= 60; i++) {
    ageArray.push(i);
  }

  return (
    <>
      <section className="addash">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex align-items-center">
                <h4>View Profile</h4>
              </div>
            </div>
          </div>

          <div className="row position-relative">
            <div className="col-lg-12">
              <div
                className="headerimage-bg"
                style={{ backgroundImage: `url(${data.cover_photo})` }}
                id="headerimage-bg"
              >
                <div className="row justify-content-center">
                  <div className="col-12 col-md-6 text-center one-fourth-screen d-flex flex-column justify-content-center sm-h-350px">
                    <h1 className="headerimage-heading text-white font-weight-600 text-uppercase d-block m-0">
                      {data.firstname}
                    </h1>
                    <span className="headerimage-text text-uppercase text-small text-white d-inline-block ">
                      Escort model from {data.city}
                    </span>
                  </div>
                </div>
                <div className="headerimage-input">
                  <label htmlFor="uploadPhoto" className="input-images">
                    <img
                      src={camera}
                      type="file"
                      className="rounded-circle mb-4 w-75"
                      alt="multiple images"
                    />
                  </label>
                  <input
                    id="uploadPhoto"
                    type="file"
                    onChange={uploadCoverPhoto}
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                </div>
              </div>
            </div>
            <div className="overlap-section-inner  rounded">
              <img
                src={data.profile_photo}
                alt="Emily"
                id="cover_photo"
                className="img-fluid model-round-img"
              />
            </div>
          </div>

          <div className="row viewcardtop">
            <div className="col-lg-12">
              <div className="viewcard ">
                <div className="row position-relative basic-detail">
                  <div className="d-flex justify-content-between align-items-center">
                    <h4 className="infoedit m-0"> Basic Info </h4>
                    <span className="infoedit1">
                      <button
                        id="editButton1"
                        className="custom-btn"
                        onClick={() => setEdata(data)}
                      >
                        {" "}
                        Edit{" "}
                      </button>{" "}
                    </span>
                  </div>
                  <span className="basic-detail-border"></span>
                </div>
                <div className="row mt-3">
                  <div className="readonly-form">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Name</p>
                        <h6 className="basicdata">
                          {data.firstname}
                        </h6>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Hair Color</p>
                        <h6 className="basicdata">{data.hair_color}</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Gender</p>
                        <h6 className="basicdata">{data.gender}</h6>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Body Type</p>
                        <h6 className="basicdata">{data.body_type}</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Age</p>
                        <h6 className="basicdata">{data.age}</h6>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Ethnicity</p>
                        <h6 className="basicdata">{data.ethnicity}</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Eye Color</p>
                        <h6 className="basicdata">{data.eye_color}</h6>
                      </div>
                      <div
                        className={`col-lg-6 col-md-6 col-sm-12 ${
                          data.gender === "Male" ? "d-none" : null
                        }`}
                      >
                        <p className="basicinfo">Breast Size</p>
                        <h6 className="basicdata">{data.breast_size}</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Height</p>
                        <h6 className="basicdata">{data.height}</h6>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo"> </p>
                        <h6 className="basicdata"> </h6>
                      </div>
                    </div>
                    <DisplayAvailability />
                  </div>

                  <div className="editable-form" style={{ display: "none" }}>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="input-label">
                            <label className="mb-1">
                              {" "}
                              <span>Name</span>{" "}
                            </label>
                            <input
                              type="text"
                              // placeholder="Name"
                              disabled
                              value={edata.firstname}
                              name="firstname"
                              onChange={handleInputs}
                              className="form-select-ctm pointer_d mb-2"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="input-label">
                            <label className="mb-1">
                              {" "}
                              <span>Hair Color</span>{" "}
                            </label>
                            <select
                              aria-label="select"
                              // placeholder="Hair Color"
                              value={edata.hair_color}
                              name="hair_color"
                              onChange={handleInputs}
                              className="form-select-ctm mb-2"
                            >
                              <option selected>-Hair Color-</option>
                              <option value="Black">Black</option>
                              <option value="Grey">Grey</option>
                              <option value="Golden">Golden</option>
                              <option value="Red">Red</option>
                              <option value="Blonde">Blonde</option>
                              <option value="Multicolor">Multicolor</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="input-label">
                            <label className="mb-1">
                              {" "}
                              <span>Gender</span>{" "}
                            </label>
                            <input
                              type="text"
                              // placeholder="Gender"
                              disabled
                              value={edata.gender}
                              name="gender"
                              onChange={handleInputs}
                              className="form-select-ctm pointer_d mb-2"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="input-label">
                            <label className="mb-1">
                              {" "}
                              <span>Body Type</span>{" "}
                            </label>
                            <select
                              aria-label="select"
                              // placeholder="body_type"
                              value={edata.body_type}
                              name="body_type"
                              onChange={handleInputs}
                              className="form-select-ctm mb-2"
                            >
                              <option selected>-Body Type-</option>
                              {edata.gender === "Male" ? (
                                <>
                                  <option value="Rectangle">Rectangle</option>
                                  <option value="Triangle">Triangle</option>
                                  <option value="Oval">Oval</option>
                                  <option value="Trapezoid">Trapezoid</option>
                                  <option value="Mesomorph">Mesomorph</option>
                                  <option value="Ectomorph">Ectomorph</option>
                                  <option value="Endomorph">Endomorph</option>
                                </>
                              ) : (
                                <>
                                  <option value="Slim">Slim</option>
                                  <option value="Triangle">Triangle</option>
                                  <option value="Hour glass">Hour glass</option>
                                  <option value="Rectangle">Rectangle</option>
                                  <option value="Apple">Apple</option>
                                  <option value="Pear">Pear</option>
                                </>
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="input-label">
                            <label className="mb-1">
                              {" "}
                              <span>Age</span>{" "}
                            </label>
                            <input
                              type="text"
                              // placeholder="Age"
                              disabled
                              value={edata.age}
                              name="age"
                              onChange={handleInputs}
                              className="form-select-ctm pointer_d mb-2"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="input-label">
                            <label className="mb-1">
                              {" "}
                              <span>Ethnicity</span>{" "}
                            </label>
                            <select
                              aria-label="select"
                              // placeholder="Ethnicity"
                              value={edata.ethnicity}
                              name="ethnicity"
                              onChange={handleInputs}
                              className="form-select-ctm mb-2"
                            >
                              <option selected>-Ethnicity-</option>
                              <option value="White American">
                                White American
                              </option>
                              <option value="European American">
                                European American
                              </option>
                              <option value="Middle Eastern American">
                                Middle Eastern American
                              </option>
                              <option value="Asian">Asian</option>
                              <option value="African">African</option>
                              <option value="Australian">Australian</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="input-label">
                            <label className="mb-1">
                              {" "}
                              <span>Eye Color</span>{" "}
                            </label>
                            <select
                              aria-label="select"
                              // placeholder="Eye Color"
                              value={edata.eye_color}
                              name="eye_color"
                              onChange={handleInputs}
                              className="form-select-ctm mb-2"
                            >
                              <option selected>-Eye Color-</option>
                              <option value="Black">Black</option>
                              <option value="Grey">Grey</option>
                              <option value="Golden">Golden</option>
                              <option value="Red">Green</option>
                            </select>
                          </div>
                        </div>
                        <div
                          className={`col-lg-6 col-md-6 col-sm-12 ${
                            edata.gender === "Male" ? "d-none" : null
                          }`}
                        >
                          <div className="input-label">
                            <label className="mb-1">
                              <span>Breast Size</span>
                            </label>
                            <select
                              aria-label="select"
                              onChange={handleInputs}
                              disabled={edata.gender === "Male" ? true : false}
                              name="breast_size"
                              value={edata.breast_size}
                              // placeholder="Breast Size"
                              className={`form-select-ctm mb-2 ${
                                edata.gender === "Male" ? "pointer_d" : null
                              }`}
                            >
                              <option selected>-Breast Size-</option>
                              {bSizeArray.map((x) => {
                                return <option value={x}>{x}</option>;
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="input-label">
                            <label className="mb-1">
                              <span>Height</span>
                            </label>
                            <select
                              aria-label="select"
                              // placeholder="Height"
                              value={edata.height}
                              name="height"
                              onChange={handleInputs}
                              className="form-select-ctm mb-2"
                            >
                              <option selected>-Height-</option>
                              {heightArray.map((x) => {
                                return <option value={x}>{x} cm</option>;
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12"></div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-12">
                          <button
                            type="submit"
                            id="saveButton"
                            disabled={loading}
                            className="btn cus-btn"
                            onClick={onSave}
                          >
                            Save&nbsp;
                            {loading ? (
                              <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : null}
                          </button>
                          &nbsp;
                          <button
                            type="submit"
                            id="cancelButton"
                            className="btn sec-btn"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row viewcardtop1 viewpro-img">
            {data.images
              ? data.images.map((x) => {
                  return (
                    <div className="col-lg-3 col-md-3 col-xs-6">
                      <div className="view-profile-img">
                        <img
                          src={x.image}
                          style={{ objectFit: "cover" }}
                          alt="advertiser"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  );
                })
              : null}
          </div>

          <div className="row mt-4 viewcardtop1">
            {data.videos
              ? data.videos.map((x) => {
                  return (
                    <div className="col-lg-6 col-md-6">
                      <div className="video-container">
                        <video
                          id="videoElement"
                          className="w-100"
                          loop
                          muted
                          controls
                        >
                          <source
                            src={x.video}
                            loop
                            className="hover-to-play w-100"
                          />
                        </video>
                        {/* <span id="customPlayButton" className="play-button">
                          <img src={play} alt="play/pause" />
                        </span> */}
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewProfile;

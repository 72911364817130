/** @format */

import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import axios from "axios"
import no_data from "../../../asset/img/no_data.png";
import DeleteEntry from "../../component/DeleteEntry"
import { idToDelete, isLoader, isToaster } from "../../../actions"
import { useDispatch } from "react-redux"

const User = () => {
  const [selectedRole, setSelectedRole] = useState("")
  const navigate = useNavigate()
  const [search, setSearch] = useState("")
  const BASE_URL = `${process.env.REACT_APP_API_URL}/api/get-managers-supports?role=${selectedRole}&searchText=${search}&page=`
  const role = localStorage.getItem("userRole")
  const token = localStorage.getItem("userToken")
  let dispatch = useDispatch()
  const [admin, setAdmin] = useState()
  const [data, setData] = useState()

  useEffect(() => {
    dispatch(isLoader(true))
    axios
      .get(BASE_URL, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch(isLoader(false))
        setData(res.data.users)
        setAdmin(res.data.users.data)
        console.log("Get all Admins : ", res.data.users)
      })
      .catch((err) => {
        dispatch(isLoader(false))
        console.log(err)
      })
  }, [])

  const updateData = (url) => {
    dispatch(isLoader(true))
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch(isLoader(false))
        setData(res.data.users)
        setAdmin(res.data.users.data)
        console.log("Get all Admins : ", res.data.users)
      })
      .catch((err) => {
        dispatch(isLoader(false))
        console.log(err)
      })
  }

  const onEdit = (ID) => {
    localStorage.setItem("editUserId", ID)
    navigate("/admin/editUser")
  }

  const handleSelectRole = (e) => {
    setSearch("")
    setSelectedRole(e.target.value)
    dispatch(isLoader(true))
    updateData(
      `${process.env.REACT_APP_API_URL}/api/get-managers-supports?role=${e.target.value}`
    )
    // axios
    //   .get(
    //     `${process.env.REACT_APP_API_URL}/api/get-managers-supports?role=${e.target.value}`,
    //     {
    //       headers: { Authorization: `Bearer ${token}` },
    //     }
    //   )
    //   .then((res) => {
    //     dispatch(isLoader(false))
    //     setData(res.data.users)
    //     setAdmin(res.data.users.data)
    //     console.log("Get all Admins : ", res.data.users)
    //   })
    //   .catch((err) => {
    //     dispatch(isLoader(false))
    //     console.log(err)
    //   })
  }

  const handleSearch = (e) => {
    if (selectedRole) {
      setSearch(e.target.value)
    } else {
      dispatch(isToaster({ flag: true, msg: "please select Role first!" }))
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSearch()
    }
  }

  const onSearch = () => {
    dispatch(isLoader(true))

    axios
      .get(BASE_URL, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch(isLoader(false))
        setData(res.data.users)
        setAdmin(res.data.users.data)
        console.log("Get all Admins : ", res.data.users)
      })
      .catch((err) => {
        dispatch(isLoader(false))
        console.log(err)
      })
  }

  return (
    <>
      <DeleteEntry type="User" />
      <section className="addash">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between align-items-center">
                <span className="d-flex justify-content-between align-items-center">
                  <h4 className="m-0">All Users</h4>
                </span>
                <span>
                  <div>
                    <Link to="/admin/createUser">
                      {" "}
                      <button type="button" className="btn cus-btn">
                        Add User
                      </button>
                    </Link>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-4"></div>
            <div className="col-lg-4">
              <span className="ads-Sales-Dropdown">
                Select Role
                <select
                  className="dropdown-fm form-select-ctm"
                  aria-label=" select example"
                  name="role"
                  value={selectedRole}
                  onChange={handleSelectRole}
                >
                  <option selected value="">
                    All
                  </option>
                  {/* <option value="Admin">Admin</option> */}
                  <option value="Manager">Manager</option>
                  <option value="Support">Support</option>
                </select>
              </span>
            </div>
            <div className="col-lg-4">
              <span className="position-relative">
                <input
                  type="text"
                  placeholder="Search"
                  name="search"
                  value={search}
                  onChange={handleSearch}
                  onKeyDown={handleKeyDown}
                  className="form-control form-control-ctm"
                />
                <i
                  className="fa fa-magnifying-glass position-absolute mx-3 mt-2 top-0 end-0 fglass pointer"
                  onClick={onSearch}
                ></i>
              </span>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="dashboardtable">
                <div
                  className={`table-scrollable ${
                    data > 1
                      ? "border-radius-n-pagination"
                      : "border-radius-w-pagination"
                  }`}
                >
                  <table className="table table-color table-hover table-fixed-first-columns">
                    <thead>
                      <tr>
                        <th>User Name</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Age</th>
                        <th>Role</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(admin) && admin.length != 0
                        ? admin.map((x) => {
                            return (
                              <tr>
                                <td>{x.username}</td>
                                <td>{x.firstname + " " + x.lastname}</td>
                                <td>{x.email}</td>
                                <td>{x.phone}</td>
                                <td>{x.age}</td>
                                <td>{x.role}</td>
                                <td className="table-action">
                                  <div className="d-flex justify-content-center align-items-center">
                                    <span>
                                      {" "}
                                      <i
                                        className="fa-regular fa-pen-to-square"
                                        style={{cursor: 'pointer'}}
                                        onClick={() => onEdit(x.id)}
                                      ></i>
                                    </span>
                                    {/* &nbsp; &nbsp; &nbsp;
                                    <span>
                                      <i
                                        className="fa-regular fa-eye"
                                        style={{cursor: 'pointer'}}
                                        onClick={() => onEdit(x.id)}
                                      ></i>
                                    </span> */}
                                    &nbsp; &nbsp;
                                    <p
                                      type="button"
                                      className={`my-0 mx-2 ${
                                        role === "Support"
                                          ? "d-none"
                                          : "d-block"
                                      }`}
                                      // data-toggle="modal"
                                      // data-target="#deletepopup"
                                      onClick={() => dispatch(idToDelete(x.id))}
                                    >
                                      <i className="far fa-trash-alt"></i>
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            )
                          })
                        : (
                          <tr>
                            <td colSpan={7}>
                              <img
                                src={no_data}
                                alt="Description of the image"
                                width={277}
                              />
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>

                {/* pagination code */}

                {data ? (
                  data.total / data.per_page > 1 ? (
                    <div className="d-flex justify-content-center w-100 mx-auto pt-4 pb-3">
                      <button
                        className="pagination-css-pre"
                        style={{ color: "white" }}
                        onClick={() =>
                          updateData(`${BASE_URL}${data.current_page - 1}`)
                        }
                        disabled={data.current_page === 1}
                      >
                        <span aria-hidden="true">
                          <i className="fa fa-chevron-left" aria-hidden="true"></i>
                        </span>
                      </button>
                      <div className="d-flex">
                        <span className={`pagination-css-active`}>
                          {data.current_page <= data.total / data.per_page
                            ? data.current_page
                            : data.last_page}
                        </span>
                        <span
                          className={`pagination-css ${
                            data.current_page <= data.last_page - 1
                              ? "d-block"
                              : "d-none"
                          }`}
                          onClick={() =>
                            updateData(`${BASE_URL}${data.current_page + 1}`)
                          }
                        >
                          {data.current_page + 1}
                        </span>
                        <span
                          className={`pagination-css ${
                            data.current_page <= data.last_page - 2
                              ? "d-block"
                              : "d-none"
                          }`}
                          onClick={() =>
                            updateData(`${BASE_URL}${data.current_page + 2}`)
                          }
                        >
                          {data.current_page + 2}
                        </span>
                        <span className="pagination-css ">{` ... `}</span>
                        <span
                          className="pagination-css "
                          onClick={() =>
                            updateData(`${BASE_URL}${data.last_page}`)
                          }
                        >{` ${data.last_page}`}</span>
                      </div>
                      <button
                        className="pagination-css-next"
                        style={{ color: "white" }}
                        onClick={() =>
                          updateData(`${BASE_URL}${data.current_page + 1}`)
                        }
                        disabled={data.current_page === data.last_page}
                      >
                        <span aria-hidden="true">
                          <i className="fa fa-chevron-right" aria-hidden="true"></i>
                        </span>
                      </button>
                    </div>
                  ) : null
                ) : null}

                {/* pagination code */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default User

import React from "react";
import blog from "../../asset/img/blogimg.png";
import { Link } from "react-router-dom";

const OurBlog = () => {
  return (
    <>
      <section className="spacing-blog-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="premium-text">
                <h3>
                  Our <span className="premium-text-color"> Blogs</span>
                </h3>
                <p>
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  visual mockups.
                </p>
              </div>
            </div>
          </div>
          <div className="row section-detail">
            <div className="d-flex justify-content-between align-items-center p-0">
              <h4 className="section-infoedit m-0"> </h4>
              <Link to="/blog" style={{textDecoration:"none", color:"#fff"}}><span>View All</span></Link>
            </div>
            <span className="section-detail-border"></span>
          </div>
          <div className="row mt-4">
            <div className="col-lg-8 col-md-8">
              <div className="blog1">
                <div className="blog-img">
                  <img src={blog} alt="blog-img" className="img-fluid" />
                  <span className="blog-img-text">
                    <h5>Lorium Ipsum</h5>
                    <div className="d-flex align-items-center">
                      <div className="author-img">
                        <i className="fa-regular fa-user"></i>
                      </div>
                      <div className="author-name">Lorium</div>
                      <div className="blog-dot"></div>
                      <div className="blog-date">12 Jan 2023</div>
                    </div>
                    <div>
                      <p className="blog-body-text">
                        Lorem ipsum, or lipsum as it is sometimes known, is
                        dummy text used in laying out print
                      </p>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="blog2">
                <div className="blog-img">
                  <img src={blog} alt="blog-img" className="img-fluid" />
                  <span className="blog-img-text">
                    <h5>Lorium Ipsum</h5>
                    <div className="d-flex align-items-center">
                      <div className="author-img">
                        <i className="fa-regular fa-user"></i>
                      </div>
                      <div className="author-name">Lorium</div>
                      <div className="blog-dot"></div>
                      <div className="blog-date">12 Jan 2023</div>
                    </div>
                    <div>
                      <p className="blog-body-text">
                        Lorem ipsum, or lipsum as it is sometimes known, is
                        dummy text used in laying out print
                      </p>
                    </div>
                  </span>
                </div>
              </div>
              <div className="blog3">
                <div className="blog-img">
                  <img src={blog} alt="blog-img" className="img-fluid" />
                  <span className="blog-img-text">
                    <h5>Lorium Ipsum</h5>
                    <div className="d-flex align-items-center">
                      <div className="author-img">
                        <i className="fa-regular fa-user"></i>
                      </div>
                      <div className="author-name">Lorium</div>
                      <div className="blog-dot"></div>
                      <div className="blog-date">12 Jan 2023</div>
                    </div>
                    <div>
                      <p className="blog-body-text">
                        Lorem ipsum, or lipsum as it is sometimes known, is
                        dummy text used in laying out print
                      </p>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurBlog;

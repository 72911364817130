/** @format */

// Calendar.js
import React, { useState, useEffect } from "react";
import "../../asset/css/advertiser.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { advertisementId, advertiserData, advertiserId, availabilityData, availableDays, isLoader, showAvailability } from "../../actions";
import DisplayAvailability from "./DisplayAvailability";
import axios from "axios";

const Calendar = ({ availableDates, directShow }) => {
  const dispatch = useDispatch();
  const advertiserID =
    useSelector((s) => s.advertiser_id) || localStorage.getItem("viewAdId");
  const advertisementID =
    useSelector((s) => s.advertisement_id) || localStorage.getItem("ad_id");

  useEffect(() => {
    if (!advertiserID) {
      const storedAdvertiserId = localStorage.getItem("viewAdId");
      if (storedAdvertiserId) {
        dispatch(advertiserId(storedAdvertiserId));
      }
    }

    if (!advertisementID) {
      const storedAdvertisementId = localStorage.getItem("ad_id");
      if (storedAdvertisementId) {
        dispatch(advertisementId(storedAdvertisementId));
      }
    }
  }, [dispatch, advertiserID, advertisementID]);

  const getAdvertiser = () => {
    dispatch(isLoader(true));
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/get-advertiser-by-id/${advertiserID}`
      )
      .then((res) => {
        console.log(res.data.user);
        dispatch(availabilityData(res.data.user.availability));
        dispatch(availableDays(res.data.user.availability));
        dispatch(advertiserData(res.data.user));
        localStorage.setItem("advertiser_data", JSON.stringify(res.data.user));
        dispatch(isLoader(false));
      })
      .catch((err) => {
        dispatch(isLoader(false));
        console.log(err);
      });
  };

  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    getAdvertiser();
  }, []);

  const isDateAvailable = (date) => {
    const isoDate = date.toISOString().slice(0, 10);
    return availableDates.includes(isoDate);
  };

  const show = useSelector((s) => s.show_availability);

  const renderCalendar = () => {
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();

    const calendarDays = [];

    // Create array of days in the month
    for (let i = 1; i <= daysInMonth; i++) {
      calendarDays.push(i);
    }

    // Pad the beginning of the month with empty slots
    for (let i = 0; i < firstDayOfMonth; i++) {
      calendarDays.unshift(null);
    }

    // Pad the end of the month with empty slots
    const totalDays = calendarDays.length;
    const lastDayOfMonth = new Date(
      currentYear,
      currentMonth,
      daysInMonth
    ).getDay();
    for (let i = totalDays; i < 42 - lastDayOfMonth; i++) {
      calendarDays.push(null);
    }

    return calendarDays.map((day, index) => {
      const date = new Date(currentYear, currentMonth, day);
      const dates = new Date(currentYear, currentMonth, day + 1);
      const isAvailable = isDateAvailable(dates);
      // console.log('Available date in calendar: ',date)
      const isInCurrentMonth = date.getMonth() === currentMonth;
      const isActiveDay = day && isInCurrentMonth;

      let classNames = "calendar-day";
      if (isActiveDay) {
        if (isAvailable) classNames += " available";
      } else {
        classNames += " inactive";
      }

      return (
        <div key={index} className={classNames}>
          {isActiveDay && <span className="day-number">{day}</span>}
        </div>
      );
    });
  };

  const handlePrevMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1)
    );
  };

  const handleNextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1)
    );
  };

  useEffect(() => {
    if (advertiserID && advertisementID) {
      localStorage.setItem("viewAdId", advertiserID);
      localStorage.setItem("ad_id", advertisementID);
      getAdvertiser();
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [advertiserID, advertisementID]);

  return (
    <>
      <div
        className={`${
          directShow
            ? "modal_container_availability_directShow"
            : "modal_container_availability"
        } w-fit text-center m-auto ${
          show ? "d-block" : directShow ? "d-block" : "d-none"
        }`}
      >
        <div className={`calendar-container d-flex flex-column `}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h2
              className={`modal-title fs-5 text-white text-center ${
                directShow ? "d-none" : null
              }`}
              id="deletepopupLabel"
            >
              Availability
            </h2>
            <span
              className={`cross_close pointer top-0 end-0 ${
                directShow ? "d-none" : null
              }`}
              onClick={() => dispatch(showAvailability(false))}
            >
              <i
                className="fa-solid fa-x"
                data-dismiss="modal"
                aria-label="Close"
              ></i>
            </span>
          </div>
          <DisplayAvailability />
          {/* <div className="calendar-header">
            <button className="arrow-button" onClick={handlePrevMonth}>
              <i className="fas fa-chevron-left"></i>
            </button>
            <span>{`${currentDate.toLocaleString("default", {
              month: "long",
            })} ${currentDate.getFullYear()}`}</span>
            <button className="arrow-button" onClick={handleNextMonth}>
              <i className="fas fa-chevron-right"></i>
            </button>
          </div> */}
          {/* <div className="calendar">
            <div className="calendar-weekdays">
              <div>Sun</div>
              <div>Mon</div>
              <div>Tue</div>
              <div>Wed</div>
              <div>Thu</div>
              <div>Fri</div>
              <div>Sat</div>
            </div>
            <div className="calendar-days">{renderCalendar()}</div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Calendar;

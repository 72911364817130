import axios from "axios";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isToaster } from "../../actions";
import logo from "../../asset/img/logo.png";
import { useDispatch } from "react-redux";

const PaymentDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const signupDetails = localStorage.getItem("accountToCreate");
  // Extract query parameters from the URL
  const searchParams = new URLSearchParams(location.search);
  const success = searchParams.get("success");
  const trackId = searchParams.get("trackId");
  const status = searchParams.get("status");

  const onCreateAccount = () => {
    const postData = JSON.parse(signupDetails);
    postData.payment_received = 1;
    postData.lastname = "xyz";
    const customConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/register`,
        postData,
        customConfig
      )
      .then((res) => {
        if (res.status === 200) {
          navigate("/login");
          dispatch(
            isToaster({
              flag: true,
              msg: `Hi ${postData.firstname}, Your account is created Successfully!`,
            })
          );
          localStorage.removeItem("accountToCreate");
        }
      })
      .catch((err) => {
        dispatch(isToaster({ flag: true, msg: err.response.data.message }));
      });
  };

  const checkPayments = () => {
    const url = "https://api.oxapay.com/merchants/inquiry";
    const data = JSON.stringify({
      merchant: "sandbox",
      trackId: trackId,
    });

    axios
      .post(url, data)
      .then((response) => {
        if (response.data.result == 100) {
          if (response.data.email == JSON.parse(signupDetails).email) {
            dispatch(isToaster({ flag: true, msg: "Payment is verified" }));
            // onCreateAccount();
            navigate(`/signup?step=4&trackId=${trackId}&success=${success}`)
          } else {
            dispatch(isToaster({ flag: true, msg: "Payment is verified but not related to the person who is trying to signup" }));
            navigate("/")
          }
          
          
        } else {
          navigate("/payment");
          dispatch(
            isToaster({
              flag: true,
              msg: `Payment is not verified, pleae try again!`,
            })
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (success == 1) {
      checkPayments();
    }
  }, []);
  return (
    <div className="payment-verification-container">
      <div class="payment-loader"></div>
      <img src={logo} alt="logo" />
      <p>Payment verification is in progress</p>
    </div>
  );
};

export default PaymentDetails;

import React, { useEffect } from 'react'
import Header from '../component/header';
import Footer from '../component/footer';
import AboutBanner from './AboutComponent/AboutBanner';
import AboutSection from './AboutComponent/AboutSection';
import ServiceTime from './AboutComponent/ServiceTime';
import AboutText from './AboutComponent/AboutText';
import AboutApplysec from './AboutComponent/AboutApplysec';
import { TopFooter } from './HomeComponent/TopFooter';
import { AboutMember } from './AboutComponent/AboutMember';
import BacktoTop from './HomeComponent/BacktoTop';

const Aboutus = () => {
  
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);


  return (
    <>
      <BacktoTop />
    <Header/>
<AboutBanner />
<AboutSection />
<ServiceTime />
<AboutText />
<AboutApplysec /> 
<AboutMember />
<TopFooter />
    <Footer />
    
    </>
  )
}

export default Aboutus;
import React from "react";
import aboutsec1 from "../../asset/img/aboutsec1.png";
import aboutsec2 from "../../asset/img/aboutsec2.png"

const AboutSection = () => {
  return (
    <>
      <section className="spacing-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
          </div>
          <div className="row mt-5 about-sec">
            <div className="col-lg-6 col-md-6">
              <div className="about-sec-img">
                <img src={aboutsec1} alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="service-detail">
                <h2 className="service-detail-h m-0">
                  Lorium Ipsum <span>Dummy Text</span>{" "}
                </h2>
                <p className="mt-4">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-5 about-sec">          
            <div className="col-lg-6 col-md-6">
              <div className="service-detail">
                <h2 className="service-detail-h m-0">
                  Lorium Ipsum <span>Dummy Text</span>{" "}
                </h2>
                <p className="mt-4">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="about-sec-img">
                <img src={aboutsec2} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutSection;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Header from "../component/header";
import { TopFooter } from "./HomeComponent/TopFooter";
import Footer from "../component/footer";
import { Membership } from "./HomeComponent/Membership";
import ViewProfileBanner from "./SingleProfile/ViewProfileBanner";
import ViewProfileNav from "./SingleProfile/ViewProfileNav";
import BacktoTop from "./HomeComponent/BacktoTop";
import SimilarAdvertisements from "./HomeComponent/SimilarAdvertisements";
import {
  adData,
  advertiserData,
  availabilityData,
  isLoader,
  advertiserId,
  advertisementId,
  availableDays,
} from "../actions";

function ViewAbcProfile() {
  const dispatch = useDispatch();
  const data = useSelector((s) => s.advertiser_data);
  const advertiserID =
    useSelector((s) => s.advertiser_id) || localStorage.getItem("viewAdId");
  const advertisementID =
    useSelector((s) => s.advertisement_id) || localStorage.getItem("ad_id");

  useEffect(() => {
    if (!advertiserID) {
      const storedAdvertiserId = localStorage.getItem("viewAdId");
      if (storedAdvertiserId) {
        dispatch(advertiserId(storedAdvertiserId));
      }
    }

    if (!advertisementID) {
      const storedAdvertisementId = localStorage.getItem("ad_id");
      if (storedAdvertisementId) {
        dispatch(advertisementId(storedAdvertisementId));
      }
    }
  }, [dispatch, advertiserID, advertisementID]);

  const getAdvertiser = () => {
    dispatch(isLoader(true));
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/get-advertiser-by-id/${advertiserID}`
      )
      .then((res) => {
        console.log(res.data.user);
        dispatch(availabilityData(res.data.user.availability));
        dispatch(availableDays(res.data.user.availability));
        dispatch(advertiserData(res.data.user));
        localStorage.setItem("advertiser_data", JSON.stringify(res.data.user));
        dispatch(isLoader(false));
      })
      .catch((err) => {
        dispatch(isLoader(false));
        console.log(err);
      });
  };

  const getAdvertisement = () => {
    dispatch(isLoader(true));
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/get-advertisement-by-id/${advertisementID}`
      )
      .then((res) => {
        console.log(res.data.advertisement);
        dispatch(adData(res.data.advertisement));
        localStorage.setItem("ad_data", JSON.stringify(res.data.advertisement));
        dispatch(isLoader(false));
      })
      .catch((err) => {
        dispatch(isLoader(false));
        console.log(err);
      });
  };

  const getAvailability = () => {
    dispatch(isLoader(true));
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/get-availability-by-ad-id/${advertisementID}`
      )
      .then((res) => {
        dispatch(isLoader(false));
        dispatch(availabilityData(res.data.availability.dates));
        localStorage.setItem(
          "availability_data",
          JSON.stringify(res.data.availability.dates)
        );
      })
      .catch((err) => {
        dispatch(isLoader(false));
        console.log(err);
      });
  };

  const renderSimilarAdvertisements = () => {
    if (!data) {
      // Render loading state or placeholder if needed
      return <div>Loading...</div>;
    }

    return (
      <SimilarAdvertisements
        ID={advertiserID}
        advertiserCity={data.city}
        eye_color={data.eye_color}
        hair_color={data.hair_color}
        breast_size={data.breast_size}
        height={data.height}
        age={data.age}
        ethnicity={data.ethnicity}
        body_type={data.body_type}
        gender={data.gender}
      />
    );
  };

  useEffect(() => {
    if (advertiserID && advertisementID) {
      localStorage.setItem("viewAdId", advertiserID);
      localStorage.setItem("ad_id", advertisementID);
      getAdvertiser();
      getAdvertisement();
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [advertiserID, advertisementID]);

  useEffect(() => {
    const storedAdvertiserData = localStorage.getItem("advertiser_data");
    const storedAdData = localStorage.getItem("ad_data");
    const storedAvailabilityData = localStorage.getItem("availability_data");

    if (storedAdvertiserData) {
      dispatch(advertiserData(JSON.parse(storedAdvertiserData)));
    }

    if (storedAdData) {
      dispatch(adData(JSON.parse(storedAdData)));
    }

    if (storedAvailabilityData) {
      dispatch(availabilityData(JSON.parse(storedAvailabilityData)));
    }
  }, [dispatch]);

  return (
    <>
      <BacktoTop />
      <Header />
      <ViewProfileBanner />
      <ViewProfileNav />
      {renderSimilarAdvertisements()}
      <Membership />
      <TopFooter />
      <Footer />
    </>
  );
}

export default ViewAbcProfile;
